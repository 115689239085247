<template>

  <figure class="inline-block h-56 md:h-80 w-full">
    <img
        :class="[imgClass, imgRounded ? 'rounded-' + imgRounded : '', {'border-1 border-gray-200': gallery && gallery.border.enabled == true}]"
        class="h-full w-full object-cover"
        :src="image.publicUrl" :title="image.properties.title" :alt="image.properties.alternative"
        :width="image.properties.dimensions.width" :height="image.properties.dimensions.height">
    <figcaption v-if="image.properties.description != null" class="mt-2 text-left">
      {{ image.properties.description }}
    </figcaption>
  </figure>


</template>

<script>
export default {
  name: 'Image',
  props: {
    gallery: Object,
    image: Object,
    imgClass: String,
    imgRounded: String,
  },
}
</script>
