<template>
    <div>
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 overflow-hidden">
            <div class="sw_textpicslider mb-8 md:mb-14">
                <div class="mx-auto">


          <swiper
              class="relative restaurant-swiper"
              :pagination="{ clickable: true }"
              :slides-per-view="1"
              :space-between="0"
              :lazy="{ loadPrevNext: true }"
          >


            <swiper-slide v-for="(child, index) in restaurant.slideImages" :key="index" class="h-auto flex flex-col text-white">
              <div class="">
                <img  class="w-full md:h-96 object-cover mb-10"  :src="'https://res.cloudinary.com/dgr25kh64/image/fetch/c_fill,f_auto,h_432,q_80,w_768/'+'https://services.davos.ch/' + child.url" alt="" width="1310" height="873">
              </div>
            </swiper-slide>

          </swiper>

                </div>
            </div>

            <div>
                <h1 class="flex items-center font-sans-extrabold">
                    {{ restaurant.name }}
                    <span
                        class="ml-3 inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider"
                        :class="[restaurant.opening == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']"
                    >
                        {{ restaurant.opening }}
                    </span>
                </h1>
                <h3 class="font-sans-extrabold" v-if="restaurant.city">{{ restaurant.city }}</h3>
                <p>{{ restaurant.description_full }}</p>
            </div>
            <div class="mt-10 pb-4 border-b border-d-blue md:flex md:justify-between space-y-6 md:space-y-0 md:space-x-8">
                <div></div>
                <div class="md:flex space-y-6 md:space-y-0 md:space-x-8">
                    <a
                        :href="'tel:' + restaurant.tel"
                        class="font-sans-extrabold flex items-center transition ease-in-out duration-150"
                        v-if="restaurant.tel"
                    >
                        <svg class="flex-shrink-0 h-5 w-5 text-sw-gray-dark">
                            <use xlink:href="#icon--phone"></use>
                        </svg>
                        <span class="ml-3">{{ restaurant.tel }}</span>
                    </a>
                    <a
                        :href="'mailto:' + restaurant.email"
                        class="font-sans-extrabold flex items-center transition ease-in-out duration-150"
                        v-if="restaurant.email"
                    >
                        <svg class="flex-shrink-0 h-5 w-5 text-sw-gray-dark">
                            <use xlink:href="#icon--email"></use>
                        </svg>
                        <span class="ml-3">{{ restaurant.email }}</span>
                    </a>
                    <a :href="restaurant.url" class="font-sans-extrabold flex items-center transition ease-in-out duration-150" v-if="restaurant.url">
                        <svg class="flex-shrink-0 h-5 w-5 text-sw-gray-dark">
                            <use xlink:href="#icon--website"></use>
                        </svg>
                        <span class="ml-3">{{ restaurant.url }}</span>
                    </a>
                </div>
            </div>

            <div class="mt-10 mx-auto space-y-6" v-if="restaurant.information">
                <div>
                    <h4 class="font-sans-extrabold">Informationen</h4>
                    <div class="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-4 sm:gap-y-2 lg:grid-cols-4">
                        <div v-if="restaurant.information['season-opening-hours']">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.saison_opening_hours') }}</b
                                ><br />
                                {{ restaurant.information['season-opening-hours'] }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.opening">
                            <p class="margin-bottom-0">
                                <b>{{ $t('general.oeffnungszeiten') }}</b
                                ><br />
                                {{ restaurant.information.opening }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.dayrest_wi">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.ruhetage_winter') }}</b
                                ><br />
                                {{ restaurant.information.dayrest_wi }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.dayrest_so">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.ruhetage_sommer') }}</b
                                ><br />
                                {{ restaurant.information.dayrest_so }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.food_midday">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.food_midday') }}</b
                                ><br />
                                {{ restaurant.information.food_midday }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.food_evening">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.food_evening') }}</b
                                ><br />
                                {{ restaurant.information.food_evening }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.food_all">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.warm_food') }}</b
                                ><br />
                                {{ restaurant.information.food_all }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.take_away">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.take_away') }}</b
                                ><br />
                                {{ restaurant.information.take_away }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.vegetarian_food">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.veggy') }}</b
                                ><br />
                                {{ restaurant.information.vegetarian_food }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.vegan_food">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.vegan') }}</b
                                ><br />
                                {{ restaurant.information.vegan_food }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.allergies">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.allergies') }}</b
                                ><br />
                                {{ restaurant.information.allergies }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.family">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.for_families') }}</b
                                ><br />
                                {{ restaurant.information.family }}
                            </p>
                        </div>
                        <div v-if="restaurant.information.wheelchair_accepted">
                            <p class="margin-bottom-0">
                                <b>{{ $t('restaurant.wheelchair') }}</b
                                ><br />
                                {{ restaurant.information.wheelchair_accepted }}
                            </p>
                        </div>
                    </div>
                </div>
                <Link
                    :style="200"
                    :text="$t('general.plan_route')"
                    target="_blank"
                    :link="
                        'https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=' +
                        restaurant.geoInfo.latitude +
                        ',' +
                        restaurant.geoInfo.longitude
                    "
                    :class="'w-full mt-4'"
                    :linkClass="'w-full'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Link from '@/components/contents/partials/Link'
import 'swiper/swiper-bundle.css'
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, {
  Pagination
} from 'swiper';
SwiperCore.use([Pagination]);

export default {
    name: 'RestaurantDetail',
    props: {
        slug: String,
    },
    components: {
        Link,
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            restaurant: {},
        }
    },
    mounted() {
        window.scrollTo(0, 0)

      let restaurantId = this.$route.params.restaurantId ?? window.location.pathname.split("/").pop().split("_")[1];
        axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/address/${restaurantId}.json `).then((response) => {
            this.restaurant = response.data[this.$i18n.locale]
        })
    },
}
</script>
