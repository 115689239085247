<template>
  <div class="">
    <div class="relative">
      <div class="relative">
        <GMaps :fullscreen="true" @markerClick="onMarkerClick" :disableControls="true" :markers="markers" :center="center" :zoom="zoom" />
        <button class="my_position absolute bottom-52 md:bottom-10 right-3" @click="onCenterMapClick" :class="currentPosition!=null?'':'hidden'">
          <svg class="h-5 w-5">
            <use xlink:href="#icon--location"></use>
          </svg>
        </button>
      </div>

      <div class="absolute bottom-10 left-8 right-0">
        <swiper
            @swiper="sliderInit"
            class="relative h-full max-w-screen-sm poi-slider"
            :slides-per-view="'auto'"
            :space-between="32"
            @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(poi, index) in pointsOfInterest" :key="index" class="flex items-end" >
            <div class="p-4 rounded-lg shadow-sw-1 bg-sw-white w-full">
              <div class="flex items-start justify-start">
                <svg v-if="false === true" class="h-10 w-10 mr-2" aria-hidden="true">
                  <use xlink:href="#icon--parking"></use>
                </svg>
                <div>
                  <span v-if="poi.filter.city" class="mb-2 inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-500 text-green-100">
                    {{ poi.filter.city }}
                  </span>
                  <h3 class="font-sans-extrabold m-0">{{ poi.name }}</h3>
                  <p v-if="poi.tel">{{ poi.tel }}</p>
                  <span v-if="poi.address">{{ poi.address }}</span>
                </div>
              </div>
              <Link :style="200" :text="poi.dist.text" target="_blank" :link="poi.dirLink" :class="'w-full mt-4'" :linkClass="'w-full'"/>
            </div>
          </swiper-slide>

        </swiper>
      </div>
    </div>
    <TheLocationBanner />
  </div>
</template>

<script>
import axios from "axios";
import Mixins from "@/Mixins";
import GMaps from "@/components/partials/GMaps";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import Link from "@/components/contents/partials/Link";
import TheLocationBanner from '@/components/TheLocationBanner.vue'

export default {
  name: 'Pois',
  mixins: [Mixins],
  components: {
    GMaps,
    Swiper,
    SwiperSlide,
    Link,
    TheLocationBanner
  },
  props: {
      appearance: Object
  },
  data() {
    return {
      sort_by_dist: false,
      pointsOfInterest: [],
      markers: [],
      swiperInstance: null,
      currentPosition: Mixins.methods.getUserPosition(),
      center: {lat: 46.802746, lng: 9.835970},
      zoom: 12,
      hasDetail: false
    }
  },
  methods: {
    onCenterMapClick(){
      this.currentPosition = Mixins.methods.getUserPosition();
      console.log(this.currentPosition);
      if(this.currentPosition!=null){
        this.center = this.currentPosition;
        this.zoom = 15;
      }
    },
    onMarkerClick: function(id) {
      this.swiperInstance.slideTo(this.pointsOfInterest.findIndex(poi => poi.id == id))

    },
    onSlideChange: function() {
      this.center =  {
        lat: parseFloat(this.pointsOfInterest[this.swiperInstance.realIndex].geoInfo.latitude),
        lng: parseFloat(this.pointsOfInterest[this.swiperInstance.realIndex].geoInfo.longitude)
      };
      this.zoom = 17
    },
    sliderInit: function(swiperInstance) {
      this.swiperInstance = swiperInstance
    },
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/${this.appearance.poi_source}_${this.$i18n.locale}.json`).then((response) => {

        let markerType = this.appearance.poi_source
        this.pointsOfInterest = response.data;

        if (this.appearance.poi_source === "mountainaddress" || this.appearance.poi_source === "gastrocrosscountry") {
          this.hasDetail = true
          this.pointsOfInterest = response.data.list
          markerType = "mountainaddress"
        }

        this.pointsOfInterest.forEach(poi => {
            poi.dist = Mixins.methods.distanceInKm(poi.geoInfo.latitude, poi.geoInfo.longitude, this.$t("general.plan_route"))
            if(poi.dist.dist !== null){
                this.sort_by_dist = true;
            }
        });

        this.markers = Mixins.methods.setMarker(this.pointsOfInterest, markerType, 0, true);

        if(this.sort_by_dist) {
            this.pointsOfInterest.sort((a, b) => a.dist.dist > b.dist.dist ? 1 : -1);
        }
    })
  },
}
</script>

<style>
  .poi-finder-slider .swiper-slide {
    width: calc(100% - 4rem);
    height: auto;
  }
</style>
