<template>
  <div>

    <figure class="inline-block h-full w-full relative" @click="setIsOpen(true)">
      <img class="w-full h-full object-cover rounded-xl max-w-full"
           :src="content.images[0].publicUrl" :title="content.images[0].properties.title" :alt="content.images[0].properties.alternative"
           :width="content.images[0].properties.dimensions.width" :height="content.images[0].properties.dimensions.height">
      <figcaption v-if="content.header != null" class="text-white absolute top-0 left-0 right-0 bottom-0 flex flex-col	items-center justify-center">
        <svg class="h-12 w-12">
          <use xlink:href="#icon--play"></use>
        </svg>
        <h3 class="mt-4"><strong>{{ content.header }}</strong></h3>
      </figcaption>
    </figure>


    <Dialog :open="isOpen" @close="setIsOpen">
      <div class="fixed bg-black bg-opacity-75 inset-0 z-60 overflow-y-auto" @click="setIsOpen(false)">
        <div class="px-6 pt-10">
          <div class="flex items-center justify-center h-8 w-8 bg-white rounded-sm absolute top-2 right-6" @click="setIsOpen(false)">
            <svg class="h-5 w-5 text-black"><use xlink:href="#icon--x"></use></svg>
          </div>
          <div class="vimeo-full-width mt-4 mx-auto">
            <iframe title="vimeo-player" :src="content.headerLink.url" width="640" height="360" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
// import Link from "@/components/contents/partials/Link";
// import Media from "@/components/contents/partials/Media";

import { Dialog } from "@headlessui/vue";

export default {
  name: 'Image',
  components: {
    Dialog,
  },
  props: {
    content: Object,
    appearance: Object
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    setIsOpen: function(value) {
      this.isOpen = value
    }
  },
  mounted() {
    console.log(this.content.images)
  }
}
</script>
