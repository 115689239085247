<template>
    <div :class="{ 'navbar--hidden': !showNavbar }">
        <TheHomescreenPopup />
        <Svg />
        <TheNavigation
            v-if="navigation"
            :servicenavigation="navigation['service']"
            :navdata="navigation['navigation']"
            :languages="navigation['languages']"
            :isEventDetail="isEventDetail"
            :isTrailDetail="isTrailDetail"
            :isTourDetail="isTourDetail"
        />
        <TheServiceNavigation :navigation="navigation && navigation['dock']" />
        <div v-if="this.introClosed == false">
            <Intro />
        </div>

        <div class="contents">
            <!--   TODO: if Route is defined (detail etc.), hide component "Contents"!   -->
            <router-view @onTrailDetailMounted="setIsTrailDetail()" @onTourDetailMounted="setIsTourDetail()" @onEventDetailMounted="setIsEventDetail()" @onRestaurantDetailMounted="setIsRestaurantDetail()" @onAddressDetailMounted="setIsAddressDetail()"></router-view>

            <Contents @hideFooter="hideFooter" disableFooter v-if="$route.name != 'eventDetail' && $route.name != 'restaurantDetail' && $route.name != 'addressDetail' && $route.name != 'trailDetail' && $route.name != 'tourDetail'" />
        </div>

        <TheFooter :navigation="navigation['footer']" :social-media="navigation['social']" v-if="navigation && showFooter" />
        <TheCookieBanner />
        <div class="hidden">
            <TheLocationBanner />
        </div>
    </div>
</template>

<style lang="scss">
@import './styles/app.scss';
</style>

<script>
import axios from 'axios'
import Svg from '@/components/Svg.vue'
import TheNavigation from '@/components/TheNavigation.vue'
import TheServiceNavigation from '@/components/TheServiceNavigation.vue'
import TheFooter from '@/components/TheFooter.vue'
import Intro from '@/components/Intro.vue'
import Contents from '@/components/Contents.vue'
import TheCookieBanner from '@/components/TheCookieBanner.vue'
import TheHomescreenPopup from '@/components/TheHomescreenPopup.vue'
import TheLocationBanner from '@/components/TheLocationBanner.vue'
import Mixins from '@/Mixins'

export default {
    name: 'App',
    mixins: [Mixins],
    data() {
        return {
            introClosed: true,
            showNavbar: true,
            lastScrollPosition: 0,
            showFooter: true,
            isEventDetail: false,
            isTrailDetail: false,
            isTourDetail: false,
            isRestaurantDetail: false,
            isAddressDetail: false,
            navigation: null,
        }
    },
    methods: {
        setIsEventDetail() {
            this.isEventDetail = true
        },
        setIsTrailDetail() {
            this.isTrailDetail = true
        },
        setIsTourDetail() {
            this.isTourDetail = true
        },
        setIsRestaurantDetail() {
            this.isRestaurantDetail = true
        },
        setIsAddressDetail() {
            this.isAddressDetail = true
        },
        hideFooter() {
            this.showFooter = false
        },
        onScroll() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            if (currentScrollPosition < 0) {
                return
            }
            // Stop executing this function if the difference between
            // current scroll position and last scroll position is less than some offset
            if (
                Math.abs(currentScrollPosition - this.lastScrollPosition) < 60 &&
                document.body.scrollHeight - window.outerHeight - currentScrollPosition > 0
            ) {
                return
            }

            this.showNavbar =
                currentScrollPosition <= this.lastScrollPosition || document.body.scrollHeight - window.outerHeight - currentScrollPosition < 0
            this.lastScrollPosition = currentScrollPosition
        },
    },
    mounted() {
        Mixins.methods.watchUserPosition()
        Mixins.methods.checkForExpiredGuestcards()

        if (localStorage.getItem('introClosed')) {
            this.introClosed = localStorage.getItem('introClosed')
        } else {
            this.introClosed = false
        }

        window.addEventListener('scroll', this.onScroll)

        let navSourceUrl = process.env.VUE_APP_API_URL

        if (this.$i18n.locale === 'en') {
            navSourceUrl += "en/"
        }

        const buddy = this.getBuddy()

        if (buddy) {
            navSourceUrl += buddy.buddy
        }

        navSourceUrl += "?type=834"

        axios.get(navSourceUrl).then((response) => {
            this.navigation = response.data
        })
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    components: {
        Svg,
        TheNavigation,
        TheServiceNavigation,
        TheFooter,
        Intro,
        Contents,
        TheCookieBanner,
        TheLocationBanner,
        TheHomescreenPopup,
    },
}
</script>
