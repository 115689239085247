<template>
  <section class="mx-auto max-w-7xl sm:px-2 lg:px-16">
    <div class="text-sw-white py-2 px-4 relative bg-red-500">
      <svg class="flex-shrink-0 h-6 w-6 absolute right-2">
        <use xlink:href="  #icon--x"></use>
      </svg>
      <h2 v-if="info && info.title" class="font-sans-extrabold">title</h2>
      <div class="alert-text">
        Du bist offline
      </div>
    </div>
  </section>

</template>

<script>

export default {
  name: 'TheOfflineInfo',
}
</script>
