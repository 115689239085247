<template>
  <div>
    <div v-if="appearance.layout === 'list'">
      <div v-if="modalOpen" class="fixed w-full h-full bg-white z-50 left-0 right-0 top-0 bottom-0 p-8 overflow-scroll">
        <div @click="modalOpen = false" class="cursor-pointer flex items-center justify-center h-8 w-8 bg-gray-500 text-white rounded-sm absolute top-2 right-2">
            <svg class="h-5 w-5 text-white"><use xlink:href="#icon--x"></use></svg>
        </div>

        <div class="mx-auto max-w-7xl sm:px-2 lg:px-16">
          <div v-if="availableFilters" class="mb-24">
            <div class="mb-8" v-if="availableFilters.category && availableFilters.category.length > 1">
              <h3 class="mb-0 font-sans-extrabold text-xl">{{ $t('filter.categories') }}</h3>
              <div class="flex flex-wrap">
                <button
                  v-for="(category, id) in availableFilters.category"
                  @click="toggleFilter('category', id)"
                  :key="category"
                  class="mr-3 mt-2 inline-flex items-center relative px-2 py-1 border font-extrabold rounded-full transition duration-200 ease-in-out"
                  :class="selectedFilters.category.includes(id) ? 'bg-gray-100 text-sw-blue border-gray-100 font-sans-extrabold' : 'border-gray-300'"
                >
                  {{ category }}

                  <span v-if="selectedFilters.category.includes(id)" class="ml-4 rounded h-5 w-5 rounded-full bg-sw-blue inline-flex items-center justify-center">
                    <svg class="h-3 w-3 text-white">
                      <use xlink:href="#icon--check"></use>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <div v-if="availableFilters.difficulty">
              <h3 class="mb-0 font-sans-extrabold text-xl">{{ $t('filter.difficulty') }}</h3>
              <div class="flex flex-wrap">
                <button
                  v-for="(difficulty, id) in availableFilters.difficulty"
                  @click="toggleFilter('difficulty', id)"
                  :key="difficulty"
                  class="mr-3 mt-2 inline-flex items-center relative px-2 py-1 border font-extrabold rounded-full transition duration-200 ease-in-out"
                  :class="selectedFilters.difficulty instanceof Array && selectedFilters.difficulty.includes(id) ? 'bg-gray-100 text-sw-blue border-gray-100' : 'border-gray-300'"
                >
                  {{ $t('tours.difficulty.' + id) }}

                  <span v-if="selectedFilters.difficulty instanceof Array && selectedFilters.difficulty.includes(id)" class="ml-4 rounded h-5 w-5 rounded-full bg-sw-blue inline-flex items-center justify-center">
                    <svg class="h-3 w-3 text-white">
                      <use xlink:href="#icon--check"></use>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <h3 class="mt-8 mb-0 font-sans-extrabold text-xl">{{ $t('filter.route') }}</h3>
            <div class="flex items-center justify-between mt-2 mb-2">
              <div>
                <div class="text-xs text-sw-gray">Min</div>
                <div class="text-sm mt-1">{{ availableFilters.distance.min_unit }}</div>
              </div>
              <div>
                <div class="text-xs text-sw-gray text-right">Max</div>
                <div class="text-sm mt-1">{{ availableFilters.distance.max_unit }}</div>
              </div>
            </div>
            <RangeSlider class="mt-4" v-model="selectedFilters.distance" :format="rangeFormat" :step="0.1" :min="availableFilters.distance.min" :max="availableFilters.distance.max" tooltipPosition="bottom" />

            <h3 class="mt-16 mb-0 font-sans-extrabold text-xl">{{ $t('filter.duration') }}</h3>
            <div class="flex items-center justify-between mt-2 mb-2">
              <div>
                <div class="text-xs text-sw-gray">Min</div>
                <div class="text-sm mt-1">{{ availableFilters.time.min_unit }}</div>
              </div>
              <div>
                <div class="text-xs text-sw-gray text-right">Max</div>
                <div class="text-sm mt-1">{{ availableFilters.time.max_unit }}</div>
              </div>
            </div>
            <RangeSlider class="mt-4" v-model="selectedFilters.time" :format="timeFormat" :step="0.01" :min="availableFilters.time.min" :max="availableFilters.time.max" tooltipPosition="bottom" />

            <h3 class="mt-16 mb-0 font-sans-extrabold text-xl">{{ $t('filter.altitude') }}</h3>
            <div class="flex items-center justify-between mt-2 mb-2">
              <div>
                <div class="text-xs text-sw-gray">Min</div>
                <div class="text-sm mt-1">{{ availableFilters.altitude.min_unit }}</div>
              </div>
              <div>
                <div class="text-xs text-sw-gray text-right">Max</div>
                <div class="text-sm mt-1">{{ availableFilters.altitude.max_unit }}</div>
              </div>
            </div>
            <RangeSlider class="mt-4" v-model="selectedFilters.altitude" :format="altitudeFormat" :step="0.1" :min="availableFilters.altitude.min" :max="availableFilters.altitude.max" tooltipPosition="bottom" />

            <div class="bg-white fixed z-20 bottom-0 left-0 right-0 px-8 py-4">
              <div class="mx-auto max-w-7xl sm:px-2 lg:px-16">
                <button @click="applyFilters" class="w-full px-4 py-2 border border-sw-blue font-extrabold rounded-full transition duration-200 ease-in-out text-white bg-sw-blue">
                  {{ $t("tours.number_tours_displayed", { number: filterTrailCount && filterTrailCount }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative">
        <div class="relative">
          <div class="flex items-center justify-between z-30" :class="viewMode === 'map' ? 'absolute top-4 md:top-6 w-full' : 'relative top-0'">
            <div :class="viewMode" class="view-mode-buttons relative inline-block rounded-full overflow-hidden bg-white">
              <span class="rounded-full transition-all duration-200 ease-in-out shape bg-sw-blue absolute h-full z-2"></span>
              <button :class="viewMode == 'map' ? 'text-white z-20' : 'text-sw-blue pr-6 z-10'" @click="viewMode = 'map'" class="-mr-4 text-sm uppercase relative w-24 z-3 py-2 border-2 border-sw-blue font-sans-extrabold rounded-full transition duration-200 ease-in-out">
                {{ $t("tours.map") }}
              </button>
              <button :class="viewMode == 'list' ? 'text-white z-20' : 'text-sw-blue pl-6 z-10'" @click="viewMode = 'list'" class="-ml-4 text-sm uppercase relative w-24 z-3 py-2 border-2 border-sw-blue font-sans-extrabold rounded-full transition duration-200 ease-in-out">
                {{ $t("tours.list") }}
              </button>
            </div>

            <button :class="viewMode === 'map' ? 'bg-white text-sw-blue border-white shadow-md' : 'bg-white text-sw-blue border-sw-blue'" @click="modalOpen = true" class="uppercase text-sm cursor-pointer inline-flex relative px-4 py-2 border-2 font-sans-extrabold rounded-full transition duration-200 ease-in-out">
              <svg class="h-5 w-5 mr-2">
                <use xlink:href="#icon--filter"></use>
              </svg>
              {{ $t("tours.filter") }}
            </button>
          </div>

          <div v-if="viewMode === 'map'">
            <div class="absolute bottom-6 left-0 right-0">
              <swiper
                  @swiper="swiperInitialized"
                  class="relative h-full parking-finder-slider -mx-4"
                  :slides-per-view="1.15"
                  :space-between="20"
                  :breakpoints="breakpoints"
                  :centeredSlides="true"
                  :mousewheel="{invert: true}"
                  :keyboard="{enabled: true}"
                  :centeredSlidesBounds="false"
                  :slideToClickedSlide="true"
                  @slideChange="onSlideChange"
              >
                <swiper-slide class="flex items-end">
                  <div class="p-4 rounded-2xl shadow-sw-1 bg-sw-white w-full flex items-center">
                    <div>
                      <h3 class="mt-2 text-xl font-sans-extrabold m-0">{{ $t("tours.all_tours") }}</h3>
                      <p>{{ $t("tours.all_tours_text") }}</p>
                    </div>
                    <svg class="h-10 w-10 ml-auto">
                      <use xlink:href="#icon--chevron-right"></use>
                    </svg>
                  </div>
                </swiper-slide>
                <swiper-slide v-for="(trail, index) in trails" :key="index" class="flex items-end" >
                  <div class="p-4 rounded-2xl shadow-sw-1 bg-sw-white w-full">
                    <router-link class="flex items-start justify-start" :to="{ name: 'tourDetail', params: { slug: 'tour-' + trail.uid + '-' + sanitizeString(trail.title), trail: trail, trailId: trail.uid } }">
                      <div class="mr-4 rounded-full w-10 h-10 md:w-16 md:h-16 hidden md:block bg-center bg-cover" :style="{ backgroundImage: `url('${trail.photos[0].largeThumb}')` }" v-if="trail.photos.length > 0 && trail.photos[0].largeThumb"></div>
                      <div>
                            <span class="mb-2 font-sans-extrabold inline-flex items-center justify-center uppercase px-4 py-1 text-xs rounded-full tracking-wider" :class="oaDifficulty(trail.filter_difficulty)" v-if="trail.filter_difficulty">
                                {{ $t('tours.difficulty.' + trail.filter_difficulty) }}
                            </span>
                            <h3 class="mt-2 text-xl font-sans-extrabold m-0">{{ trail.title }}</h3>

                            <div class="flex mt-2 space-x-2">
                                <div class="flex items-center" v-if="trail.distance">
                                    <svg class="h-5 w-5 mr-2">
                                        <use xlink:href="#icon&#45;&#45;trailforks-bike"></use>
                                    </svg>
                                    <span class="text-sm">
                                        {{ trail.distance}}
                                    </span>
                                </div>
                                <span class="hidden xs:block">&bull;</span>
                                <div class="flex items-center" v-if="trail.altClimb">
                                    <svg class="h-3 w-3 mr-2">
                                        <use xlink:href="#icon&#45;&#45;trailforks-up"></use>
                                    </svg>
                                    <span class="text-sm">
                                        {{ trail.altClimb }}
                                    </span>
                                </div>
                                <span class="hidden xs:block">&bull;</span>
                                <div class="flex items-center" v-if="trail.altDescent">
                                    <svg class="h-3 w-3 mr-2">
                                        <use xlink:href="#icon&#45;&#45;trailforks-down"></use>
                                    </svg>
                                    <span class="text-sm">
                                        {{ trail.altDescent }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <GMaps ref="googleMaps" map-type="terrain" @polylineClick="onPolylineClick" :fullscreen="true" :disableControls="true" :center="center" :zoom="zoom" :trails="trails" :activeTrail="activeTrail" :options="options" />
            <button class="my_position absolute top-16 md:top-auto md:bottom-6 md:left-auto md:right-2" @click="onCenterMapClick" :class="currentPosition!=null ? '' : 'hidden'">
              <svg class="h-5 w-5">
                <use xlink:href="#icon&#45;&#45;location"></use>
              </svg>
            </button>
            <button class="my_position text-black absolute md:bottom-6 md:top-auto" @click="onBackClick()" v-if="swiperInstance && swiperInstance.realIndex !== 0" :class="currentPosition!=null ? 'top-28' :'top-16'">
              <svg class="h-5 w-5">
                <use xlink:href="#icon--chevron-left"></use>
              </svg>
            </button>
          </div>

          <div v-if="viewMode === 'list'">
            <div class="mx-auto grid gap-5 lg:grid-cols-3">
              <section
                class="mt-4 flex flex-col rounded-2xl shadow-sw-1 bg-sw-white overflow-hidden relative"
                v-for="(trail) in trails"
                :item="tab"
                :key="trail"
              >
                <div class="flex-shrink-0" v-if="trail.photos.length > 0">
                  <router-link class="relative block" :to="{ name: 'tourDetail', params: { slug: 'tour-' + trail.uid + '-' + sanitizeString(trail.title), trail: trail, trailId: trail.uid } }">
                    <img
                      class="h-36 w-full object-cover"
                      :src="trail.photos[0].largeThumb"
                      :alt="trail.title"
                    />
                   <span class="absolute bottom-2 right-2 font-sans-extrabold inline-flex items-center justify-center uppercase px-4 py-1 text-xs rounded-full tracking-wider" :class="oaDifficulty(trail.filter_difficulty)" v-if="trail.filter_difficulty">
                        {{ $t('tours.difficulty.' + trail.filter_difficulty) }}
                    </span>
                  </router-link>
                </div>
                <div class="w-full flex justify-end" v-if="!trail.photos.length > 0">
                  <span class="mt-2 mr-2 font-sans-extrabold inline-flex items-center justify-center uppercase px-4 py-1 text-xs rounded-full tracking-wider" :class="oaDifficulty(trail.filter_difficulty)" v-if="trail.filter_difficulty">
                      {{ $t('tours.difficulty.' + trail.filter_difficulty) }}
                  </span>
                </div>
                <div class="flex-1 px-4 pt-2 pb-4 flex flex-col justify-between">
                  <div class="flex-1">
                    <router-link :to="{ name: 'tourDetail', params: { slug: 'tour-' + trail.uid + '-' + sanitizeString(trail.title), trail: trail, trailId: trail.uid } }">
                      <h3 class="mt-2 text-xl block font-sans-extrabold">
                        {{ trail.title }}
                      </h3>
                      <div class="flex mt-2 space-x-2">
                          <div class="flex items-center">
                              <img class="h-5 w-5 mr-2" :src="trail.category.iconUrl" v-if="trail.category.iconUrl"/>
                              <svg class="h-5 w-5 mr-2" v-else>
                                  <use xlink:href="#icon--trailforks-bike"></use>
                              </svg>
                              <span class="text-sm">
                                  {{ trail.distance }}
                              </span>
                          </div>
                          <span class="hidden xs:block">&bull;</span>
                          <div class="flex items-center">
                              <svg class="h-3 w-3 mr-2">
                                  <use xlink:href="#icon--trailforks-up"></use>
                              </svg>
                              <span class="text-sm">
                                  {{ trail.altClimb }}
                              </span>
                          </div>
                          <span class="hidden xs:block">&bull;</span>
                          <div class="flex items-center">
                              <svg class="h-3 w-3 mr-2">
                                  <use xlink:href="#icon--trailforks-down"></use>
                              </svg>
                              <span class="text-sm">
                                  {{ trail.altDescent }}
                              </span>
                          </div>
                      </div>

                      <div class="mt-4" v-if="trail.shortText" v-html="trail.shortText"></div>
                      <template v-else-if="trail.longText && trail.longText.length > 100">
                        <div class="inline mt-4" v-html="shortenString(trail.longText, 100)"></div>...
                      </template>
                      <template v-else-if="trail.longText && trail.longText.length <= 100">
                        <div class="inline mt-4" v-html="trail.longText"></div>
                      </template>
                    </router-link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <TheLocationBanner />
    </div>
    <div v-else-if="appearance.layout === 'slider'" class="mx-auto w-full h-full">
      <swiper
          class="relative w-full pt-2 px-2 -mx-2 -mt-2 pb-12"
          :slides-per-view="1"
          :space-between="15"
          :breakpoints="{
                    414: {
                        slidesPerView: 1.15,
                        spaceBetween: 15,
                    },
                    640: {
                        slidesPerView: 2.25,
                        spaceBetween: 25,
                    },
                    1200: {
                        slidesPerView: 3.25,
                        spaceBetween: 25,
                    },
                }"
      >
        <swiper-slide v-for="(trail, index) in trails" :key="index" class="h-auto">
          <section
              class="flex flex-col rounded-2xl shadow-sw-1 bg-sw-white overflow-hidden relative"
          >
            <div class="flex-shrink-0" v-if="trail.photos.length > 0">
              <router-link class="relative block" :to="{ name: 'tourDetail', params: { slug: 'tour-' + trail.uid + '-' + sanitizeString(trail.title), trail: trail, trailId: trail.uid } }">
                <img
                    class="h-36 w-full object-cover"
                    :src="trail.photos[0].largeThumb"
                    :alt="trail.title"
                />
                <span class="absolute bottom-2 right-2 font-sans-extrabold inline-flex items-center justify-center uppercase px-4 py-1 text-xs rounded-full tracking-wider" :class="oaDifficulty(trail.filter_difficulty)" v-if="trail.filter_difficulty">
                        {{ $t('tours.difficulty.' + trail.filter_difficulty) }}
                    </span>
              </router-link>
            </div>
            <div class="w-full flex justify-end" v-if="!trail.photos.length > 0">
                  <span class="mt-2 mr-2 font-sans-extrabold inline-flex items-center justify-center uppercase px-4 py-1 text-xs rounded-full tracking-wider" :class="oaDifficulty(trail.filter_difficulty)" v-if="trail.filter_difficulty">
                      {{ $t('tours.difficulty.' + trail.filter_difficulty) }}
                  </span>
            </div>
            <div class="flex-1 px-4 pt-2 pb-4 flex flex-col justify-between">
              <div class="flex-1">
                <router-link :to="{ name: 'tourDetail', params: { slug: 'tour-' + trail.uid + '-' + sanitizeString(trail.title), trail: trail, trailId: trail.uid } }">
                  <h3 class="mt-2 text-xl block font-sans-extrabold">
                    {{ trail.title }}
                  </h3>
                  <div class="flex mt-2 space-x-2">
                    <div class="flex items-center" v-if="trail.distance">
                      <img class="h-5 w-5 mr-2" :src="trail.category.iconUrl" v-if="trail.category.iconUrl"/>
                      <svg class="h-5 w-5 mr-2" v-else>
                        <use xlink:href="#icon--trailforks-bike"></use>
                      </svg>
                      <span class="text-sm">
                          {{ trail.distance }}
                      </span>
                    </div>
                    <span class="hidden xs:block">&bull;</span>
                    <div class="flex items-center" v-if="trail.altClimb">
                      <svg class="h-3 w-3 mr-2">
                        <use xlink:href="#icon--trailforks-up"></use>
                      </svg>
                      <span class="text-sm">
                          {{ trail.altClimb }}
                      </span>
                    </div>
                    <span class="hidden xs:block">&bull;</span>
                    <div class="flex items-center" v-if="trail.altDescent">
                      <svg class="h-3 w-3 mr-2">
                        <use xlink:href="#icon--trailforks-down"></use>
                      </svg>
                      <span class="text-sm">
                          {{ trail.altDescent }}
                      </span>
                    </div>
                  </div>

                  <div class="mt-4" v-if="trail.shortText" v-html="shortenString(trail.shortText, 100)"></div>
                  <template v-else-if="trail.longText && trail.longText.length > 100">
                    <div class="inline mt-4" v-html="shortenString(trail.longText, 100)"></div>...
                  </template>
                  <template v-else-if="trail.longText && trail.longText.length <= 100">
                    <div class="inline mt-4" v-html="trail.longText"></div>
                  </template>
                </router-link>
              </div>
            </div>
          </section>
        </swiper-slide>
        <swiper-slide class="h-auto">
          <section class="h-full flex flex-col rounded-lg shadow-sw-1 bg-sw-white relative">
            <a
                href="https://buddy.davos.ch/bike/berichte-karten/touren"
                class="flex-1 px-4 pt-4 pb-4 flex flex-col justify-center items-center"
            >
              <svg class="leading-none text-gray-300 w-100 h-100">
                <use xlink:href="#icon&#45;&#45;arrow-circle"></use>
              </svg>
              <p class="block font-sans-bold text-xl sm:text-2xl mt-4">{{ $t('tours.show_all') }}</p>
            </a>
          </section>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Mixins from "@/Mixins";
import GMaps from "@/components/partials/GMaps";
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, {
  Mousewheel,
  Keyboard
} from 'swiper';
SwiperCore.use([Mousewheel,Keyboard]);
import 'swiper/swiper-bundle.css';
import TheLocationBanner from '@/components/TheLocationBanner.vue'
import RangeSlider from '@vueform/slider'

export default {
  name: 'OaIntegrationBikeTours',
  mixins: [Mixins],
  components: {
    GMaps,
    Swiper,
    SwiperSlide,
    TheLocationBanner,
    RangeSlider
  },
  props: {
    page: Object,
    appearance: Object
  },
  data() {
    return {
      sort_by_dist: false,
      trails: [],
      swiperInstance: null,
      currentPosition: Mixins.methods.getUserPosition(),
      center: {lat: 46.802746, lng: 9.835970},
      zoom: 14,
      activeTrail: null,
      viewMode: "list",
      modalOpen: false,
      availableFilters: null,
      selectedFilters: {
        category: [],
        difficulty: [],
        distance: [1,1],
        time: [1, 1],
        altitude: [1, 1]
      },
      filterTrailCount: null,
      breakpoints: {
        1024: {
          slidesPerView: 2.05,
        }
      }
    }
  },
  methods: {
    onPolylineClick: function(index) {
      this.swiperInstance.slideTo(index + 1)
    },
    onBackClick: function() {
      this.swiperInstance.slideTo(0)
    },
    removeBrackets: function(longText) {
      return longText.replace(/\[.*?\]/g, '')
    },
    prepareTrails(data) {
      const trails = []

      data.forEach(trail => {
        if(trail.track !== null) {
          const path = []
          const lngArray = trail.track.longitude.split(",")

          trail.track.latitude.split(",").forEach((lat, index) => {
            path.push({
              lat: parseFloat(lat),
              lng: parseFloat(lngArray[index])
            })
          })

          var color = this.oaDifficultyColor(trail.filter_difficulty)

          trail.path = path
          trail.pathOptions = {
            strokeColor: color,
            strokeWeight: 3
          }
        }
        trails.push(trail)
      })

      return trails
    },
    applyFilters() {
      const params = encodeURIComponent(JSON.stringify(this.selectedFilters))
      axios.get(process.env.VUE_APP_API_URL+`?type=329&filter=${params}&source=outdooractive&category=3642920,3642921,3642923,26510740`).then(response => {
        this.trails = this.prepareTrails(response.data)
        this.modalOpen = false
        // probably reset this.swiperInstance.realIndex here
      })
    },
    toggleFilter(type, value) {
      let typeFilter = this.selectedFilters[type]

      // check if filter is set
      if (typeFilter.includes(value)) {
        // remove filter
        typeFilter = typeFilter.filter(f => f !== value)
      } else {
        // add filter
        typeFilter.push(value)
      }

      this.selectedFilters[type] = typeFilter
    },
    onCenterMapClick(){
      this.currentPosition = Mixins.methods.getUserPosition();
      if(this.currentPosition!=null){
        this.center = this.currentPosition;
        this.zoom = 14;
      }
    },
    onSlideChange: function() {
      // -1, because the "All Trails" Box is always the first

      if (this.swiperInstance.realIndex !== 0) {
        this.activeTrail = this.trails[this.swiperInstance.realIndex - 1]

        this.center =  {
          lat: this.activeTrail.path[Math.round(this.activeTrail.path.length / 2)].lat,
          lng: this.activeTrail.path[Math.round(this.activeTrail.path.length / 2)].lng
        };
        this.zoom = 14

        this.trails.forEach(trail => {
          if(this.activeTrail.uid !== trail.uid) {
            trail.pathOptions = {
              strokeColor: 'transparent'
            }
          } else {
            let color = this.oaDifficultyColor(trail.filter_difficulty)
            if(color === '#eaeaea') {
              color = '#06c'
            }
            trail.pathOptions = {
              strokeColor: color
            }
          }
        })
      } else {
        this.trails.forEach(trail => {
          let color = this.oaDifficultyColor(trail.filter_difficulty)
          if(color === '#eaeaea') {
            color = '#06c'
          }
          trail.pathOptions = {
            strokeColor: color
          }
          this.zoom = 10
        })
      }
    },
    swiperInitialized: function(swiperInstance) {
      this.swiperInstance = swiperInstance
    },
  },
  computed: {
    options() {
      return {
        styles: [
          {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          // custom styles from: https://mapstyle.withgoogle.com/
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dadada"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#c9c9c9"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    selectedFilters: {
      handler(filters) {
        const params = encodeURIComponent(JSON.stringify(filters))
        axios.get(process.env.VUE_APP_API_URL+`?type=331&filter=${params}&source=outdooractive&category=3642920,3642921,3642923,26510740`).then(response => {
          this.filterTrailCount = response.data.trails
        })
      },
      deep: true
    }
  },
  mounted() {
    if(this.appearance.layout === 'list') {
      let apiUrl = process.env.VUE_APP_API_URL+`?type=329&source=outdooractive&filter={"category":["3642920","3642921","3642923","26510740"]}`

      axios.get(apiUrl).then(response => {
        this.trails = this.prepareTrails(response.data)
        this.zoom = 10;
      })

      axios.get(process.env.VUE_APP_API_URL+`?type=331&source=outdooractive&category=3642920,3642921,3642923,26510740`).then(response => {
        const availableFilters = response.data.json

        availableFilters.distance.min = Math.floor(parseFloat(availableFilters.distance.min/1000))
        availableFilters.distance.max = Math.ceil(parseFloat(availableFilters.distance.max/1000))

        availableFilters.time.min = Math.floor(parseFloat(availableFilters.time.min/60))
        availableFilters.time.max = Math.ceil(parseFloat(availableFilters.time.max/60))

        availableFilters.altitude.min = Math.floor(parseFloat(availableFilters.altitude.min))
        availableFilters.altitude.max = Math.ceil(parseFloat(availableFilters.altitude.max))


        this.availableFilters = availableFilters

        this.selectedFilters.distance = [this.availableFilters.distance.min, this.availableFilters.distance.max]
        this.selectedFilters.time = [this.availableFilters.time.min, this.availableFilters.time.max]
        this.selectedFilters.altitude = [this.availableFilters.altitude.min, this.availableFilters.altitude.max]
      })
    } else {
      let layout = this.appearance.layout !== '' ? '&layout=' + this.appearance.layout : ''
      let single = this.appearance.single2 !== '' ? '&uids=' + this.appearance.single2 : ''
      let limit = this.appearance.limit !== '' ?  '&limit=' + this.appearance.limit : ''

      let apiUrl = process.env.VUE_APP_API_URL+`?type=329&source=outdooractive${layout}${single}${limit}`

      axios.get(apiUrl).then(response => {
        this.trails = this.prepareTrails(response.data)
      })
    }
  },
}
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style>
  .view-mode-buttons {

  }

  .slider-tooltip, .slider-connect {
    background-color: #089aca;
    border-color: #089aca;
  }

  .shape {
    width: calc(50% + 1rem);
  }

  .view-mode-buttons.list .shape {
    left: unset;
    right: 0;
  }
  .view-mode-buttons.map .shape {
    right: unset;
    left: 0;
  }
</style>
