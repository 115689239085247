<template>

  <div class="">
    <div v-if="appearance.layout == 'slider'" class="mx-auto w-full h-full overflow-hidden">

        <!--   TODO: limit!   -->

        <swiper
            class="relative w-full pt-5 px-2 pb-12"
            :slides-per-view="1.15"
            :space-between="15"
            :breakpoints="{
              640: {
                slidesPerView: 2.25,
                spaceBetween: 25,
              },
              1200: {
                slidesPerView: 3.25,
                spaceBetween: 25,
              }
            }"
        >
          <swiper-slide v-for="(guest_program_item, index) in guest_program" :key="index" class="h-auto">
            <div class="h-full flex flex-col rounded-lg shadow-sw-1 bg-sw-white relative">
              <div class="flex-1 px-4 pt-2 pb-4 flex flex-col justify-between">

                <div class="flex-1">
                  <div class="block">

                    <p class="text-xs mt-1.5 mb-4 tracking-wide font-semibold uppercase inline-block uppercase px-1.5 py-1 rounded-md bg-sw-blue bg-opacity-10 text-sw-blue">Ski & Snowboard</p>

                    <h3 class="block text-xl font-sans-bold sm:text-3xl">
                      {{ guest_program_item.title }}
                    </h3>
                    <p class="text-lg font-sans-bold my-2">
                      {{ guest_program_item.price }}
                    </p>
                    <p class="text-sm"></p>
                  </div>
                </div>

              </div>
              <div class="flex-shrink-0" v-if="guest_program_item.image">
                <img class="h-36 w-full object-cover rounded-b-xl" :src="guest_program_item.image" :alt="guest_program_item.title">
              </div>
            </div>
          </swiper-slide>
        </swiper>

    </div>

    <div v-else class="mx-auto grid gap-5 md:grid-cols-2 lg:grid-cols-3">

      <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative" v-for="(guest_program_item, index) in guest_program"
           :item="tab" :key="index" >
        <div class="flex-shrink-0" v-if="guest_program_item.image">
          <img class="h-36 w-full object-cover" :src="guest_program_item.image" :alt="guest_program_item.title">
        </div>
        <div class="flex-1 px-4 pt-2 pb-4 flex flex-col justify-between">
          <div class="flex-1">
             <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-500 text-green-100" v-for="city in guest_program_item.city" :key="city">
              {{ filters.cities[this.$i18n.locale][city] }}
            </span>
            <div class="block mt-2">
              <h3 class="block font-sans-bold">
                {{ guest_program_item.title }}
              </h3>
              <ul>
                 <li v-for="category in guest_program_item.category" :key="category" class="pl-2"> &bull; {{ filters.categories[this.$i18n.locale][category] }}</li>
                 <li v-for="criteria in guest_program_item.criteria" :key="criteria" class="pl-2"> &bull; {{ filters.criteria[this.$i18n.locale][criteria] }}</li>
              </ul>
            </div>
          </div>
              <Link :style="0" :text="currencyCHF(guest_program_item.price, true) + ' ' + $t('guestProgram.book') " target="_blank" :link="guest_program_item.url" linkClass="w-full" v-if="guest_program_item.url != ''"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import axios from "axios";
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Mixins from "@/Mixins";
import Link from "@/components/contents/partials/Link";

export default {
  name: 'GuestProgram',
  mixins: [Mixins],
  props: {
    appearance: Object,
  },
  data() {
    return {
      guest_program: [],
      filters: [],
    }
  },
  methods: {

  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+'typo3temp/feed_dk/guest_programm_winter.json').then(response => {
      this.guest_program = response.data.items[this.$i18n.locale]
      this.filters = response.data.filters
    })
  },
  components: {
    Swiper,
    SwiperSlide,
    Link
  },
}
</script>
