<template>
  <div>

    <!--  TODO!  -->

  </div>
</template>

<script>
export default {
  name: 'SkitourFreeride',
}
</script>
