<template>

  <span :class="add_class">
        <i v-if="icon_set[icon] == 'mist'" class="cfi-weather-mist"></i>
        <i v-if="icon_set[icon] == 'sunrise'" class="cfi-weather-sunrise"></i>
        <i v-if="icon_set[icon] == 'sunset'" class="cfi-weather-sunset"></i>
        <i v-if="icon_set[icon] == 'cloud'" class="cfi-weather-cloud"></i>
        <i v-if="icon_set[icon] == 'cloud-b'" class="cfi-weather-cloud cfi-dark"></i>
        <i v-if="icon_set[icon] == 'sun'" class="cfi-weather-sun"></i>
        <i v-if="icon_set[icon] == 'moon'" class="cfi-weather-moon"></i>
        <span v-if="icon_set[icon] == 'smallcloud-more-sun'" class="cfi-weather-stack"><i
            class="cfi-stack-icon icon-master cfi-weather-sun-more"></i><i
            class="cfi-stack-icon icon-slave cfi-weather-cloud-smaller"></i>
        </span>
        <span v-if="icon_set[icon] == 'smallcloud-more-night'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-moon-more"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-cloud-smaller"></i>
        </span>
        <span v-if="icon_set[icon] == 'twocloud-more-sun'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-sun-medium"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-two-clouds"></i>
        </span>
        <span v-if="icon_set[icon] == 'twocloud-more-night'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-moon-medium"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-two-clouds"></i>
        </span>
        <span v-if="icon_set[icon] == 'sun-mist'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-sun-mist"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-mist-small"></i>
        </span>
        <span v-if="icon_set[icon] == 'moon-mist'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-moon-mist"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-mist-small"></i>
        </span>
        <span v-if="icon_set[icon] == 'smallcloud-sunny'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-smallcloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
        </span>
        <span v-if="icon_set[icon] == 'smallcloud-night'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-smallcloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-rainy'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-rainy"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-rainy'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-rainy"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-thunder-windyrain'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-thunder-windyrain'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-thunder-windyrain-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-thunder-windyrain-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-frosty'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-frosty"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-frosty'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-frosty"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-frosty-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-frosty"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-thunder-windysnow'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windysnow"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-thunder-windysnow'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windysnow"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-thunder-windysnow-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windysnow"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-thunder-windysnow-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windysnow"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-sleet'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sleet"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-sleet'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sleet"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-drizzle'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-drizzle"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-drizzle'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-drizzle"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-snowy'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-snowy"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-snowy'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-snowy"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-showers'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-showers"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-showers'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-showers"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-showers-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-showers"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-showers-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-showers"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-windysnow-thunder-windyrain'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windysnow-top"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-windysnow-thunder-windyrain'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windysnow-top"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sunny-windysnow-thunder-windyrain-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sunny"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windysnow-top"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-night-windysnow-thunder-windyrain-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-night"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windysnow-top"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-windysnow-thunder-windyrain-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windysnow-top"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud--windysnow-thunder-windyrain-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windysnow-top"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-drizzle'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-drizzle"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-drizzle-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-drizzle"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-snowy'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-snowy"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sleet'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sleet"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-sleet-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-sleet"></i>
        </span>
        <span v-if="icon_set[icon] == 'windyraincloud-windyrain'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-windyraincloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'windyraincloud-windyrain-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-windyraincloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-smallfrosty'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-smallfrosty"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-smallfrosty-b'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-smallfrosty"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-thunder-windyrain'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windyrain"></i>
        </span>
        <span v-if="icon_set[icon] == 'cloud-thunder-windysnow'" class="cfi-weather-stack">
          <i class="cfi-stack-icon icon-master cfi-weather-brokencloud cfi-dark"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-thunder-small"></i>
          <i class="cfi-stack-icon icon-slave cfi-weather-windysnow"></i>
        </span>
  </span>
</template>


<script>
export default {
  name: 'WeatherIcons',
  props: {
    icon: String,
    add_class: String
  },
  data() {
    return {
      icon_set: {
        '1': 'sun',
        '-1': 'moon',
        '2': 'sun-mist',
        '-2': 'moon-mist',
        '3': 'twocloud-more-sun',
        '-3': 'twocloud-more-night',
        '4': 'cloud-sunny-rainy',
        '-4': 'cloud-night-rainy',
        '5': 'cloud-sunny-thunder-windyrain-b',
        '-5': 'cloud-night-thunder-windyrain-b',
        '6': 'cloud-sunny-frosty',
        '-6': 'cloud-night-frosty',
        '7': 'cloud-sunny-thunder-windysnow-b',
        '-7': 'cloud-night-thunder-windysnow-b',
        '8': 'cloud-sunny-sleet',
        '-8': 'cloud-night-sleet',
        '9': 'cloud-sunny-thunder-windyrain',
        '-9': 'cloud-night-thunder-windyrain',
        '10': 'smallcloud-more-sun',
        '-10': 'smallcloud-more-night',
        '11': 'cloud-sunny-drizzle',
        '-11': 'cloud-night-drizzle',
        '12': 'cloud-sunny-thunder-windyrain-b',
        '-12': 'cloud-night-thunder-windyrain-b',
        '13': 'cloud-sunny-snowy',
        '-13': 'cloud-night-snowy',
        '14': 'cloud-sunny-thunder-windysnow-b',
        '-14': 'cloud-night-thunder-windysnow-b',
        '15': 'cloud-sunny-showers-b',
        '-15': 'cloud-night-showers-b',
        '16': 'cloud-sunny-windysnow-thunder-windyrain-b',
        '-16': 'cloud-night-windysnow-thunder-windyrain-b',
        '17': 'mist',
        '-17': 'mist',
        '18': 'cloud-b',
        '-18': 'cloud-b',
        '19': 'cloud',
        '-19': 'cloud',
        '20': 'cloud-drizzle-b',
        '-20': 'cloud-drizzle-b',
        '21': 'cloud-snowy',
        '-21': 'cloud-snowy',
        '22': 'cloud-sleet-b',
        '-22': 'cloud-sleet-b',
        '23': 'cloud-drizzle-b',
        '-23': 'cloud-drizzle-b',
        '24': 'cloud-frosty-b',
        '-24': 'cloud-frosty-b',
        '25': 'windyraincloud-windyrain-b',
        '-25': 'windyraincloud-windyrain-b',
        '26': 'cloud-thunder-windyrain',
        '-26': 'cloud-thunder-windyrain',
        '27': 'cloud-smallfrosty-b',
        '-27': 'cloud-smallfrosty-b',
        '28': 'cloud-thunder-windysnow',
        '-28': 'cloud-thunder-windysnow',
        '29': 'cloud-sleet-b',
        '-29': 'cloud-sleet-b',
        '30': 'cloud-windysnow-thunder-windyrain-b',
        '-30': 'cloud-windysnow-thunder-windyrain-b'
      },
      icon_list: {}
    }
  }
}
</script>
