<template>
  <div>
    <div class="mt-8" v-if="lastupdate">
      <div class="__iframe_container">
        <iframe src="https://webtv.feratel.com/webtv/?cam=4002&design=v3&c0=0&lg=de" width="100%" height="640" scrolling="no" noresize="noresize" frameborder="no" allowtransparency="true"></iframe>
      </div>

      <p class="uppercase mb-5">{{  $t('general.lastUpdate') }}: {{lastupdate}}</p>
      <tabs :selectedTab='selectedTab' v-if="selectedTab">
        <tab :title="$t('livecams.mountains')">
          <div class="sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
             <div class="cursor-pointer" v-for="(livecam, index) in livecams.mountains" :key="livecam" @click="() => showImg(index)">
                <img :src="livecam.urlThumb + (livecam.url.includes('?')?'&':'?') + 'time='+(new Date().getTime())" alt="">
                <div class="pt-4 pb-3">
                  <p class="m-0 font-sans-bold text-lg">{{livecam.title}}</p>
                  <p>{{livecam.camupdate}}</p>
                  <p class="m-0" v-if="livecam.camtemperature"><b>Temperatur:</b> {{livecam.camtemperature}}°</p>
                  <p class="m-0" v-if="livecam.camwind"><b>Wind:</b> {{livecam.camwind}}</p>
                </div>
              </div>
          </div>
        </tab>
        <tab title="Davos Klosters">
          <div class="sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div class="cursor-pointer" v-for="(livecam, index) in livecams.city" :key="livecam" @click="() => showImg(index+mountain_counter)">
              <img :src="livecam.urlThumb + (livecam.url.includes('?')?'&':'?') +'time='+(new Date().getTime())" alt="">
              <div class="pt-4 pb-3">
                <span class="inline-flex items-center justify-center uppercase px-2 py-1 mb-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-100 text-green-800" v-if="livecam.location">{{livecam.location}}</span>
                <p class="m-0 font-sans-bold text-lg">{{livecam.title}}</p>
                <p>{{livecam.camupdate}}</p>
                <p class="m-0" v-if="livecam.camtemperature"><b>Temperatur:</b> {{livecam.camtemperature}}°</p>
                <p class="m-0" v-if="livecam.camwind"><b>Wind:</b> {{livecam.camwind}}</p>
              </div>
            </div>
          </div>
        </tab>
      </tabs>
    </div>

    <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide">

      <template v-slot:toolbar></template>

    </vue-easy-lightbox>

  </div>
</template>

<script>
import Tabs from "@/components/Tabs";
import Tab from "@/components/Tab";
import VueEasyLightbox from 'vue-easy-lightbox'

import axios from "axios";

export default {
  name: 'Livecams',
  components: {
    Tabs,
    Tab,
    VueEasyLightbox
  },
  props: {
    appearance: Object,
  },
  data() {
    return {
      livecams: [],
      lastupdate: [],
      visible: false,
      index: 0,
      imgs: [],
      mountain_counter: 0,
      selectedTab: null,

    }
  },
  mounted() {
    let time = new Date().getTime();
    this.selectedTab=this.appearance.type == 'wef' ? 1 : 0
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/livecams_${this.$i18n.locale}.json`).then(response => {
      this.livecams = response.data;
      this.lastupdate = this.livecams.lastupdate;
      this.livecams.mountains.forEach(livecam => {
        this.imgs.push(
            {
              title: livecam.title,
              src: livecam.url + (livecam.url.includes('?')?'&':'?') + 'time='+time,
            }
        )
      });
      this.mountain_counter = this.livecams.mountains.length;
      this.livecams.city.forEach(livecam => {
        this.imgs.push(
            {
              title: livecam.title,
              src: livecam.url + (livecam.url.includes('?')?'&':'?') +'time='+time,
            }
        )
      });
    });
  },
  methods: {
    showImg (index) {
      this.index = index
      this.visible = true
    },
    handleHide () {
      this.visible = false
    }
  }
}
</script>
<style>
.__iframe_container {
  width: 100%;
  padding-top: 61.45%;
  position: relative;
}
.__iframe_container >iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 815px) and (min-width: 532px) {
  .__iframe_container {
    padding-top:  74.45%;
  }
}
</style>
