import { createI18n } from 'vue-i18n'
import de from './locales/de'
import en from './locales/en'

let lang = 'de'

if (window && window.location) {
    const parts = window.location.pathname.split('/')
    if (parts.length >= 1) {
        if (parts[1] === 'en') {
            lang = 'en'
        }
    }
}

const i18n = createI18n({
    legacy: false,
    locale: lang,
    fallbackLocale: 'de',
    globalInjection: true,
    messages: {
        de,
        en,
    },
})

export default i18n
