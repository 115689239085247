<template>
  <div>
    <TabGroup>
      <TabList class="border border-gray-200 rounded-full relative">
        <div id="mobile-slide-arrow" class="w-10 h-full absolute right-0 top-2/4 transform -translate-y-2/4 z-20 flex lg:hidden items-center bg-white rounded-l-none rounded-full justify-center">
          <svg class="h-4 w-5">
            <use xlink:href="#icon--chevron-right"></use>
          </svg>
        </div>
        <div id="bar-tab"  class="flex overflow-auto relative">
          <Tab v-slot="{ selected }" v-for="tab in tabs" :key="tab" as="template">
            <button :class="(selected ? 'bg-black text-white ' : '') + 'flex-shrink-0 px-4 md:px-6 py-2 tracking-wider border border-transparent font-sans-bold rounded-full transition duration-200 ease-in-out focus:outline-none'">
              {{ tab.title }}
            </button>
          </Tab>
        </div>
      </TabList>
      <TabPanels>
        <TabPanel v-for="(tab, index) in tabs" :key="index">
          <div class="mt-4">
            <component :is="tab.component" :filters="{}" />
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import Facilities from "@/components/integrations/Facilities";
import Slopes from "@/components/integrations/Slopes";
import CrosscountryReport from "@/components/integrations/CrosscountryReport";
import WinterHiking from "@/components/integrations/WinterHiking"
import Sledding from "@/components/integrations/Sledding"
import Ice from "@/components/integrations/Ice"
import Avalanches from "@/components/integrations/Avalanches";
import TrafficASE from "@/components/integrations/TrafficASE";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

export default {
  name: 'LiveStatus',
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Facilities,
    Slopes,
    CrosscountryReport,
    WinterHiking,
    Sledding,
    Ice,
    Avalanches,
    TrafficASE
  },
  data() {
    return {
      tabs: [
        {
          title: this.$t("livestatus.anlagen"),
          component: "Facilities"
        },
        {
          title: this.$t("livestatus.pisten"),
          component: "Slopes"
        },
        {
          title: this.$t("livestatus.loipen"),
          component: "CrosscountryReport"
        },
        {
          title: this.$t("livestatus.traffic"),
          component: "TrafficASE"
        },
        {
          title: this.$t("livestatus.winterhiking"),
          component: "WinterHiking"
        },
        {
          title: this.$t("livestatus.schlittelwege"),
          component: "Sledding"
        },
        {
          title: this.$t("livestatus.iceskating"),
          component: "Ice"
        },
        {
          title: this.$t("livestatus.lawinen"),
          component: "Avalanches"
        }

      ]
    }
  },
}
</script>
<style>
#bar-tab{
  width: calc( 100% - 30px);
}
</style>
