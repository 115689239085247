<template>
  <div class="max-w-7xl mx-auto" v-if="actual_situation">

    <div class="flex items-end">
      <h2 class="mb-2 sm:mb-0 mr-4 sm:mr-6 flex-1"><b>{{ $t("actualSituationSki.title") }}</b></h2>
      <p class="mb-0.5">{{actual_situation.last_update}}</p>
    </div>

    <div class="w-full mt-6 grid gap-2 lg:gap-4 lg:grid-cols-2" v-if="actual_situation">
      <a :href="$t('uri.snowrackets')"  class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="flex-none w-12 h-12 mr-6 text-sw-blue">
          <div class="sw_percentage-circle" :class="'text-' + page.color">
            <div class="sw_percentage-circle-text">
              <svg class="h-5 w-5 text-sw-gray-dark">
                <use xlink:href="#icon--snowrackets"></use>
              </svg>
            </div>
            <div class="pie" :class="'p' + actual_situation.hiking.open_rate"></div>
          </div>
        </div>
        <div>
          <div class="flex space-x-6">
            <p class="mb-0"><b>{{actual_situation.hiking.open}}</b>&nbsp;&nbsp;von&nbsp;&nbsp;<b>{{actual_situation.hiking.total}}</b></p>
          </div>
          <p class="mb-0">{{ $t("actualSituationSki.hiking") }}</p>
        </div>
      </a>

      <a :href="$t('uri.sledge')"  class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="flex-none w-12 h-12 mr-6 text-sw-blue">
          <div class="sw_percentage-circle" :class="'text-' + page.color">
            <div class="sw_percentage-circle-text">
              <svg class="h-5 w-5 text-sw-gray-dark">
                <use xlink:href="#icon--sledge"></use>
              </svg>
            </div>
            <div class="pie" :class="'p' + actual_situation.sledge.open_rate"></div>
          </div>
        </div>
        <div>
          <div class="flex space-x-6">
            <p class="mb-0"><b>{{actual_situation.sledge.open}}</b>&nbsp;&nbsp;von&nbsp;&nbsp;<b>{{actual_situation.sledge.total}}</b></p>
          </div>
          <p class="mb-0">{{ $t("actualSituationSki.schlittelbahnen")}}</p>
        </div>
      </a>

      <a :href="$t('uri.pool')"  class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="flex-none w-12 h-12 mr-6 text-sw-blue">
          <div class="sw_percentage-circle" :class="'text-' + page.color">
            <div class="sw_percentage-circle-text">
              <svg class="h-5 w-5 text-sw-gray-dark">
                <use xlink:href="#icon--pool"></use>
              </svg>
            </div>
            <div class="pie p100"></div>
          </div>
        </div>
        <div>
          <p class="mb-0"><b>{{ actual_situation.eau_la_la.pool }}</b> {{ $t("actualSituationSki.pool")}}</p>
        </div>
      </a>

      <a :href="$t('uri.spa')"  class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="flex-none w-12 h-12 mr-6 text-sw-blue">
          <div class="sw_percentage-circle" :class="'text-' + page.color">
            <div class="sw_percentage-circle-text">
              <svg class="h-5 w-5 text-sw-gray-dark">
                <use xlink:href="#icon--spa"></use>
              </svg>
            </div>
            <div class="pie p100"></div>
          </div>
        </div>
        <div>
          <p class="mb-0"><b>{{ actual_situation.eau_la_la.sauna }}</b> {{ $t("actualSituationSki.sauna")}}</p>
        </div>
      </a>
    </div>

  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'ActualSituationSki',
  props: {
    page: Object,
  },
  data() {
    return {
      actual_situation: null,
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+'typo3temp/feed_dk/actual_situation_ski.json').then(response => {
      this.actual_situation = response.data['davos']
    })
  }
}
</script>
