<template>

  <div :class="[appearance.frameClass != 'default' ? 'py-8 lg:py-10' : '', appearance.frameClass == 'none' && appearance.backgroundColor == '' ? 'px-4 sm:px-6 md:px-0' : '']">
    <div class="relative overflow-hidden grid grid-cols-6 gap-x-4 md:gap-x-10">
      <div class="col-span-2 md:col-span-1">
        <Media :content="content.gallery" imgClass="w-full h-auto" imgRounded="xl"/>
      </div>
      <div class="col-span-4 space-y-4">
        <Bodytext :content="content" v-if="content.bodytext"/>
        <div class="hidden sm:block text-sw-blue-dark">
          <Link :style="content.linkStyle" :text="content.linkText" :link="content.headerLink.url" v-if="content.headerLink.url != ''"/>
        </div>
      </div>
      <div class="sm:hidden col-span-6 mt-4 text-sw-blue-dark">
        <Link :style="content.linkStyle" :text="content.linkText" :link="content.headerLink.url" linkClass="w-full" v-if="content.headerLink.url != ''"/>
      </div>
    </div>

  </div>

</template>

<script>

import Bodytext from "@/components/contents/partials/Bodytext";
import Link from "@/components/contents/partials/Link";
import Media from "@/components/contents/partials/Media";

export default {
  name: 'Textpic',
  components: {
    Bodytext,
    Link,
    Media
  },
  props: {
    content: Object,
    appearance: Object
  },
}
</script>