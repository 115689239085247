<template>

  <div class="space-y-8">
    <nav class="inline-flex border border-gray-200 rounded-full w-full" aria-label="Tabs">
      <a v-for='(tab, index) in tabs' :key='tab.title' @click='selectTab(index)' :class="[index == selectedIndex ? 'bg-black text-white rounded-full' : 'cursor-pointer text-sw-black hover:text-black']" class="uppercase whitespace-nowrap font-sans-bold py-2 px-4 md:px-6 font-bold tracking-wider flex-1 text-center" aria-current="page">
        {{ tab.title }}
      </a>
    </nav>
    <div>
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    selectedTab: {
      default: 0,
      type: Number
    },
    mode: {
      type: String,
    }
  },
  data () {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: []         // all of the tabs
    }
  },
  created () {
  },
  mounted () {
    this.selectTab(this.selectedTab)
  },
  methods: {
    selectTab (i) {
      i = parseInt(i)
      this.selectedIndex = parseInt(i)

      // loop over all the tabs
      if(this.tabs){
        this.tabs.forEach((tab, index) => {
          tab.isActive = (index === i)
        })
      }
    }
  }
}

</script>
