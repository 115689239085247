<template>
    <div>
        <div class="flex justify-end mb-3">
            <SwitchGroup>
                <div class="flex items-center">
                    <SwitchLabel class="mr-4">{{ mapView ? $t('general.listenansicht') : $t('general.kartenansicht') }}</SwitchLabel>
                    <Switch v-model="mapView" :class='mapView ? "bg-blue-600" : "bg-gray-200"' class="relative inline-flex items-center h-6 transition-colors rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span :class='mapView ? "translate-x-6" : "translate-x-1"' class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full" />
                    </Switch>
                </div>
            </SwitchGroup>
        </div>

        <div v-if="!mapView" class="mx-auto grid gap-5 lg:grid-cols-3">
            <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative" v-for="address in addresses" :key="address">
                <div class="flex-shrink-0">
                    <router-link class="block" :to="addressRoute(address)">
                        <img class="h-48 w-full object-cover" :src="addressImageSrc(address)" :alt="address.name" />
                    </router-link>
                </div>
                <div class="flex-1 p-6 pt-3 flex flex-col justify-between">
                    <div class="flex-1">
                        <router-link class="block" :to="addressRoute(address)">
                            <span v-if="address.opening" class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider" :class="[address.opening == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']">
                                {{ address.opening }}
                            </span>
                            <h3 class="font-sans-bold text-md mb-0 mt-2 h4">{{ address.name }}</h3>
                            <p class="text-sm">
                                {{ address.address }}
                            </p>
                        </router-link>
                    </div>
                    <div class="mt-4">
                        <div class="space-y-2 text-sm">
                            <a :href="'tel:' + address.tel" class="flex items-center text-sm transition ease-in-out duration-150" v-if="address.tel">
                                <svg class="flex-shrink-0 h-4 w-5">
                                    <use xlink:href="#icon--phone"></use>
                                </svg>
                                <span class="ml-3">{{ address.tel }}</span>
                            </a>
                            <a :href="`mailto:${address.email}`" class="flex items-center text-sm transition ease-in-out duration-150" v-if="address.email">
                                <svg class="flex-shrink-0 h-3 w-5">
                                    <use xlink:href="#icon--email"></use>
                                </svg>
                                <span class="ml-3">{{ address.email }}</span>
                            </a>
                            <a :href="`http://${address.url}`" target="_blank"  class="flex items-center text-sm transition ease-in-out duration-150" v-if="address.url">
                                <svg class="flex-shrink-0 h-4 w-5">
                                    <use xlink:href="#icon--external"></use>
                                </svg>
                                <span class="ml-3">{{ address.url }}</span>
                            </a>
                            <Link :style="200" v-if="address.dist && address.dist.text" :text="address.dist.text" target="_blank" :link="address.dirLink" :class="'w-full mt-4'" :linkClass="'w-full mt-4'"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="mapView">
            <GMaps :markers="markers"  />
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import GMaps from "@/components/partials/GMaps";
    import Link from "@/components/contents/partials/Link";
    import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
    import Mixins from "@/Mixins";

    export default {
        name: "Addresses",
        mixins: [Mixins],
        components: {
            GMaps,
            Link,
            Switch,
            SwitchGroup,
            SwitchLabel
        },
        props: {
            appearance: Object
        },
        data() {
            return {
                addresses: null,
                sort_by_dist: false,
                markers: [],
                mapView: false,
            }
        },
        methods: {
            addressRoute: function(address) {
                return {
                    name: "addressDetail",
                    params: {
                        slug: `${address.id}_${this.sanitizeString(address.name)}`,
                        addressId: address.id
                    }
                }
            },
            addressImageSrc: function(address) {
                if (this.appearance.address_source.includes("trail-running-hotels") || this.appearance.address_source.includes("bike-hotels")) {
                    return `https://res.cloudinary.com/dgr25kh64/image/fetch/c_fill,f_auto,h_432,q_80,w_768/${address.image}`
                }

                return `https://res.cloudinary.com/dgr25kh64/image/fetch/c_fill,f_auto,h_432,q_80,w_768/https://services.davos.ch/${address.image}`
            }
        },
        mounted() {

            axios.get(`${this.appearance.address_source}_${this.$i18n.locale}.json`).then(response => {

                this.addresses = this.appearance.address_source.includes("trail-running-hotels") || this.appearance.address_source.includes("mountainhuts") || this.appearance.address_source.includes("gastro-hiking")  ? response.data.list : response.data;

                this.addresses.forEach(address => {
                    address.dist = Mixins.methods.distanceInKm(address.geoInfo.latitude, address.geoInfo.longitude, this.$t("general.plan_route"))
                    if (address.dist.dist !== null) {
                        this.sort_by_dist = true
                    }
                })

                this.markers = Mixins.methods.setMarker(this.addresses, 'infopoint')

                if (this.sort_by_dist) {
                    this.addresses.sort((a, b) => a.dist.dist > b.dist.dist ? 1 : -1);
                }
            })
        }
    }
</script>