<template>
    <div class="relative overflow-hidden">
        <div class="space-y-6">
            <div>
                <div class="bg-sw-white overflow-hidden">
                    <ul role="list" class="space-y-2">
                        <li v-for="(tab, index) in tabs" :item="tab" :key="index" class="bg-sw-gray-light rounded-3xl">
                            <a
                                class="transition block rounded-tl-3xl rounded-tr-3xl cursor-pointer"
                                @click="select(tab)"
                                :class="{ 'bg-gray-200': tab == selected }"
                            >
                                <div class="w-full flex items-center space-x-4 md:space-x-8 px-4 lg:px-8 py-2 md:py-4 grid gap-4 grid-cols-4">
                                    <div class="flex-col md:flex md:flex-row col-span-2">
                                        <div class="w-full flex items-center">
                                            <b>{{ tab.title }}</b>
                                        </div>
                                        <div class="w-full">
                                            <p class="mb-0 text-base">({{ tab.open }} / {{ tab.total }} {{ $t('facilities.in_betrieb') }})</p>
                                        </div>
                                    </div>
                                    <div>
                                        <span
                                            class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider w-16"
                                            :class="[tab.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']"
                                            >{{ tab.status }}</span
                                        >
                                    </div>
                                    <div class="flex items-center justify-end">
                                        <p class="mb-0 hidden font-sans-bold md:block text-base text-sw-black mr-2">{{ $t('labels.show-more') }}</p>
                                        <svg class="h-4 w-4" v-if="tab != selected">
                                            <use xlink:href="#icon--chevron-down"></use>
                                        </svg>
                                        <svg class="h-4 w-4" v-if="tab == selected">
                                            <use xlink:href="#icon--chevron-up"></use>
                                        </svg>
                                    </div>
                                </div>
                            </a>
                            <div v-show="tab == selected" class="pb-4 bg-sw-gray-light rounded-3xl space-y-6">
                                <div v-for="(f, index) in facilities[index]" :key="index" class="pt-4 px-4 lg:px-8 border-t">
                                    <div>
                                        <h3 class="h4 flex items-center justify-between">
                                            <b>{{ f.name }}</b>
                                            <span
                                                class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider ml-2 w-16"
                                                :class="[f.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']"
                                                >{{ f.status }}</span
                                            >
                                        </h3>
                                        <p v-if="f.comments != ''">{{ $t('facilities.notes') }}: {{ f.comments }}</p>
                                    </div>
                                    <div>
                                        <div class="md:table md:table-fixed md:w-full divide-y divide-sw-black md:divide-y-0">
                                            <div class="flex justify-between py-2 md:table-cell md:align-top md:py-0">
                                                <div class="md:border-b-2 md:pb-2 md:mb-2 md:border-sw-black">
                                                    <b>{{ $t('facilities.first_ride') }}</b>
                                                </div>
                                                <div>
                                                    <b>{{ f.firstride }} - {{ f.lastride }}</b>
                                                </div>
                                            </div>
                                            <div class="flex justify-between py-2 md:table-cell md:align-top md:py-0">
                                                <div class="md:border-b-2 md:pb-2 md:mb-2 md:border-sw-black">
                                                    <b>{{ $t('facilities.difference') }}</b>
                                                </div>
                                                <div>{{ f.difference }}</div>
                                            </div>
                                            <div class="flex justify-between py-2 md:table-cell md:align-top md:py-0">
                                                <div class="md:border-b-2 md:pb-2 md:mb-2 md:border-sw-black">
                                                    <b>{{ $t('facilities.length') }}</b>
                                                </div>
                                                <div>{{ f.length }}</div>
                                            </div>
                                            <div class="flex justify-between py-2 md:table-cell md:align-top md:py-0">
                                                <div class="md:border-b-2 md:pb-2 md:mb-2 md:border-sw-black">
                                                    <b>{{ $t('facilities.capacity') }}</b>
                                                </div>
                                                <div>{{ f.capacity }}</div>
                                            </div>
                                            <div class="flex justify-between py-2 md:table-cell md:align-top md:py-0">
                                                <div class="md:border-b-2 md:pb-2 md:mb-2 md:border-sw-black">
                                                    <b>{{ $t('facilities.fahrzeit') }}</b>
                                                </div>
                                                <div>{{ f.traveltime }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="mt-8" v-if="facilities.lastupdate">
            <p class="uppercase">{{ $t('general.lastUpdate') }}: {{ facilities.lastupdate }}</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Facilities',
    data() {
        return {
            tabs: [],
            facilities: [],
            selected: null,
        }
    },
    mounted() {
        axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/overviewfacilities_${this.$i18n.locale}.json`).then((response) => {
            // http://feed.davosklosters.ch/feed/json/summerfacilities/de
            this.tabs = response.data.tab
            this.facilities = response.data.facilities
        })
    },
    methods: {
        select(index) {
            this.selected = this.selected != index ? index : null
        },
    },
}
</script>
