<template>

  <div class="space-y-2 md:space-y-6">
    <div  class="space-y-2 md:space-y-6" v-if="content.subheader != '' || content.header != ''">
      <Headers :content="content" />
      <Link :style="content.linkStyle" :text="content.linkText" :link="content.headerLink.url" :target="content.headerLink.target" v-if="content.headerLink != ''"/>
    </div>
    <a :href="content.headerLink.url" :target="content.headerLink.target" v-if="content.headerLink != ''">
      <Media :content="content.gallery" imgClass="w-full h-full object-cover" imgRounded="xl"/>
    </a>
    <Media :content="content.gallery" imgClass="w-full h-full object-cover" imgRounded="xl" v-if="content.headerLink == ''"/>
  </div>

</template>

<script>
import Headers from "@/components/contents/partials/Headers";
import Link from "@/components/contents/partials/Link";
import Media from "@/components/contents/partials/Media";

export default {
  name: 'Image',
  components: {
    Headers,
    Link,
    Media,
  },
  props: {
    content: Object,
    appearance: Object
  },
}
</script>
