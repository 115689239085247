<template>
    <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 overflow-hidden" v-if="trail">
        <div class="relative">
            <swiper
                @swiper="swiperInitialized"
                class="relative trail-swiper"
                :slides-per-view="trail.photos.length > 1 ? 1.1 : 1"
                :space-between="20"
                @slideChange="onSlideChange"
                v-if="trail.photos"
            >
                <swiper-slide v-for="image in trail.photos" :key="image" class="h-full w-full">
                    <div class="h-80 lg:h-96 bg-cover bg-center" :style="{ backgroundImage: `url('${image.largeThumb}')` }"></div>
                </swiper-slide>
            </swiper>

            <div class="image-counter absolute bottom-10 z-30 text-white font-sans-extrabold text-sm" v-if="trail.photos">
                <span>{{ `${currentSlideNum} / ${totalSlides}` }}</span>
            </div>
        </div>

        <div class="px-4 py-4">
            <h1 class="mt-1 font-sans-extrabold text-2xl" v-if="trail.title">{{ trail.title }}</h1>
            <div class="flex items-center" v-if="trail.category">
                <img class="w-6 h-6 mr-2" :src="trail.category.iconUrl" v-if="trail.category.iconUrl"/>
                <span class="text-sm" v-if="trail.category.name">{{ trail.category.name }}</span>
                <span class="text-sm ml-2 mr-2" v-if="!trail.category.name">&bull;</span>
                <span class="text-sm" v-if="!trail.category.name">Davos Klosters</span>
            </div>
        </div>

        <div class="bg-gray-100 py-4 px-4" v-if="trail.source === 'trailforks'">
          <div class="flex items-center">
            <h3 class="mb-0 text-sm font-sans-extrabold mr-4">Status</h3>
            <div class="TrailforksWidgetTrailStatus max-w-full" data-w="100%" data-h="20px" :data-trailid="trail.externalId.replace('en_', '')" data-status="1" data-st="0" data-condition="1" data-ct="1" data-date="1"></div>
          </div>
          <div class="flex justify-end">
            <a :href="`https://www.trailforks.com/goto/trail/${trail.externalId.replace('en_', '')}`" target="_blank" class="inline-flex items-center font-sans-extrabold text-sw-blue">
              <span class="mr-1">Mehr</span>
              <svg class="h-5 w-5">
                <use xlink:href="#icon--external-link"></use>
              </svg>
            </a>
          </div>
        </div>

        <div class="details-table bg-white px-4 py-4">
          <div class="pt-2 flex flex-wrap items-center justify-between" v-if="trail.difficulty && trail.filter_difficulty">
              <div>{{ $t("trailfork.schwierigkeit") }}</div>
              <div class="rounded-full px-4 md:px-8 py-1 font-sans-extrabold" :class="trailforksDifficulty(trail.filter_difficulty)" v-if="trail.source === 'trailforks'">{{ trail.difficulty }}</div>
              <div class="rounded-full px-4 md:px-8 py-1 font-sans-extrabold" :class="oaDifficulty(trail.filter_difficulty)" v-else-if="trail.source === 'outdooractive'">{{ trail.difficulty }}</div>
          </div>
          <div v-if="trail.difficulties">
            <div class="mt-2 border-t pt-2 flex items-center justify-between" v-for="(difficulty, index) in trail.difficulties.difficulty" :key="index">
              <div>{{ difficulty.type }}</div>
              <DifficultyRating :value="difficulty.value"/>
            </div>
          </div>

          <div class="mt-2 border-t pt-2 flex items-center justify-between" v-if="trail.distance">
              <div>{{ $t("trailfork.strecke") }}</div>
              <div>{{ trail.distance }}</div>
          </div>
          <div class="mt-2 border-t pt-2 flex items-center justify-between" v-if="trail.time">
              <div>{{ $t("trailfork.dauer") }}</div>
              <div>{{ trail.time }}</div>
          </div>
          <div class="mt-2 border-t pt-2 flex items-center justify-between" v-if="trail.altClimb">
              <div>{{ $t("trailfork.aufstieg") }}</div>
              <div>{{ trail.altClimb }}</div>
          </div>
          <div class="mt-2 border-t pt-2 flex items-center justify-between" v-if="trail.altDescent">
              <div>{{ $t("trailfork.abstieg") }}</div>
              <div>{{trail.altDescent}}</div>
          </div>
          <div class="mt-2 border-t pt-2 flex items-center justify-between" v-if="trail.altMax">
              <div>{{ $t("trailfork.hoechster_punkt") }}</div>
              <div>{{ trail.altMax }}</div>
          </div>
          <div class="mt-2 border-t pt-2 flex items-center justify-between" v-if="trail.altMin">
              <div>{{ $t("trailfork.tiefster_punkt") }}</div>
              <div>{{ trail.altMin }}</div>
          </div>

          <div class="mt-2 border-t pt-2 flex items-center justify-between" v-if="trail.downloads && trail.downloads.gpx">
              <div>{{ $t("trailfork.gpx") }}</div>
              <a :href="trail.downloads.gpx" class="font-sans-extrabold text-sw-blue inline-flex items-center" target="_blank">
                  Download
                  <svg class="h-5 w-5 ml-2">
                      <use xlink:href="#icon--download"></use>
                  </svg>
              </a>
          </div>
          <div class="mt-2 border-t pt-2 flex items-center justify-between" v-if="trail.downloads && trail.downloads.kml">
              <div>{{ $t("trailfork.kml") }}</div>
              <a :href="trail.downloads.kml" class="font-sans-extrabold text-sw-blue inline-flex items-center" target="_blank">
                  Download
                  <svg class="h-5 w-5 ml-2">
                      <use xlink:href="#icon--download"></use>
                  </svg>
              </a>
          </div>
          <div class="mt-2 border-t pt-2 flex items-center justify-between" v-if="trail.downloads && trail.downloads.fit">
              <div>{{ $t("trailfork.fit") }}</div>
              <a :href="trail.downloads.fit" class="font-sans-extrabold text-sw-blue inline-flex items-center" target="_blank">
                  Download
                  <svg class="h-5 w-5 ml-2">
                      <use xlink:href="#icon--download"></use>
                  </svg>
              </a>
          </div>

          <div class="mt-2 border-t pt-2 flex flex-wrap items-center justify-between" v-if="distanz_startpunkt">
              <div>{{ $t("trailfork.distanz_startpunkt") }}</div>
              <div>
                <Link :style="200" :text="distanz_startpunkt.text" target="_blank" :link="distanz_startpunkt.link" :linkClass="'w-full'" v-if="distanz_startpunkt.link"/>
                <span v-else-if="distanz_startpunkt.text">{{distanz_startpunkt.text}}</span>
              </div>
          </div>
      </div>

      <div class="mt-2 px-4 py-4" v-if="trail.longText" v-html="removeBrackets(trail.longText)"></div>

      <div class="mt-2 px-4 py-4" v-if="trail.elevationProfile != null && trail.elevationProfile.url || trail.source === 'trailforks'">
        <h2 class="mt-1 font-sans-extrabold text-2xl" v-if="trail.elevationProfile != null && trail.elevationProfile.url || trail.source === 'trailforks'">{{ $t("trailfork.hoehenprofil") }}</h2>
        <img v-if="trail.elevationProfile != null && trail.elevationProfile.url" class="max-w-full" :src="trail.elevationProfile.url">
        <div v-else-if="trail.source === 'trailforks'" class="TrailforksWidgetTrail max-w-full" data-w="100%" data-h="350px" :data-trailid="trail.externalId.replace('en_', '')" data-activitytype="1" data-map="0" data-elevation="1" data-photos="0" data-title="0" data-info="0" data-trail_opacity="50" data-v="2"></div>
      </div>

      <div class="mt-2 px-4 py-4" v-if="trail.source === 'trailforks'">
        <h2 class="mt-1 font-sans-extrabold text-2xl">{{ $t("trailfork.kartenausschnitt") }}</h2>
        <div class="TrailforksWidgetTrail max-w-full" data-w="100%" data-h="350px" :data-trailid="trail.externalId.replace('en_', '')" data-activitytype="0" data-map="1" data-elevation="0" data-photos="0" data-title="0" data-info="0" data-trail_opacity="50" data-v="2"></div>
      </div>

      <div class="mt-2 px-4 py-4" v-if="trail.source === 'outdooractive'">
        <h2 class="mt-1 font-sans-extrabold text-2xl">{{ $t("trailfork.kartenausschnitt") }}</h2>
        <GMaps ref="googleMaps" map-type="terrain" :disableControls="true" :zoom="12" :center="googleMapsCenter" :trail="googleMapsTrail" :options="options" />
      </div>

      <div class="mt-2 px-4 py-4">
        <p>{{ $t("trailfork.verantwortlich_fuer_inhalt") }}</p>
        <a target="_blank" href="https://www.trailforks.com/" v-if="trail.source === 'trailforks'"><img class="max-w-full" :src="`/images/trailfork/${trail.source}.png`"></a>
        <a target="_blank" href="https://www.outdooractive.com/" v-if="trail.source === 'outdooractive'"><img class="max-w-full" :src="`/images/trailfork/${trail.source}.png`"></a>
      </div>

    </div>
</template>

<script>
import axios from "axios"
import Mixins from "@/Mixins";
import DifficultyRating from "@/components/partials/DifficultyRating";
import Link from "@/components/contents/partials/Link";
import GMaps from "@/components/partials/GMaps"
import 'swiper/swiper-bundle.css'
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
    name: 'TrailDetail',
    mixins: [Mixins],
    components: {
        Swiper,
        SwiperSlide,
        DifficultyRating,
        Link,
        GMaps
    },
    data() {
        return {
            googleMapsCenter: {},
            googleMapsTrail: {
              type: Object,
              default() {
                return null
              }
            },
            trail: null,
            swiperInstance: null,
            currentSlideNum: 1,
            totalSlides: 0,
            distanz_startpunkt: null,
            longText: null,
            trailforksjs: null,
            trailforksjs2: null,
        }
    },
    methods: {
        onSlideChange: function() {
            this.currentSlideNum = this.swiperInstance.realIndex + 1
        },
        swiperInitialized: function(swiperInstance) {
            this.swiperInstance = swiperInstance
        },
        removeBrackets: function(longText) {
            return longText.replace(/\[.*?\]/g, '')
        },
        prepareTrail() {
          if(this.trail.track !== null && this.trail.source === 'outdooractive') {
            const path = []
            const lngArray = this.trail.track.longitude.split(",")
            const latArray = this.trail.track.latitude.split(",")

            latArray.forEach((lat, index) => {
              path.push({
                lat: parseFloat(lat),
                lng: parseFloat(lngArray[index])
              })
            })

            var color = this.oaDifficultyColor(this.trail.filter_difficulty)

            this.googleMapsTrail.path = path
            this.googleMapsTrail.pathOptions = {
              strokeColor: color,
              strokeWeight: 3
            }

            this.googleMapsCenter = {
              lat: parseFloat(latArray[0]),
              lng: parseFloat(lngArray[0])
            }
          }
        },
    },
  computed: {
    options() {
      return {
        styles: [
          {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          // custom styles from: https://mapstyle.withgoogle.com/
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dadada"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#c9c9c9"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          }
        ]
      }
    }
  },
    mounted() {
        window.scrollTo(0, 0)

      const trailId = this.$route.params.trailId ?? window.location.pathname.split("/").pop().split("-")[1]
      axios.get(process.env.VUE_APP_API_URL+`?type=330&uid=${trailId}`).then((response) => {
        this.trail = response.data
        this.prepareTrail()

        this.totalSlides = response.data.photos ? response.data.photos.length : 0
        if (response.data.track.longitude && response.data.track.latitude) {
          const distanz_startpunkt_longitude = response.data.track.longitude.split(",")[0]
          const distanz_startpunkt_latitude = response.data.track.latitude.split(",")[0]
          this.distanz_startpunkt = Mixins.methods.distanceInKm(distanz_startpunkt_latitude, distanz_startpunkt_longitude, this.$t("general.plan_route")) ?? null
          this.distanz_startpunkt['link'] = 'https://www.google.com/maps?saddr=My+Location&daddr=' + distanz_startpunkt_latitude + ',' + distanz_startpunkt_longitude ?? null
        }

        if (this.trail.source === 'trailforks') {
          this.$emit('onTrailDetailMounted')

          var script1 = document.createElement("script")
          script1.setAttribute("src", "https://es.pinkbike.org/ttl-86400/sprt/j/trailforks/widget.js")
          this.trailforksjs = document.getElementsByTagName("head")[0].appendChild(script1)

          var script2 = document.createElement('script')
          script2.innerText = 'var widgetCheck = false; var style = "'+process.env.VUE_APP_API_URL+'fileadmin/css/trailforks.css";'
          this.trailforksjs2 = document.getElementsByTagName("head")[0].appendChild(script2)

        } else if (this.trail.source === 'outdooractive') {
          this.$emit('onTourDetailMounted')
        }
      })
    },
    beforeUnmount () {
      window.widgetScriptLoaded = false;
      document.getElementsByTagName("head")[0].removeChild(this.trailforksjs)
      document.getElementsByTagName("head")[0].removeChild(this.trailforksjs2)
    }
}
</script>
<style>
    .image-counter {
        right: 20%;
    }
</style>
