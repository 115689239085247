<template>

  <div class="mb-4 sw_textpicslider -mx-4 sm:-mx-6 lg:mx-0">
    <div class="h-auto md:flex text-white text-center md:text-left bg-sw-blue-dark">
      <div class="sw_gradient md:hidden">
        <div class="mb-4 -mt-px mx-auto px-4">
          <img :src="content.URLimage" class="object-cover rounded-xl">
        </div>
      </div>
      <div class="hidden md:block p-4 w-6/12">
        <img :src="content.URLimage" class="object-cover rounded-xl">
      </div>
      <div class="flex-1 px-6 pb-6 md:py-6">
        <p class="text-lg">
          <b>
            <span style="color:#FED500;">{{ $t("daytip.title") }}</span><br />
            {{content.title}}
          </b>
        </p>
        {{content.teaser}}
      </div>
    </div>
  </div>

</template>

<script>

import axios from "axios";


export default {
  name: 'Daytip',
  data() {
    return {
      content: [],
    }
  },
  mounted(){
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/daytip_${this.$i18n.locale}.json`).then(response => {
      this.content = response.data.tipp
    })
  }
}
</script>
