<template>
  <div class="max-w-7xl mx-auto" v-if="actual_situation">

    <div class="flex items-end">
      <h2 class="mb-2 sm:mb-0 mr-4 sm:mr-6 flex-1"><b>{{ $t("actual_crosscountry.aktuelle_lage") }}</b></h2>
      <p class="mb-0.5">{{actual_situation.last_update}}</p>
    </div>

    <div class="w-full mt-6 grid gap-2 lg:gap-4 lg:grid-cols-3" v-if="actual_situation">
      <a :href="$t('uri.cross_country_skating')" class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="flex-none w-12 h-12 mr-6 text-sw-blue">
          <div class="sw_percentage-circle" :class="'text-' + page.color">
            <div class="sw_percentage-circle-text">
              <svg class="h-5 w-5 text-sw-gray-dark">
                <use xlink:href="#icon--skating"></use>
              </svg>
            </div>
            <div class="pie" :class="'p' + actual_situation.skating.open_rate"></div>
          </div>
        </div>
        <div>
          <div class="flex space-x-6">
            <p class="mb-0">
              <b>{{ $t("actual_crosscountry.skating") }}</b><br />
              {{actual_situation.skating.open/1000}} {{ $t("actual_crosscountry.km_von") }} {{actual_situation.skating.total/1000}} {{ $t("actual_crosscountry.km_offen") }}</p>
          </div>
        </div>
      </a>

      <a :href="$t('uri.cross_country_classic')" class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="flex-none w-12 h-12 mr-6 text-sw-blue">
          <div class="sw_percentage-circle" :class="'text-' + page.color">
            <div class="sw_percentage-circle-text">
              <svg class="h-5 w-5 text-sw-gray-dark">
                <use xlink:href="#icon--classic"></use>
              </svg>
            </div>
            <div class="pie" :class="'p' + actual_situation.classic.open_rate"></div>
          </div>
        </div>
        <div>
          <div class="flex space-x-6">
            <p class="mb-0">
              <b>{{ $t("actual_crosscountry.klassisch") }}</b><br />
              {{actual_situation.classic.open/1000}} {{ $t("actual_crosscountry.km_von") }} {{actual_situation.classic.total/1000}} {{ $t("actual_crosscountry.km_offen") }}</p>
          </div>
        </div>
      </a>

      <a :href="$t('uri.cross_country_dogs')" class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="flex-none w-12 h-12 mr-6 text-sw-blue">
          <div class="sw_percentage-circle" :class="'text-' + page.color">
            <div class="sw_percentage-circle-text">
              <svg class="h-5 w-5 text-sw-gray-dark">
                <use xlink:href="#icon--dog"></use>
              </svg>
            </div>
            <div class="pie" :class="'p' + actual_situation.dog.open_rate"></div>
          </div>
        </div>
        <div>
          <div class="flex space-x-6">
            <p class="mb-0">
              <b>{{ $t("actual_crosscountry.hundeloipen") }}</b><br />
              {{actual_situation.dog.open/1000}} {{ $t("actual_crosscountry.km_von") }} {{actual_situation.dog.total/1000}} {{ $t("actual_crosscountry.km_offen") }}</p>
          </div>
        </div>
      </a>
    </div>

  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'ActualSituationCrosscountry',
  props: {
    page: Object,
  },
  data() {
    return {
      actual_situation: null,
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/actual_situation_crosscountry.json`).then(response => {
      this.actual_situation = response.data
    })
  }
}
</script>
