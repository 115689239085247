import { createWebHistory, createRouter } from 'vue-router';
import EventDetail from '../components/integrations/detail/Event.vue'
import RestaurantDetail from '../components/integrations/detail/Restaurant.vue'
import AddressDetail from "@/components/integrations/detail/Address.vue"
import TrailDetail from "@/components/integrations/detail/Trail.vue"
import TourDetail from "@/components/integrations/detail/Trail.vue"

const routes = [
    {
        path: '/events/:slug',
        name: 'eventDetail',
        component: EventDetail,
        props: true
    },
    {
        path: '/en/bike/reports-maps/trail-network/:slug',
        name: 'trailDetailEn',
        component: TrailDetail,
        props: true
    },
    {
        path: '/bike/berichte-karten/trail-netz/:slug',
        name: 'trailDetail',
        component: TrailDetail,
        props: true
    },
    {
        path: '/tours/:slug',
        name: 'tourDetail',
        component: TourDetail,
        props: true
    },
    {
        path: '/restaurants-und-skihuetten/:slug',
        name: 'restaurantDetail',
        component: RestaurantDetail,
        props: true
    },
    {
        path: '/addressen/:slug',
        name: 'addressDetail',
        component: AddressDetail,
        props: true
    },
    /*{
        path: "/:catchAll(.*)",
        component: NotFound,
    },*/
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
