<template>

  <div>
    <div class="buddy-davos-reports">
    <div class="davos-reports__integrations davos-reports__integration-trafficASE"
         data-integration-type="trafficASE">
      <div class="davos-reports__integrations-traffic">
        <div class="davos-reports__integration-box">
          <div class="davos-reports__integration-box-row display-block">
            <div class="grid-container">
              <div class="grid-x grid-padding-x align-middle">
                <div class="small-12 medium-12 cell">
                  <div class="text-title">
                    <img src="@/assets/icons/traffic.svg"
                         class="img-icon img-icon__traffic"
                         alt="traffic"/><span>{{ $t('trafficASE.title') }}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="davos-reports__integration-box-row display-block davos-reports__box-chart">
            <div class="grid-container">
              <div class="grid-x grid-padding-x align-middle">
                <div class="small-12 cell">
                  <div class="c-info-chart">
                    <div class="c-info-chart__big-title">{{ $t("trafficASE.bigTitleNow") }}</div>
                    <div class="c-chart__label">
                      <div class="c-chart__label-left">
                        {{ $t('trafficASE.lastUpdate') }}<br><span></span></div>
                      <div class="c-chart__label-right">
                        <div class="icon icons-traffic icons-traffic-high">
                          <div class="text-traffic-high">{{ $t('trafficASE.traffic.high') }}</div>
                          <div class="text-traffic-medium">{{ $t('trafficASE.traffic.medium') }}</div>
                          <div class="text-traffic-low">{{ $t('trafficASE.traffic.low') }}</div>
                          <div class="icons-traffic-list">
                            <svg>
                              <use xlink:href="#icon--traffic"></use>
                            </svg>
                            <svg>
                              <use xlink:href="#icon--traffic"></use>
                            </svg>
                            <svg>
                              <use xlink:href="#icon--traffic"></use>
                            </svg>
                          </div>
                        </div>
                        <span style="display: none"></span></div>
                    </div>
                    <div class="c-bar-gradient__wrap">
                      <div class="c-bar-gradient">
                        <div class="c-bar-gradient__gray">
                          <div class="c-bar-gradient__point"></div>
                        </div>
                      </div>
                    </div>
                    <div class="c-chart">

                      <div class="c-chart__content-bars js-is-open js-is-loading">
                        <div class="c-info-chart__big-title space-element--t-small">{{ $t("trafficASE.bigTitleComing") }}</div>

                        <div class="c-chart__header">
                          <div class="c-chart__header-days">
                            <div v-for="(day) in days" :key="day.id" class="c-chart__header-day" :data-day="day.id">
                              <span class="c-chart__header-label">{{ $t("trafficASE.days."+day.name) }}</span>
                            </div>
                            <div class="c-chart__header-bar"></div>
                          </div>
                        </div>
                        <div class="c-chart__bars" data-max-value="1000" data-range-height="750" data-range-medium="300">
                          <div class="c-chart__bars-message"
                               :data-message-low="$t('trafficASE.traffic.low')"
                               :data-message-medium="$t('trafficASE.traffic.medium')"
                               :data-message-high="$t('trafficASE.traffic.high')"
                          >
                            <div class="c-chart__bars-message-block" id="c-chart__bars-message">
                              <div class="c-chart__bars-message-wrap-svg">
                                <svg width="14" height="11">
                                  <use xlink:href="#icon--traffic"></use>
                                </svg>
                              </div>
                              <span class="c-chart__bars-message-text c-chart__bars-message-text-hours"></span><span
                                class="c-chart__bars-message-text c-chart__bars-message-text-comment"></span></div>
                          </div>
                          <div class="c-chart__bar-radio loading" id="c-chart__bar" role="radiogroup">
                            <div id="c-chart__bar-radio-space"></div>

                            <div v-for="hourIndex in 24"
                                 :key="hourIndex"
                                 class="c-chart__bar"
                                 role="radio"
                                 tabindex="-1"
                                 :data-hour="hourIndex">
                              <div class="c-chart__bar-wrap">
                                <div class="c-chart__bar-height"></div>
                              </div>
                            </div>
                          </div>

                          <div class="c-chart__hours">
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour">
                              <div class="c-chart__hour-wrap">
                                <div class="c-chart__hour-wrap-des"></div>
                                <div class="c-chart__hour-label">3</div>
                              </div>
                            </div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour">
                              <div class="c-chart__hour-wrap">
                                <div class="c-chart__hour-wrap-des"></div>
                                <div class="c-chart__hour-label">6</div>
                              </div>
                            </div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour">
                              <div class="c-chart__hour-wrap">
                                <div class="c-chart__hour-wrap-des"></div>
                                <div class="c-chart__hour-label">9</div>
                              </div>
                            </div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour">
                              <div class="c-chart__hour-wrap">
                                <div class="c-chart__hour-wrap-des"></div>
                                <div class="c-chart__hour-label">12</div>
                              </div>
                            </div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour">
                              <div class="c-chart__hour-wrap">
                                <div class="c-chart__hour-wrap-des"></div>
                                <div class="c-chart__hour-label">15</div>
                              </div>
                            </div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour">
                              <div class="c-chart__hour-wrap">
                                <div class="c-chart__hour-wrap-des"></div>
                                <div class="c-chart__hour-label">18</div>
                              </div>
                            </div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour">
                              <div class="c-chart__hour-wrap">
                                <div class="c-chart__hour-wrap-des"></div>
                                <div class="c-chart__hour-label">21</div>
                              </div>
                            </div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour"></div>
                            <div class="c-chart__hour"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="c-chart__openclose js-is-open"><span>{{ $t('trafficASE.detail')}}</span><i class="arrow"></i></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
      <symbol id="icon--traffic" viewBox="0 0 23 18">
        <path d="M5.48962 6.65707H5.67011L5.89551 5.33976C5.9184 5.13507 5.98531 4.95897 6.03109 4.78403H2.19941L2.6053 2.50046C2.69511 2.06136 2.98829 1.73888 3.34928 1.73888H8.03768C8.39867 1.73888 8.69096 2.06136 8.78166 2.50046L8.82656 2.7932H9.95352L9.84083 2.17799C9.63833 1.06536 8.89436 0.304951 8.03768 0.304951H3.37047C2.4918 0.304951 1.74782 1.06653 1.56733 2.17799L1.20634 4.16882H0.486137C0.215846 4.16882 0.0124512 4.46155 0.0124512 4.78403V5.57418C0.0124512 5.86692 0.192941 6.15965 0.418339 6.18939L0.801327 6.2763C0.756424 6.48099 0.733533 6.68566 0.733533 6.89152V11.1362C0.733533 11.4872 0.958926 11.7514 1.20722 11.7514H2.69517C2.92057 11.7514 3.12306 11.5467 3.16886 11.254L3.37136 9.84861H4.56613C4.40853 9.61419 4.31783 9.29288 4.31783 8.94066V8.15051C4.33984 7.33062 4.85841 6.65709 5.4897 6.65709L5.48962 6.65707ZM3.34839 8.09214L1.81554 8.0041C1.67995 8.0041 1.59014 7.85773 1.56724 7.71136C1.56724 7.44836 1.54435 7.06758 1.59013 6.7451C1.61303 6.54041 1.74773 6.42262 1.90532 6.4821C2.37901 6.65819 3.03229 6.89146 3.32548 6.97951C3.41529 7.00924 3.48308 7.09615 3.50597 7.21393L3.59578 7.68277C3.64156 7.88631 3.52886 8.09213 3.34836 8.09213L3.34839 8.09214Z" fill="currentColor"/>
        <path d="M10.4475 9.69097H10.6276L10.8753 8.4751C10.8982 8.28617 10.9649 8.12363 11.0106 7.96216H7.18775L7.5927 5.85444C7.6823 5.44915 7.9748 5.1515 8.33496 5.1515H13.0126C13.3727 5.1515 13.6643 5.44915 13.7548 5.85444L13.7996 6.12463H14.924L14.8115 5.55679C14.6095 4.52984 13.8673 3.82799 13.0126 3.82799L8.33406 3.82693C7.47937 3.82693 6.7371 4.52987 6.53508 5.55574L6.17492 7.42069H5.4555C5.18584 7.42069 4.98291 7.69089 4.98291 7.98853V8.71784C4.98291 9.01548 5.16298 9.25823 5.38786 9.28568L5.76997 9.36695C5.72517 9.55587 5.70233 9.74479 5.70233 9.93479V13.825C5.70233 14.149 5.9272 14.3929 6.17492 14.3929H7.65945C7.88432 14.3929 8.08635 14.2039 8.13204 13.9338L8.33408 12.6366L9.54804 12.6356C9.3908 12.4192 9.32316 12.1226 9.32316 11.7975V11.0682C9.30032 10.3115 9.8177 9.69085 10.4475 9.69085L10.4475 9.69097ZM8.31123 11.0145L6.78191 10.9332C6.64663 10.9332 6.55703 10.7981 6.53419 10.663C6.53419 10.4203 6.51135 10.0688 6.55702 9.77117C6.57986 9.58224 6.71426 9.47352 6.87149 9.52842C7.34408 9.69095 7.99586 9.87988 8.28837 9.98753C8.37797 10.015 8.44561 10.0952 8.46844 10.2039L8.55804 10.663C8.60372 10.8255 8.49128 11.0145 8.3112 11.0145L8.31123 11.0145Z" fill="currentColor"/>
        <path d="M21.5441 10.9346H20.7795L20.3968 9.08763C20.1821 8.05542 19.3932 7.34998 18.4849 7.34998L13.5148 7.34892C12.5831 7.34892 11.7943 8.05545 11.6029 9.08657L11.2202 10.9335L10.4556 10.9346C10.169 10.9346 9.95337 11.1786 9.95337 11.5053V12.2384C9.95337 12.5375 10.1447 12.7815 10.3837 12.8091L10.7898 12.8632C10.7422 13.0531 10.7179 13.243 10.7179 13.4339V17.3441C10.7179 17.6698 10.9326 17.9149 11.2202 17.9149H12.7978C13.0368 17.9149 13.2515 17.725 13.3001 17.4534L13.5148 16.1496H18.4615L18.6762 17.4534C18.7238 17.725 18.9395 17.9149 19.1785 17.9149H20.7561C21.0427 17.9149 21.2584 17.6709 21.2584 17.3441V13.4339C21.2584 13.2441 21.2341 13.0266 21.1865 12.8632L21.5926 12.8091C21.8316 12.7815 22.0229 12.5375 22.0229 12.2384V11.5053C22.0463 11.1786 21.8316 10.9346 21.544 10.9346H21.5441ZM12.7261 9.38574C12.8213 8.97838 13.1322 8.67921 13.5149 8.67921H18.486C18.8688 8.67921 19.1787 8.97838 19.2748 9.38574L19.7052 11.5043H12.2958L12.7261 9.38574ZM13.4906 14.5457L11.8654 14.464C11.7216 14.464 11.6264 14.3282 11.6021 14.1924C11.5778 13.9484 11.5778 13.5952 11.6264 13.296C11.6507 13.1061 11.8178 12.9968 11.9606 13.052C12.4628 13.2154 13.1555 13.4318 13.4664 13.5135C13.5616 13.5411 13.6335 13.6217 13.6577 13.731L13.753 14.1659C13.8015 14.3834 13.6577 14.5733 13.4906 14.5457L13.4906 14.5457ZM17.291 14.2476C17.291 14.3558 17.2191 14.4375 17.1239 14.4375H14.8535C14.7583 14.4375 14.6864 14.3558 14.6864 14.2476L14.6855 13.8943C14.6855 13.7861 14.7574 13.7044 14.8526 13.7044H17.1229C17.2181 13.7044 17.29 13.7861 17.29 13.8943L17.291 14.2476ZM20.3977 14.1935C20.3977 14.3569 20.2782 14.4651 20.1345 14.4651L18.5092 14.5467C18.3421 14.5467 18.1983 14.3569 18.2459 14.167L18.3411 13.732C18.3654 13.6238 18.4364 13.5421 18.5325 13.5145C18.8434 13.4329 19.5361 13.2154 20.0383 13.0531C20.1821 12.999 20.3492 13.1072 20.3725 13.2971C20.421 13.5952 20.3977 13.9484 20.3977 14.1935L20.3977 14.1935Z" fill="currentColor"/>
      </symbol>

    </svg>
  </div>
    <section>
      <div>
        <div class="d-block py-3 md:py-4"></div>
      </div>
      <div class="rounded-lg">
        <div class="">
          <a :href="$t('parking.banner.link')" class="" target="_self" >
          <div class="relative px-4 rounded-xl shadow-sw-1">
            <div class="absolute inset-0 overflow-hidden rounded-xl opacity-50">
              <div class="h-full w-full">
                <div class="text-center md:grid-cols-1 grid gap-4 h-full w-full"></div>
              </div>
            </div>
            <div aria-hidden="true" class="absolute inset-0 z-10 pointer-events-none rounded-xl mix-blend-color"></div>
            <div class="relative mx-auto max-w-7xl z-10 py-4">
              <div class="space-y-1 md:space-y-6 lg:space-y-10">
                <div>
                  <div class="h-full w-full">
                    <div class="text-center md:grid-cols-1 grid gap-4 h-full w-full">
                      <div class="h-full w-full">
                        <figure class="inline-block h-full w-full">
                          <img class="object-center object-cover max-w-full" src="https://pwa.davos.ch/fileadmin/user_upload/parking.svg" width="62" height="62">
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="space-y-2">
                  <div><h2><strong>{{ $t('parking.banner.title')}}</strong></h2>
                    <p>{{ $t('parking.banner.description')}}</p></div>
                  <div>
                    <a :href="$t('parking.banner.link')"
                       target=""
                       class="inline-flex items-center justify-center font-sans-bold transition duration-200 ease-in-out text-sw-blue-dark">{{ $t('parking.banner.button')}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a></div>
      </div>
      <div>
        <div class="d-block py-2 md:py-2"></div></div>
    </section>
  </div>
</template>

<script>
import { DavosReports } from "@/assets/js/davosReports";

export default {
  name: 'TrafficASE',
  props: {
    page: Object,
  },
  data() {
    return {
      days: [
        { id: 0, name: 'su' },
        { id: 1, name: 'mo' },
        { id: 2, name: 'tu' },
        { id: 3, name: 'we' },
        { id: 4, name: 'th' },
        { id: 5, name: 'fr' },
        { id: 6, name: 'sa' }
      ]
    }
  },
  mounted() {
    new DavosReports();
    this.updateDaysBasedOnCurrentDay();
  },
  methods: {
    updateDaysBasedOnCurrentDay() {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const updatedDays = this.days.slice(currentDay).concat(this.days.slice(0, currentDay));
      this.days = updatedDays;
    }
  }
}
</script>
<style>
@import '/css/davosReports.min.css';
</style>
