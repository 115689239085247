<template>

  <div class="mx-auto grid gap-5 lg:grid-cols-3">

    <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative" v-for="activity in activities[$i18n.locale]" :key="activity">
      <div class="flex-shrink-0" v-if="activity.image">
        <img class="h-48 w-full object-cover" :src="activity.image" :alt="activity.title">
      </div>
      <div class="flex-1 p-6 flex flex-col justify-between">
        <div class="flex-1">
          <div class="block mt-2">
            <h3 class="block text-2xl leading-8 font-sans-extrabold tracking-tight sm:text-3xl">
              {{ activity.title }}
            </h3>
            <p class="mt-3 text-base" v-html="$filters.str_limit(activity.description, 160)"></p>
          </div>
        </div>
        <div class="sm:text-right">
          <b class="block mb-2 text-xl" v-if="activity.price">{{ currencyCHF(activity.price, true) }}</b>

          <Link :style="0" :text="$t('labels.learn-more')" :link="activity.link" linkClass="w-full" v-if="activity.link" target="_blank"/>

        </div>
      </div>
    </div>

  </div>

</template>

<script>
import axios from "axios";
import Link from "@/components/contents/partials/Link";
import Mixins from "@/Mixins";

export default {
  name: 'Activities',
  props: {
    filters: Object,
  },
  mixins: [Mixins],
  components: {
    Link,
  },
  data() {
    return {
      activities: [],
    }
  },
  mounted() {
    var filter_string = '?cat=';
    filter_string += (this.filters['categories'] && this.filters['categories'].length != 0 ? this.filters['categories'] : 'pwa');
    filter_string += (this.filters['criterias'] && this.filters['criterias'].length != 0 ? '&criteria='+this.filters['criterias'] : '');

    axios.get('https://ferienshop.davos.ch/api/export/activities'+filter_string).then(response => {
      this.activities = response.data
    })
  }
}
</script>
