<template>
    <div>
      <div id="oa-mehrtagestouren"></div>
    </div>
</template>

<script>
    export default {
        name: "OaIntegrationMehrtagestouren",
        mounted() {
          let externalScript = document.createElement('script')
          externalScript.setAttribute('src', 'https://www.outdooractive.com/' + this.$i18n.locale + '/embed/243732288/js?mw=false&usr=lvdtn&key=USR-JRT86J2K-EMWGMIDQ-4OSSLHPH')
          document.getElementById('oa-mehrtagestouren').appendChild(externalScript)
        }
    }
</script>