<template>
  <div class="">
    <div class="relative">
      <div class="relative">
        <GMaps :fullscreen="true" @markerClick="onMarkerClick($event)" :disableControls="true" :markers="markers" :center="center" :zoom="zoom" ref="map" />
        <button class="my_position absolute bottom-52 md:bottom-10 right-3" @click="onCenterMapClick" :class="currentPosition!=null?'':'hidden'">
          <svg class="h-5 w-5">
            <use xlink:href="#icon--location"></use>
          </svg>
        </button>
      </div>

      <div class="absolute bottom-10 left-8 right-0">
        <swiper
            @swiper="swiperInitialized"
            class="relative h-full max-w-screen-sm parking-finder-slider"
            :slides-per-view="'auto'"
            :space-between="32"
            @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(parking, index) in parkings" :key="index" :class="`flex items-end c-parking-${parking.id}`" >
            <div class="p-4 rounded-lg shadow-sw-1 bg-sw-white w-full">
              <div class="flex items-start justify-start">
                <svg class="h-10 w-10 mr-2" aria-hidden="true">
                  <use xlink:href="#icon--parking"></use>
                </svg>
                <div>
                  <h3 class="font-sans-extrabold m-0">{{ parking.short_name }}</h3>
                  <p>{{ parking.description }}</p>
                  <span v-if="parking.address">{{ parking.address }}</span>
                </div>
              </div>
              <div class="flex items-center mt-4" v-if="parking.occupancy.status !== 0">
                <div :class="`mr-2 rounded-full w-4 h-4 bg-${getColorBasedOnFreeParkingLots(parking.occupancy.message)}`"></div>
                <p :class="`m-0 text-${getColorBasedOnFreeParkingLots(99)}`">
                  <strong>{{parking.occupancy.free_places}}</strong>
                  {{ $t("parking.free_parking") }}
                </p>
              </div>
              <Link :style="200" :text="parking.dist.text" target="_blank" :link="parking.dirLink" :class="'w-full mt-4'" :linkClass="'w-full'"/>
            </div>
          </swiper-slide>

        </swiper>
      </div>
    </div>
    <TheLocationBanner />
  </div>
</template>

<script>
import axios from "axios";
import Mixins from "@/Mixins";
import GMaps from "@/components/partials/GMaps";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import Link from "@/components/contents/partials/Link";
import TheLocationBanner from '@/components/TheLocationBanner.vue'

export default {
  name: 'ParkingFinder',
  mixins: [Mixins],
  components: {
    GMaps,
    Swiper,
    SwiperSlide,
    Link,
    TheLocationBanner
  },
  props: {
    page: Object
  },
  data() {
    return {
      sort_by_dist: false,
      parkings: [],
      markers: [],
      swiperInstance: null,
      currentPosition: Mixins.methods.getUserPosition(),
      center: {lat: 46.802746, lng: 9.835970},
      zoom: 12,
      map: null
    }
  },
  methods: {
    onCenterMapClick(){
      this.currentPosition = Mixins.methods.getUserPosition();
      if(this.currentPosition!=null){
        this.center = this.currentPosition;
        this.zoom = 15;
      }
    },
    onMarkerClick: function(id) {
      this.swiperInstance.slideTo(this.parkings.findIndex(parking => parking.id == id))
    },
    onSlideChange: function() {
      this.center =  {
        lat: parseFloat(this.parkings[this.swiperInstance.realIndex].geoInfo.latitude),
        lng: parseFloat(this.parkings[this.swiperInstance.realIndex].geoInfo.longitude)
      };
      this.zoom = 17
      this.map.selectedKey=this.parkings[this.swiperInstance.realIndex].id
    },
    swiperInitialized: function(swiperInstance) {
      this.swiperInstance = swiperInstance
      let that = this
        if(location.hash){
          setTimeout(function(){
            that.swiperInstance.slideTo(that.parkings.findIndex(parking => parking.id == location.hash.substring(1)))
          },300)
        }
    },
    getColorBasedOnFreeParkingLots: function(message) {

      if (message === "warning") {
        return "sw-orange"
      }

      if (message === "free") {
        return "sw-green"
      }

      if (message === "full") {
        return "sw-red"
      }

      return ""
    }
  },
  mounted() {
    let apiUrl = process.env.VUE_APP_API_URL+`typo3temp/feed_dk/parking_${this.$i18n.locale}.json`
    if(this.page.integration == 'parking-wef'){
      apiUrl = process.env.VUE_APP_API_URL+`typo3temp/feed_dk/parking-wef_${this.$i18n.locale}.json`
    }
    this.map = this.$refs.map;
    this.map.mapMarkerActive ={
      scaledSize: {
        height:50,
        width:32
      },
      url : "/marker/parking_active.svg",
    };

    axios.get(apiUrl).then(response => {
      this.parkings = response.data;
      this.parkings.forEach(parking => {
        parking.dist = Mixins.methods.distanceInKm(parking.geoInfo.latitude, parking.geoInfo.longitude, this.$t("general.plan_route"))
        if(parking.dist.dist !== null){
          this.sort_by_dist = true;
        }
      });
      this.markers = Mixins.methods.setMarker(this.parkings, 'parking', 0, true);
      if(this.sort_by_dist) {
        this.parkings.sort((a, b) => a.dist.dist > b.dist.dist ? 1 : -1);
      }
    })
  },
}
</script>

<style>
  .parking-finder-slider .swiper-slide {
    width: calc(100% - 4rem);
    height: auto;
  }
</style>
