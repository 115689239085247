<template>

  <div :class="[appearance.frameClass != 'default' ? 'py-8 lg:py-10' : '', appearance.frameClass == 'none' && appearance.backgroundColor == '' ? 'px-4 sm:px-6 md:px-0' : '']">
    <div class="relative overflow-hidden grid grid-cols-6 gap-x-4 md:gap-x-10 lg:gap-x-16" v-if="content.gallery.position.vertical == 'intext'">
      <div class="col-span-2" v-if="content.gallery.position.vertical == 'above' || content.gallery.position.vertical == 'intext' && content.gallery.position.horizontal == 'left'">
        <Media :content="content.gallery" imgClass="w-full h-auto" imgRounded="xl"/>
      </div>
      <div class="col-span-4 space-y-4" :class="[ content.gallery.position.vertical == 'below' || content.gallery.position.vertical == 'intext' && content.gallery.position.horizontal == 'right' ? '' : '', appearance.backgroundColor == '' && content.gallery.position.vertical == 'intext' ? 'px-6' : '' ]">
        <Headers :content="content" />
        <Bodytext :content="content" v-if="content.bodytext"/>
        <div class="hidden sm:block text-sw-blue-dark">
          <Link :style="content.linkStyle" :text="content.linkText" :link="content.headerLink.url" v-if="content.headerLink != ''"/>
        </div>
      </div>
      <div class="col-span-2" v-if="content.gallery.position.vertical == 'below' || content.gallery.position.vertical == 'intext' && content.gallery.position.horizontal == 'right'">
        <Media :content="content.gallery" imgClass="w-full h-full" imgRounded="xl"/>
      </div>
      <div class="sm:hidden col-span-6 mt-4 text-sw-blue-dark">
        <Link :style="content.linkStyle" :text="content.linkText" :link="content.headerLink.url" class="w-full" linkClass="w-full" v-if="content.headerLink != ''"/>
      </div>
    </div>

    <div class="rounded-lg space-y-2 md:space-y-6" :class="appearance.backgroundColor != '' || appearance.backgroundPadding != 0 ? 'p-4 sm:p-8 lg:p-10' : ''" v-if="content.gallery.position.vertical == 'above' || content.gallery.position.vertical == 'below'">
      <div
          :class="[{'md:grid-cols-2 md:grid md:gap-4': content.gallery.position.vertical == 'intext'}, {'space-y-4': content.gallery.position.vertical == 'below' || content.gallery.position.vertical == 'above'}]">
        <div
            v-if="content.gallery.position.vertical == 'above' || content.gallery.position.vertical == 'intext' && content.gallery.position.horizontal == 'left'">
          <Media :content="content.gallery" imgClass="" imgRounded="xl"/>
        </div>
        <div :class="[{'pt-4 pr-4': content.gallery.position.vertical == 'intext' && content.gallery.position.horizontal == 'right'}, {'pt-4 pl-4': content.gallery.position.vertical == 'intext' && content.gallery.position.horizontal == 'left'}]">
          <Headers :content="content" />
          <Bodytext :content="content" v-if="content.bodytext"/>
        </div>
        <div>
          <Link :style="content.linkStyle" :text="content.linkText" :link="content.headerLink.url" v-if="content.headerLink != ''"/>
        </div>
        <div
            v-if="content.gallery.position.vertical == 'below' || content.gallery.position.vertical == 'intext' && content.gallery.position.horizontal == 'right'">
          <Media :content="content.gallery" imgClass="" imgRounded="xl"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Headers from "@/components/contents/partials/Headers";
import Bodytext from "@/components/contents/partials/Bodytext";
import Link from "@/components/contents/partials/Link";
import Media from "@/components/contents/partials/Media";

export default {
  name: 'Textpic',
  components: {
    Headers,
    Bodytext,
    Link,
    Media
  },
  props: {
    content: Object,
    appearance: Object
  },
}
</script>