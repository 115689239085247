<template>

  <div>
    <hr>
  </div>

</template>

<script>

export default {
  name: 'Div',
}
</script>
