<template>
    <div class="w-full">
        <div class="grid grid-cols-1 sm:grid-cols-2" v-for="(condition, index) in snowconditions" :item="condition" :key="index">
            <div
                class="pt-4 sm:pl-4 sm:py-4 text-left text-sm font-sans-bold uppercase tracking-wider sm:border-b border-sw-gray"
                v-if="index != 'interactive_map'"
            >
                {{ condition.label }}
            </div>
            <div class="pb-4 sm:px-4 sm:py-4 whitespace-nowrap border-b border-sw-gray" v-if="index != 'interactive_map'">
                {{ condition.value }}
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Snow',
    data() {
        return {
            snowconditions: [],
        }
    },
    mounted() {
        axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/wisposki_${this.$i18n.locale}.json`).then((response) => {
            this.snowconditions = response.data
        })
    },
}
</script>
