<template>
  <div>
    <div class="mx-auto grid gap-5 lg:grid-cols-3">
      <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative"
           v-for="emergency in emergenies" :key="emergency">
        <a :href="'tel:' + emergency.tel" v-if="emergency.tel">
          <div class="flex-1 p-6 flex flex-col justify-between">
            <div class="flex-1">
              <div class="block mt-2">
             <span
                 class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-500 text-green-100"
                 v-if="emergency.filter.city">{{ emergency.filter.city }}</span>
                <h3 class="font-sans-bold text-md mb-0 mt-2 h4">
                  {{ emergency.name }}
                </h3>
                <p class="text-sm" v-if="emergency.address">
                  {{ emergency.address }}
                </p>
              </div>
            </div>
            <div class="mt-4">
              <div class="space-y-2 text-lg">
              <span class="flex justify-end items-center text-3xl transition ease-in-out duration-150">
                <svg class="flex-shrink-0 h-7 w-7">
                  <use xlink:href="#icon--phone"></use>
                </svg>
                <span class="ml-3">{{ emergency.tel }}</span>
                </span>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div class="mt-5 pt-5 border-t lg:col-span-3"></div>

      <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative"
           v-for="emergency in emergencynumbers" :key="emergency">
        <a :href="'tel:' + emergency.tel" v-if="emergency.tel">
          <div class="flex-1 p-6 flex flex-col justify-between">
            <div class="flex-1">
              <div class="block mt-2">
             <span
                 class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-500 text-green-100"
                 v-if="emergency.filter.city">
              {{ emergency.filter.city }}
            </span>
                <h3 class="font-sans-bold text-md mb-0 mt-2 h4">
                  {{ emergency.name }}
                </h3>
                <p class="text-sm" v-if="emergency.address">
                  {{ emergency.address }}
                </p>
              </div>
            </div>
            <div class="mt-4">
              <div class="space-y-2 text-lg">
              <span class="flex justify-end items-center text-3xl transition ease-in-out duration-150">
                <svg class="flex-shrink-0 h-7 w-7">
                  <use xlink:href="#icon--phone"></use>
                </svg>
                <span class="ml-3">{{ emergency.tel }}</span>
                </span>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Emergencynumbers',
  props: {
    filters: Object
  },
  data() {
    return {
      emergenies: [],
      emergencynumbers: [],
    }
  },
  mounted() {

    let url = process.env.VUE_APP_API_URL+`typo3temp/feed_dk/emergencynumber_${this.$i18n.locale}.json`;
    if (this.filters.emergencytype == 'snowsports'){
      url = process.env.VUE_APP_API_URL+`typo3temp/feed_dk/sos_ski_${this.$i18n.locale}.json`;
    }
    axios.get(url).then(response => {
      this.emergenies = response.data.filter(function (emergencynumber) {
        if (emergencynumber !== undefined && emergencynumber.filter.city == '') {
          return true;
        }
      });
      this.emergencynumbers = response.data.filter(function (emergencynumber) {
        if (emergencynumber !== undefined && emergencynumber.filter.city != '') {
          return true;
        }
      });
    })
  },
}
</script>
