<template>
  <div>
    <section v-if="info !== null && isActive && !closedByUser" class="mx-auto max-w-7xl sm:px-2 lg:px-16">
      <div class="text-sw-white py-2 px-4 relative" :class="info && info.color === 'notice--warning' ? 'bg-sw-orange' : 'bg-red-500'">
        <svg @click="closeAlert(info.title)" class="flex-shrink-0 h-6 w-6 absolute right-2">
          <use xlink:href="  #icon--x"></use>
        </svg>
        <h2 v-if="info && info.title" class="font-sans-extrabold">{{info.title}}</h2>
        <div class="alert-text" v-html="info.text"></div>
        <a v-if="info && info.link" :href="info.link" target="_blank" class="flex">
          → {{ $t('labels.learn-more') }}
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import Mixins from "@/Mixins";

export default {
  name: 'Alert',
  props: {
    isActive: Boolean,
    infos: Object
  },
  data() {
    return {
      info: null,
      closedByUser: false,
    }
  },
  methods: {
    getTimstamp: function() {
      return Math.floor(Date.now() / 1000)
    },
    closeAlert: function(title) {
      //localStorage.setItem("alertClosed1", this.hashCode(title))
      this.removeLocalStorageExpiry()
      this.setLocalStorageWithExpiry("alertClosed_"+this.hashCode(title), true, 300000)
      this.closedByUser = true
    },
    hashCode: function(string) {
      var hash = 0,
          i, chr;
      if (string.length === 0) return hash;
      for (i = 0; i < string.length; i++) {
        chr = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return Math.abs(hash);
    },
    setLocalStorageWithExpiry: function(key, value, ttl) {
      const now = new Date()
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item))
    },
    removeLocalStorageExpiry:function(){
      for (let key in localStorage){
        if (key.includes("alertClosed_")){
          this.getLocalStorageWithExpiry(key);
        }
      }
    },
    getLocalStorageWithExpiry: function (key) {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    }
  },
  mounted() {
    let flag = false
    let that = this
    this.infos.forEach(function(info){
      // check start page
      if (!Mixins.methods.getBuddy() && info.pwacategories.includes("pwa")) {
        flag = true
      }

      if (Mixins.methods.getBuddy() && info.pwacategories.includes(Mixins.methods.getBuddy().pwaCategory)) {
        flag = true
      }
      if (flag === true){
        that.info = info;
      }
      flag = false;
    });
    if(that.info && typeof that.info === 'object' && Object.keys(that.info).length>0){
      flag = true
    }
    /*
    if(this.info){
      const hash = this.hashCode(this.info.title);
      console.log(localStorage.getItem("alertClosed1"))
      console.log(hash)
      if(localStorage.getItem("alertClosed1") && localStorage.getItem("alertClosed1") !== String(hash)){
        //localStorage.removeItem("alertClosed1")
        console.log('delete');
      }
    }
    if (localStorage.getItem("alertClosed1")) {
      this.closedByUser = true
    }
    */

    if (
        this.info &&
        this.getLocalStorageWithExpiry("alertClosed_"+this.hashCode(this.info.title))){
      this.closedByUser = true
    }
  }
}
</script>
