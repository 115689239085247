<template>
    <div class="mb-4 sw_textpicslider -mx-4 sm:-mx-6 lg:mx-0" v-if="content">
        <div class="h-auto md:flex text-white text-center md:text-left bg-sw-blue-dark">
            <div class="sw_gradient md:hidden">
                <div class="mb-4 -mt-px mx-auto px-4">
                    <img :src="content.images[0]" class="object-cover rounded-xl" />
                </div>
            </div>
            <div class="hidden md:block p-4 w-6/12">
                <img :src="content.images[0]" class="object-cover rounded-xl" />
            </div>
            <div class="flex-1 px-6 pb-6 md:py-6">
                <p class="text-lg">
                    <b>
                        <span style="color: #fed500">Bergtipp</span><br />
                        {{ content.title }}
                    </b>
                </p>
                <div v-html="content.description"></div>
                <br />
                <a :href="content.link" v-if="content.link" target="_blank"> → {{ $t('labels.learn-more') }} </a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Mountaintip',
    data() {
        return {
            content: null,
        }
    },
    mounted() {
        axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/tipps_${this.$i18n.locale}.json`).then((response) => {
            if (response.data.tips.mountains && response.data.tips.mountains.length > 0) {
                this.content = response.data.tips.mountains[0]
            }
        })
    },
}
</script>
