<template>

  <div>
    <div class="hidden md:block" v-if="sorted_slopes">
      <div class="md:flex border-b">
          <div class="flex-1 px-3 py-2 border-t border-l border-r">
            <b>{{ $t("livestatus.loipen") }}</b>
          </div>
          <div class="w-full md:w-96 flex">
            <div class="text-center px-3 py-2  border-t border-l border-r w-6/12">
              <b>{{ $t("crosscountry.skating") }}</b><br />
              {{skating.open/1000}} {{ $t("crosscountry.km_from") }} {{skating.total/1000}} {{ $t("crosscountry.km_open") }}
            </div>
            <div class="text-center px-3  border-t border-r py-2 w-6/12">
              <b>{{ $t("crosscountry.classic") }}</b><br />
              {{classic.open/1000}} {{ $t("crosscountry.km_from") }} {{classic.total/1000}} {{ $t("crosscountry.km_open") }}
            </div>
          </div>
      </div>
      <div class="md:flex border-b border-l" v-for="slope in sorted_slopes" :key="slope">
        <div class="flex-1 pt-7 md:pt-3 pb-2 px-3 border-r flex justify-between items-center">
          <span>
            <b class="mr-1">{{slope.classic ? slope.classic.initial : slope.skating.initial }}</b> {{slope.classic ? slope.classic.name : slope.skating.name }}
          </span>
          <svg class=" flex-shrink-0 h-4 w-5" v-if="slope.classic ? slope.classic.dog==1 : slope.skating.dog==1">
            <use xlink:href="#icon--dog"></use>
          </svg>
        </div>
        <div class="w-full md:w-96 flex border-t md:border-t-0">
          <div class="text-center border-r w-6/12 text-white" :class="[slope.skating ? (slope.skating.status==1 ? 'bg-green-500' : 'bg-red-500') : '']">
            <b class="block bg-white text-black md:hidden">{{ $t("crosscountry.skating") }} </b>
            <div v-if="slope.skating" class="border-t md:border-t-0 px-3 py-2">
              <b class="uppercase">{{slope.skating.status_text}}</b> ({{slope.skating.dynamic_length}} m)
              <span v-if="slope.skating.slf_status_condition"><br />{{ $t("crosscountry.condition") }}: {{slope.skating.slf_status_condition}}</span>
              <span v-if="slope.skating.statusid == 9 || slope.skating.statusid == 10"><br />{{ $t(`crosscountry.status.${slope.skating.statusid}`) }}</span>
            </div>
          </div>
          <div class="text-center border-r w-6/12 text-white" :class="[slope.classic ? (slope.classic.status==1 ? 'bg-green-500' : 'bg-red-500') : '']">
            <b class="block bg-white text-black md:hidden">{{ $t("crosscountry.classic") }} </b>
            <div v-if="slope.classic" class="border-t md:border-t-0 px-3 py-2">
              <b class="uppercase">{{slope.classic.status_text}}</b> ({{slope.classic.dynamic_length}} m)
              <span v-if="slope.classic.slf_status_condition"><br />{{ $t("crosscountry.condition") }}: {{slope.classic.slf_status_condition}}</span>
              <span v-if="slope.classic.statusid == 9 || slope.classic.statusid == 10"><br />{{ $t(`crosscountry.status.${slope.classic.statusid}`) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md:hidden">
      <div id="skating"></div>
      <div  v-if="skating_slopes.length>0">
        <div class="flex justifiy-between bg-gray-100 py-5 px-2">
          <div class="flex-1"><b>{{ $t("crosscountry.skating") }}</b></div>
          <div><b>{{skating.open/1000}} {{ $t("crosscountry.km_from") }} {{skating.total/1000}} {{ $t("crosscountry.km_open") }}</b></div>
        </div>
        <div class="flex border-b justify-between items-center" v-for="slope in skating_slopes" :key="slope">
          <b class="mr-1 px-2">{{slope.initial }}</b>
          <div class="flex-1">{{slope.name }}</div>
          <svg class="flex-shrink-0 h-4 w-5 mr-3" v-if="slope.dog==1"><use xlink:href="#icon--dog"></use></svg>
          <div class="text-center w-36 text-white py-3 h-full" :class="[slope.status==1 ? 'bg-green-500' : 'bg-red-500']">
            <b class="uppercase">{{slope.status_text}}</b> ({{slope.dynamic_length}} m)
            <span v-if="slope.slf_status_condition"><br />{{ $t("crosscountry.condition") }}: {{slope.slf_status_condition}}</span>
            <span v-if="slope.statusid == 9 || slope.statusid == 10"><br />{{ $t(`crosscountry.status.${slope.statusid}`) }}</span>
          </div>
        </div>
      </div>
      <div id="classic"></div>
      <div  v-if="classic_slopes.length>0" class="mt-5">
        <div class="flex justifiy-between bg-gray-100 py-5 px-2">
          <div class="flex-1"><b>{{ $t("crosscountry.classic") }}</b></div>
          <div><b>{{classic.open/1000}} {{ $t("crosscountry.km_from") }} {{classic.total/1000}} {{ $t("crosscountry.km_open") }}</b></div>
        </div>
        <div class="flex border-b justify-between items-center" v-for="slope in classic_slopes" :key="slope">
          <b class="mr-1 px-2">{{slope.initial }}</b>
          <div class="flex-1">{{slope.name }}</div>
          <svg class="flex-shrink-0 h-4 w-5 mr-3" v-if="slope.dog==1"><use xlink:href="#icon--dog"></use></svg>
          <div class="text-center w-36 text-white py-3 h-full" :class="[slope.status==1 ? 'bg-green-500' : 'bg-red-500']">
            <b class="uppercase">{{slope.status_text}}</b> ({{slope.dynamic_length}} m)
            <span v-if="slope.slf_status_condition"><br />{{ $t("crosscountry.condition") }}: {{slope.slf_status_condition}}</span>
            <span v-if="slope.statusid == 9 || slope.statusid == 10"><br />{{ $t(`crosscountry.status.${slope.statusid}`) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Crosscountry',
  props: {
    slopes: Object,
    type: String
  },
  data(){
    return {
      sorted_slopes: {},
      skating_slopes: {},
      classic_slopes: {},
      skating: {
        open: 0,
        total: 0
      },
      classic: {
        open: 0,
        total: 0
      },
    }
  },
  mounted(){

    let i = 0;

    if(this.type=='dog'){
      this.skating_slopes = this.slopes['skating'].filter(function (slope) {
        if (slope.dog==1) {
          return true;
        }
      });
      this.classic_slopes = this.slopes['classic'].filter(function (slope) {
        if (slope.dog==1) {
          return true;
        }
      });
    }else{
      this.skating_slopes = this.slopes['skating'];
      this.classic_slopes = this.slopes['classic'];
    }

    this.skating_slopes.forEach(slope => {
      i = slope.initial;
      if(slope.status==1){
        this.skating['open'] += parseInt(slope.dynamic_length);
      }
      this.skating['total'] += parseInt(slope.total_length);
      if(!this.sorted_slopes[i]){
        this.sorted_slopes[i] = [];
      }
      this.sorted_slopes[i]['skating'] = slope;
    });

    this.classic_slopes.forEach(slope => {
      i = slope.initial;
      if(slope.status==1){
        this.classic['open'] += parseInt(slope.dynamic_length);
      }
      this.classic['total'] += parseInt(slope.total_length);
      if(!this.sorted_slopes[i]){
        this.sorted_slopes[i] = [];
      }
      this.sorted_slopes[i]['classic'] = slope;
    });

    const el = document.getElementById(this.$route.hash.replace('#',''))
    if(el){
      setTimeout(function (){
        el.scrollIntoView()
      },600)
    }



  }
}
</script>
