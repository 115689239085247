<template>
    <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 overflow-hidden">
        <img
            class="w-full md:h-96 object-cover mb-10"
            :src="'https://res.cloudinary.com/dgr25kh64/image/fetch/c_fill,f_auto,h_432,q_80,w_768/' + event.image"
            alt=""
            width="1310"
            height="873"
        />

        <div>
            <p
                class="text-xs mt-1.5 mb-4 tracking-wide font-sans-bold uppercase inline-block uppercase px-1.5 py-1 rounded-md bg-sw-blue bg-opacity-10 text-sw-blue"
                v-if="event.tags && event.tags[0]"
            >
                {{ event.tags[0] }}
            </p>
            <h2 class="font-sans-bold">{{ event.title }}</h2>
            <h3 class="font-sans-bold" v-if="event.head">{{ event.head }}</h3>
            <p v-html="event.description"></p>
        </div>
        <div class="mt-10 pb-4 border-b border-d-blue md:flex md:justify-between space-y-6 md:space-y-0 md:space-x-8">
            <div>
                <p class="font-sans-bold" v-for="date in event.dateList" :key="date">{{ date }}</p>
            </div>
        </div>
        <div class="mt-10 pb-4 border-b border-d-blue md:flex md:justify-between space-y-6 md:space-y-0 md:space-x-8">
            <div class="sm:flex space-y-6 sm:space-y-0 sm:space-x-8">
                <div class="w-full">
                    <p v-html="event.adr_host"></p>
                </div>
                <div class="w-full">
                    <p v-html="event.adr_info"></p>
                </div>
            </div>
        </div>

        <div class="event-link__buttons" v-if="event.links"  v-html="event.links">
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'EventDetail',
    data() {
        return {
         event: {}
        }
    },
    mounted() {
        this.$emit('onEventDetailMounted')
        window.scrollTo(0, 0)
        let eventId = this.$route.params.eventId ?? window.location.pathname.split("/").pop().split("-")[1];
        axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/events/${eventId}.json `).then((response) => {
            this.event = response.data[this.$i18n.locale]
        })
    },
}
</script>

<style>
  .event-link__buttons {
    margin-top: 30px;
  }
  .event-link__buttons a{
      font-weight: 800;
      padding-left: .75rem;
      padding-right: .75rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      display: inline-block;
      padding: 10px 20px;
      font-size: 16px;
      text-align: center;
      text-decoration: none;
      background-color: rgba(8,152,202,var(--tw-bg-opacity));
      border: 1px solid #000;
      border-radius: 9999px;
      color: #000;
      background-color: #ffffff;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s, border-color 0.3s;
      margin-bottom: 10px;
  }

  .event-link__buttons a:hover {
    background-color: #000;
    color: #ffffff;
    border-color: #000;
  }
</style>
