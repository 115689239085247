<template>

  <div class="">
    <div class="mt-8" v-if="report.lastupdate">
      <div v-if="!filters.dogs || filters.dogs != 1">
        <p class="uppercase mb-5">{{ $t("general.lastUpdate") }}: {{report.lastupdate}}</p>
        <tabs>
          <tab title="Davos">
            <Crosscountry :slopes="report.davos" v-if="report.davos" />
          </tab>
          <tab title="Klosters">
            <Crosscountry :slopes="report.klosters" v-if="report.klosters" />
          </tab>
        </tabs>
      </div>
      <div v-if="filters.dogs || filters.dogs == 1">
        <Crosscountry :slopes="report.davos" type="dog" v-if="report.davos"/>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import Tabs from "@/components/Tabs";
import Tab from "@/components/Tab";
import Crosscountry from "@/components/integrations/partials/Crosscountry";

export default {
  name: 'CrosscountryReport',
  components: {
    Crosscountry,
    Tab,
    Tabs
  },
  props: {
    filters: Object,
    appearance: Object,
  },
  data() {
    return {
      report: [],
    }
  },
  methods: {
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/crosscountry_${this.$i18n.locale}.json`).then(response => {
      this.report = response.data
    })
  },
}
</script>
