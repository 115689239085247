<template>
  <div>
    <a v-if="style == 0 || style == 1 || style == 2 || style == 3 || style == 4 || style == 5 || style == 6"
       :href="getLink" :target="target"
       class="inline-flex items-center justify-center px-4 py-2 border border-transparent font-sans-bold rounded-full transition duration-200 ease-in-out mt-2"
      :class="[{'bg-sw-orange text-white': style == 0},
      {'bg-sw-blue-dark text-white': style == 6},
      {'bg-white text-current': style == 1},
      {'bg-sw-blue text-white': style == 2},
      {'bg-sw-violet text-white': style == 3},
      {'bg-sw-orangered text-white': style == 4},
      {'bg-sw-green text-white': style == 5},
      linkClass]">
      {{ text }}
    </a>

    <a v-if="style == 10 || style == 11 || style == 12 || style == 13 || style == 14 || style == 15 || style == 16"
       :href="getLink" :target="target"
       class="inline-flex items-center justify-center font-sans-bold transition duration-200 ease-in-out"
      :class="[{'text-sw-orange': style == 10},
      {'text-sw-blue-dark': style == 16},
      {'text-white': style == 11},
      {'text-sw-blue': style == 12},
      {'text-sw-violet': style == 13},
      {'text-sw-orangered': style == 14},
      {'text-sw-green': style == 15},
      linkClass]">
      {{ text }} →
    </a>

    <a v-if="style == 20"
       :href="getLink" class="flex items-center justify-center h-8 w-8 bg-gray-500 text-white rounded-sm absolute top-2 right-2"
       :target="target"
       :class="linkClass">
      <svg class="h-5 w-5 text-white">
        <use :xlink:href="'#icon--x'"></use>
      </svg>
    </a>

    <a v-if="style == 21" :href="getLink" :target="target"
       class="inline-flex items-center justify-center px-3 py-2 border border-transparent font-sans-bold rounded-full transition duration-200 ease-in-out w-full mt-2 bg-white text-current">
      {{ text }}
    </a>

    <a v-if="style == 100"
       :href="getLink" class="bg-sw-red text-white inline-flex items-center justify-center px-3 py-2 border border-transparent font-sans-bold rounded-full transition duration-200 ease-in-out"
       :class="linkClass"
       :target="target">

      <svg class="h-5 w-5 text-white mr-2">
        <use :xlink:href="'#icon--phone'"></use>
      </svg>

      {{ text }}
    </a>

    <a v-if="style == 200"
       :href="getLink" class="inline-flex items-center justify-center px-3 py-2 border border-transparent font-extrabold rounded-full transition duration-200 ease-in-out bg-sw-blue text-white"
       :target="target"
       :class="linkClass">

      <svg class="h-5 w-5 text-white mr-2">
        <use :xlink:href="'#icon--directions'"></use>
      </svg>

      {{ text }}
    </a>

  </div>
</template>

<script>
export default {
  name: 'Link',
  props: {
    link: String,
    target: String,
    text: String,
    linkClass: String,
    style: Number,
  },
  computed: {
    getLink() {
      if (typeof this.link === 'object') {
        return this.link.url
      }

      return this.link
    }
  }
}
</script>
