<template>
  <div>
    <TabGroup>
      <TabList class="border border-gray-200 rounded-full relative">
        <div id="mobile-slide-arrow" class="w-10 h-full absolute right-0 top-2/4 transform -translate-y-2/4 z-20 flex lg:hidden items-center bg-white rounded-l-none rounded-full justify-center">
          <svg class="h-4 w-5">
            <use xlink:href="#icon--chevron-right"></use>
          </svg>
        </div>
        <div class="flex overflow-auto relative">
          <Tab v-slot="{ selected }" v-for="tab in tabs" :key="tab" as="template">
            <button :class="(selected ? 'bg-black text-white ' : '') + 'flex-shrink-0 px-4 md:px-6 py-2 tracking-wider border border-transparent font-sans-bold rounded-full transition duration-200 ease-in-out focus:outline-none'">
              {{ tab.title }}
            </button>
          </Tab>
        </div>
      </TabList>
      <TabPanels>
        <TabPanel v-for="(tab, index) in tabs" :key="index">
          <div class="mt-4">
            <component :is="tab.component" :filters="{}" />
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import FacilitiesSummer from "@/components/integrations/FacilitiesSummer";
import OutdoorFacilities from "@/components/integrations/OutdoorFacilities";
import Pool from "@/components/integrations/Pool";
import Avalanches from "@/components/integrations/Avalanches";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

export default {
  name: 'LiveStatusSummer',
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    FacilitiesSummer,
    OutdoorFacilities,
    Pool,
    Avalanches
  },
  data() {
    return {
      tabs: [
        {
          title: this.$t("livestatus.facilitiessummer"),
          component: "FacilitiesSummer"
        },
      /*  {
          title: this.$t("livestatus.outdoorfacilities"),
          component: "OutdoorFacilities"
        },*/
        {
          title: this.$t("livestatus.pool"),
          component: "Pool"
        },
        {
          title: this.$t("livestatus.lawinen"),
          component: "Avalanches"
        }
      ]
    }
  },
}
</script>
