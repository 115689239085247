<template>
  <div :class="fullscreen ? 'map-fullscreen' : ''">
    <GMapMap :center="center"
             :options="getOptions"
             :zoom="zoom"
             :map-type-id="mapType"
             style="width: 100vw; height: 900px"
             ref="mapRef"
    >
      <GMapCluster :styles="clusterStyle" :zoomOnClick="true">

        <GMapPolyline v-if="trail" :path="trail.path" :options="trail.pathOptions"/>
        <template v-if="Array.isArray(trails)">
          <GMapPolyline v-for="(t, index) in trails" :key="index" :path="t.path" :options="t.pathOptions" :clickable="true" @click="openPolyline(index)"/>
        </template>

        <GMapMarker :key="index" v-for="(m, index) in getFilteredMarkers" :position="m.position" :clickable="true" :draggable="false" @click="openMarker(m,m.id)" :icon="getMarkers(m)">
          <GMapInfoWindow
              :closeclick="true"
              @closeclick="deSelectMarker()"
              :opened="openedMarkerID === m.id">
            <div>
              <p>
                <b style="font-size:16px">{{m.header}}</b><br v-if="m.description" />
                {{m.description}}<br />
              </p>
              <Link :style="200" :text="$t('general.plan_route')" target="_blank" :link="m.link" :class="'w-full mt-4'" :linkClass="'w-full'" v-if="m.link" />
              <Link :style="200" :text="$t('general.plan_route')" target="_blank" :link="m.dirLink" :class="'w-full mt-4'" :linkClass="'w-full'" v-if="m.dirLink" />
            </div>
          </GMapInfoWindow>
        </GMapMarker>
      </GMapCluster>
    </GMapMap>
  </div>
</template>


<style>
/*.full-h-screen {
  height: calc(100vh - 140px);
  height: calc(var(--vh, 1vh) * 100 - 140px);
}
@media only screen and (max-width: 991px) {
  .full-h-screen {
    height: calc(100vh - 120px);
    height: calc(var(--vh, 1vh) * 100 - 120px);
  }
}*/
</style>

<script>
import Link from "@/components/contents/partials/Link";
import Mixins from "@/Mixins";

export default {
  name: 'GMaps',
  mixins: [Mixins],
  props: {
    markers: Object,
    trails: {
      type: Array,
      default() {
        return null
      }
    },
    activeTrail: {
      type: Object,
      default() {
        return null
      }
    },
    trail: {
      type: Object,
      default() {
        return null
      }
    },
    mapType: {
      type: String,
      default() {
        return "roadmap"
      }
    },
    center: {
      type: Object,
      default() {
        return {lat: 46.802746, lng: 9.835970};
      }
    },
    zoom: {
      type: Number,
      default: 12
    },
    filter: String,
    disableControls: {
      type: Boolean,
      default: true
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return {
          styles: [
            {
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "administrative.neighborhood",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            }
          ]
        }
      }
    }
  },
  components: {
    Link,
  },
  computed: {
    getFilteredMarkers: function() {
      if(this.filter){
        return this.markers.filter(marker => marker.type === this.filter || marker.type === "me")
      }
      return this.markers
    },
    getOptions: function() {
      const options = {...this.options}

      if (this.disableControls) {
        options.zoomControl = false
        options.streetViewControl = false
        options.fullscreenControl = false
        options.rotateControl = false
        options.mapTypeControl = false
        options.scaleControl = false
      }

      return options
    }
  },
  data() {
    return {
      currentUserPosition: null,
      openedMarkerID: null,
      clusterStyle: [
        {
          height: 53,
          url: "/images/m1.png",
          width: 53,
          anchorText: [20, 0],
          anchorIcon: [36, 37],
          textColor: "#FFFFFF"
        },
        {
          height: 56,
          url: "/images/m2.png",
          width: 56,
          anchorText: [21, 0],
          anchorIcon: [36, 37],
          textColor: "#FFFFFF"
        },
        {
          height: 66,
          url: "/images/m3.png",
          width: 66,
          anchorText: [20, 0],
          anchorIcon: [36, 37],
          textColor: "#FFFFFF"
        },
        {
          height: 78,
          url: "/images/m4.png",
          width: 78,
          anchorText: [20, 0],
          anchorIcon: [36, 37],
          textColor: "#FFFFFF"
        },
        {
          height: 90,
          url: "https://github.com/googlemaps/js-marker-clusterer/tree/gh-pages/images/m5.png",
          width: 90,
          anchorText: [20, 0],
          anchorIcon: [36, 37],
          textColor: "#FFFFFF"
        }
      ],
      selectedKey: null,
      selectedMarker: null,
      mapMarkerActive : null,
      mapMarker : null
    }
  },
  methods: {
    getMarkers: function(m) {
      if (this.mapMarkerActive!=null && this.selectedKey === m.id){
        return this.mapMarkerActive;
      }
      return m.icon;
    },
    openMarker: function(marker, id) {
      if (this.$attrs && this.$attrs.onMarkerClick) {
        this.$emit("markerClick", id)
      } else {
        this.openedMarkerID = id
      }
      this.selectedMarker = marker;
      this.selectedKey = id;
    },
    openPolyline: function(index) {
      if (this.$attrs && this.$attrs.onPolylineClick) {
        this.$emit("polylineClick", index)
      }
    },
    deSelectMarker: function() {
      this.openedMarkerID = null
    }
  },
  // mounted() {
  //   var element = document.querySelector('.full-h-screen');
  //   let vh = window.innerHeight * 0.01;
  //   element.style.setProperty('--vh', `${vh}px`);
  //
  //   window.addEventListener('resize', () => {
  //     let vh = window.innerHeight * 0.01;
  //     element.style.setProperty('--vh', `${vh}px`);
  //   });
  // }
}
</script>
