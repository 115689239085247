import $ from 'jquery';

export class DavosReports {
    constructor() {
        const that = this;
        that.integrations = $('.davos-reports__integrations');
        that.settingChart = {
            widthOfBars : 12
        };
        if (that.integrations.length){
            that.initIntegrations(that.integrations);
        }

    }

    initIntegrations(contentElement){
        const that = this;
        that.location =  $('#elm-container').data('location');
        contentElement.each(function() {
            let $elm = $(this);
            let integrationType = $elm.data('integration-type');
            switch(integrationType) {
                case 'poolASE':
                    that.initGradient($elm,integrationType);
                    setInterval(function(){that.initGradient($elm,integrationType);}, 30000);
                    that.initChart($elm,integrationType);
                    break;
                case 'trafficASE':
                    that.initGradient($elm,integrationType);
                    setInterval(function(){that.initGradient($elm,integrationType);}, 30000);
                    that.initChart($elm,integrationType);
                    break;
                case 'spaASE':
                    that.initGradient($elm,integrationType);
                    setInterval(function(){that.initGradient($elm,integrationType);}, 30000);
                    that.initChart($elm,integrationType);
                    break;
                default:
            }
        })

    }

    initGradient($elm,integrationType){
        const that = this;
        let keyValue = '';
        let keyMaxValue = '';

        if ($elm){
            let gradientGray = $elm.find('.c-bar-gradient__gray');
            let urlJson = '';

            switch(integrationType) {
                case 'poolASE':
                    urlJson = that.createUrl('https://www.davos.ch/fileadmin/davos/various/eau-la-la/json/stats.json');
                    keyValue = 'pool';
                    keyMaxValue = 'maxPool';
                    break;
                case 'spaASE':
                    urlJson = that.createUrl('https://www.davos.ch/fileadmin/davos/various/eau-la-la/json/stats.json');
                    keyValue = 'sauna';
                    keyMaxValue = 'maxSauna';
                    break;
                case 'trafficASE':
                    urlJson = that.createUrl('https://www.davos.ch/fileadmin/davos/extensions/davos_reports/json/trafficRealTime.json');
                    keyValue = 'occupancy';
                    keyMaxValue = 'maxOccupancy';
                    break;
            }

            if (urlJson!==''){
                $.ajax({
                    url: urlJson,
                    method: "GET",
                    cache: false
                })
                .done(function( data ) {
                    //console.log(data);
                    if (keyValue!=='' && keyMaxValue!==''){
                        let percentage = 100 - data[keyValue]*100/ data[keyMaxValue];
                        gradientGray.css('width', percentage + '%');
                        const $labelLeft = $elm.find('.c-chart__label-left span');
                        const $labelRight = $elm.find('.c-chart__label-right span');
                        if ($labelLeft.length>0 && data['updateTime'] != null && data['updateTime'].trim() != ''){
                            $labelLeft.html(data['updateTime']+' h');
                        }
                        if ($labelRight.length>0){
                            $labelRight.html(data[keyValue]);
                        }


                        if (integrationType === 'trafficASE'){
                            let rangeHeight = 750;
                            let rangeMedium = 300;
                            const $iconsRight = $elm.find('.c-chart__label-right .icon');
                            $iconsRight.attr('class','icon icons-traffic');
                            if (data[keyValue]>rangeHeight){
                                $iconsRight.addClass('icons-traffic-high')
                            }else{
                                if(data[keyValue]>rangeMedium && data[keyValue]<=rangeHeight){
                                    $iconsRight.addClass('icons-traffic-medium');
                                }else{
                                    $iconsRight.addClass('icons-traffic-low');
                                }
                            }
                        }
                    }

                });
            }
        }
    }

    initChart($elm,integrationType) {
        const that = this;
        const $contentBar = $elm.find('.c-chart__content-bars');
        $elm.find('.c-chart__openclose').on('click',function(){
            $contentBar.toggleClass('js-is-open');
            $(this).toggleClass('js-is-open');

            if ($contentBar.is(":visible") && !$contentBar.hasClass('js-is-loading')){
                $contentBar.addClass('js-is-loading');
                that.loadChart($elm,integrationType);
            }

        });
        if ($contentBar.hasClass('js-is-loading')){
            that.loadChart($elm,integrationType);
        }
    }

    loadChart($elm,integrationType) {
        const that = this;
        const dayDate = new Date();

        //selezione giorno
        let dayNumber = dayDate.getDay();
        //console.log(dayNumber);
        let $elmToday = $elm.find('.c-chart__header-day[data-day="'+dayNumber+'"]');
        $elmToday.addClass('selected');

        const $elmDays = $elm.find('.c-chart__header-day');
        const $elmChart = $elm.find('.c-chart__bars');
        const $elmAllBar= $elm.find('.c-chart__bar');


        let infoDays = [];

        $.ajax({
            url: that.getUrlDays(integrationType),
            method: "GET",
            cache: false
        }).done(function( data ) {
            infoDays = data;
            $elmDays.each(function (){
                $(this).on('click', function () {
                    let $elm = $(this);
                    $elmDays.removeClass('selected');
                    $elmAllBar.removeClass('c-chart__bar-selected');
                    $elm.addClass('selected');
                    that.chartReadInfoDay($elmChart,infoDays,$elm.data('day'),100);
                });
            });

            //Selezione barre
            $elmAllBar.each(function (){
                let elmDay = $(this);
                elmDay.on('click', function () {
                    let $elm = $(this);
                    $elmAllBar.removeClass('c-chart__bar-selected');
                    $elm.addClass('c-chart__bar-selected');
                    that.chartAlignElmPointer($elmChart);
                });
            });


            that.chartReadInfoDay($elmChart,infoDays,dayNumber, 500);
            //leggere i dati di giorno
            window.addEventListener('resize', function(){
                that.chartAlignElmPointer($elmChart);
            });

        }).fail(function() {
            console.log('Error loading Info day'+integrationType)
        });

    }

    //leggere i dati di giorno
    chartReadInfoDay($elmChart,infoDays,dayNumber,delay ){
        const that = this;
        //console.log('read '+dayNumber);
        const dayDate = new Date();
        infoDays.forEach((day, index) => {
            if (index===dayNumber){
                //Cambiare con il valore max
                //let valueMax =  Math.max(...day.map(hour => hour.value));
                let valueMax =  $elmChart.data('maxValue');
                valueMax = (valueMax !== undefined) ? parseInt(valueMax) : 100;
                let currentTime = dayDate.getHours();
                day.forEach((hour) => {
                    let $bar = $elmChart.find('[data-hour="'+hour.hour+'"]');
                    let $barHeight = $elmChart.find('[data-hour="'+hour.hour+'"] .c-chart__bar-height');
                    if ($bar.length && $barHeight.length){
                        let valueInProportion;
                        if (parseInt(hour.value) !== 0 && valueMax !== 0) {
                            valueInProportion = Math.round(100 * parseInt(hour.value) / valueMax);
                        } else {
                            valueInProportion = 0;
                        }
                        $bar.attr('data-value' ,hour.value);
                        setTimeout(function(){
                            $barHeight.css('height',valueInProportion+'px');
                        }, delay);
                        if (currentTime===parseInt(hour.hour)){
                            $bar.addClass('c-chart__bar-selected');
                            that.chartAlignElmPointer($elmChart);
                        }
                    }
                });
                $elmChart.removeClass('loading');
            }
        });
    }

    chartAlignElmPointer($elmChart){
        const that = this;
        const $elmPointer = $elmChart.find('#c-chart__bar-radio-space');
        const $barSelected = $elmChart.find('.c-chart__bar.c-chart__bar-selected .c-chart__bar-height');
        const elemRectBarSelected = $barSelected.get(0).getBoundingClientRect();
        const elemRectChart = $elmChart.get(0).getBoundingClientRect();
        let left = elemRectBarSelected.right - ( that.settingChart.widthOfBars / 2 ) - elemRectChart.x ;
        $elmPointer.css('left',left + 'px') ;
        that.chartSetMessage($elmChart,left);
    }

    chartSetMessage($elmChart,left){
        const $elmMessageSpace = $elmChart.find('.c-chart__bars-message');
        const $elmMessage = $elmMessageSpace.find('.c-chart__bars-message-block');
        const $barSelected = $elmChart.find('.c-chart__bar.c-chart__bar-selected');
        let textHour = $barSelected.data('hour') + ':00 ';
        if ($barSelected.data('hour')<10) textHour = '0' + textHour;
        const $elmTextHour = $elmMessage.find('.c-chart__bars-message-text-hours');
        $elmTextHour.html(textHour);
        const $elmTextComment = $elmMessage.find('.c-chart__bars-message-text-comment');
        let textComment = '';
        let rangeHeight = $elmChart.data('rangeHeight');
        rangeHeight = (rangeHeight !== undefined) ? parseInt(rangeHeight) : 70;
        let rangeMedium = $elmChart.data('rangeMedium');
        rangeMedium = (rangeMedium !== undefined) ? parseInt(rangeMedium) : 25;
        let valueSelected = $barSelected.attr('data-value');
        if (valueSelected>rangeHeight) textComment = $elmMessageSpace.data('message-high');
        if (valueSelected>rangeMedium && valueSelected<=rangeHeight) textComment = $elmMessageSpace.data('message-medium');
        if (valueSelected<=rangeMedium) textComment = $elmMessageSpace.data('message-low');
        $elmTextComment.html(textComment) ;

        const elemRectMessage = $elmMessage.get(0).getBoundingClientRect();
        const elemRectMessageSpace = $elmMessageSpace.get(0).getBoundingClientRect();
        let leftValue = left - (elemRectMessage.width/2);
        if (leftValue<0) {
            leftValue = 0;
        }
        if (leftValue > (elemRectMessageSpace.width - elemRectMessage.width)) {
            $elmMessage.css('right','0px') ;
            $elmMessage.css('left','') ;
        }else{
            $elmMessage.css('right','') ;
            $elmMessage.css('left',leftValue +'px') ;
        }
    }



    getUrlDays(integrationType){
        let that = this;
        let urlJson = '';
        if (integrationType === 'spaASE'){
            urlJson = that.createUrl('https://www.davos.ch/fileadmin/davos/various/eau-la-la/json/spaAse.json');
        }
        if (integrationType === 'poolASE'){
            urlJson = that.createUrl('https://www.davos.ch/fileadmin/davos/various/eau-la-la/json/poolAse.json');
        }
        if (integrationType === 'trafficASE'){
            urlJson = that.createUrl('https://www.davos.ch/fileadmin/davos/extensions/davos_reports/json/trafficForecast.json');
        }
        return urlJson;
    }
    rand(){
        const min = 1
        const max = 1000;
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    createUrl(url){
        return url;
    }
}
