<template>
    <div>
        <div v-if="posts" class="mx-auto w-full h-full">
            <swiper
                class="relative w-full pt-2 px-2 -mx-2 -mt-2 pb-12"
                :slides-per-view="1.15"
                :space-between="15"
                :breakpoints="{
                    640: {
                        slidesPerView: 2.25,
                        spaceBetween: 25,
                    },
                    1200: {
                        slidesPerView: 3.25,
                        spaceBetween: 25,
                    },
                }"
            >
                <swiper-slide v-for="(post, index) in posts" :key="index" class="h-auto">
                    <section class="h-full flex flex-col rounded-lg shadow-sw-1 bg-sw-white relative">
                        <div class="flex-1 px-4 pt-4 pb-4 flex flex-col justify-between">
                            <div class="flex-1">
                                <a target="_blank" class="block" :href="post.url">
                                    <div class="text-xs mt-1.5 mb-4 tracking-wide font-sans-bold uppercase inline-block uppercase px-1.5 py-1 rounded-md bg-sw-blue bg-opacity-10 text-sw-blue">
                                        <span class="flex" v-if="post.network_name === 'Facebook'">
                                            <svg class="w-4 h-4 text-white mr-2">
                                                <use :xlink:href="'#icon--facebook-circle'"></use>
                                            </svg>
                                            Facebook
                                        </span>
                                    </div>
                                    <p class="text-md font-sans my-2 h-48 overflow-hidden" v-html="post.text"></p>
                                </a>
                            </div>

                            <div class="mt-1">
                                <a target="_blank" :href="post.url" class="font-sans-bold text-cu text-sw-blue">{{ $t('labels.more') }}</a>
                            </div>
                        </div>
                        <div class="flex-shrink-0" v-if="post.image">
                            <a target="_blank" :href="post.url">
                                <img
                                    class="h-36 w-full object-cover rounded-b-xl"
                                    :src="post.image"
                                />
                            </a>
                        </div>
                    </section>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import 'swiper/swiper-bundle.css'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import Mixins from '@/Mixins'

    export default {
        name: "SocialMedia",
        mixins: [Mixins],
        props: {
            appearance: Object
        },
        data() {
            return {
                posts: null
            }
        },
        components: {
            Swiper,
            SwiperSlide
        },
        mounted() {
            axios.get(this.appearance.socialmedia_feed).then(response => {
                this.posts = response.data.posts
            })
        }
    }
</script>