<template>
  <div>
    <div id="oa_integration"></div>
  </div>
</template>

<script>
export default {
  name: "OaIntegrationEmbed",
  props: {
    appearance: Object
  },
  mounted() {
    let script = document.createElement('script')
    script.src = this.appearance.embed_url;
    document.getElementById('oa_integration').insertAdjacentElement('afterend', script);
  }
}
</script>