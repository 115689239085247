<template>
  <div class="grid grid-cols-1 gap-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
    <div v-for="stage in data" :key="stage" class="rounded-lg shadow-sw-1 bg-sw-white px-4 py-4">
      <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
        <div class="ml-4 mt-4">
          <h3 class="font-sans-bold text-md mb-0 mt-2 h4">{{ stage.name }}</h3>
          
          <div class="flex">
            <span>{{ stage.distance + " km" }}</span>
            <span class="mx-4">|</span>
            <span>{{ stage.hoehenmeter + " hm" }}</span>
          </div>
          
          <a target="_blank" :href="stage.gpx_url" class="inline-flex items-center justify-center px-3 py-2 border border-transparent rounded-full transition duration-200 ease-in-out bg-sw-blue text-white w-full mt-4">
            GPX Datei herunterladen
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Mixins from "@/Mixins";

export default {
  name: 'LaceupStages',
  mixins: [Mixins],
  data() {
    return {
      data: null
    }
  },
  methods: {
   
  },
  mounted() {
    axios.get(`https://app.laceup.io/api/stages.json?tour.slug=bikestar-davos-2022`).then(response => {
      this.data = response.data

      // fix for hm
      this.data = response.data.map(x => ({...x, hoehenmeter: "10"}))
      console.log(this.data)
    })
  }
}
</script>
