<template>

  <div v-if="content.bodytext" v-html="content.bodytext"></div>

</template>

<script>
export default {
  name: 'Bodytext',
  props: {
    content: Object,
  },
}
</script>