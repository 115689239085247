<template>
  <div>
    <div class="bg-white">
      <div class="mx-auto py-4">
        <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-6">
          <div class="space-y-5 sm:space-y-4">
            <Headers :content="content" />
            <Bodytext :content="content"/>
          </div>
          <div class="lg:col-span-2">
            <ul role="list" class="space-y-12 sm:grid sm:grid-cols-1 sm:gap-12 sm:space-y-0 lg:gap-x-8">
              <li v-for="athlete in data" :key="athlete">
                <div class="flex items-center space-x-4 lg:space-x-6">
                  <img class="w-16 h-16 rounded-full lg:w-20 lg:h-20" :src="athlete.athlete.profile" alt="">
                  <div class="leading-6 space-y-1">
                    <h3 class="block text-2xl leading-8 font-sans-extrabold tracking-tight sm:text-2xl">{{ `#${athlete.rank} ${athlete.athlete.firstname} ${athlete.athlete.lastname}` }}</h3>
                    <p>{{ `${athlete.value} von ${athlete.total} Strecken absolviert` }}</p>

                    <button
                      type="button"
                      @click="showDetails(athlete)"
                      class="justify-center px-5 py-2 border border-transparent font-extrabold rounded-full transition duration-200 ease-in-out bg-sw-red text-white"
                    >
                      Details
                    </button>

                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-50">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div class="flex cursor-pointer items-center justify-center h-8 w-8 rounded-sm absolute top-2 right-2" @click="closeModal">
                  <svg class="h-5 w-5 text-black"><use xlink:href="#icon--x"></use></svg>
                </div>
                <div class="flex space-x-4 lg:space-x-6">
                  <img class="w-16 h-16 rounded-full lg:w-20 lg:h-20" :src="selectedAthlete.athlete.profile" alt="">
                  <div class="leading-6">
                    <h3 class="block text-2xl leading-8 font-sans-extrabold tracking-tight sm:text-2xl">
                      {{ selectedAthlete.athlete.name }}
                    </h3>
                    <p>{{ `${selectedAthlete.value} von ${selectedAthlete.total} Strecken absolviert` }}</p>
                    <p><strong>Distanz:</strong> {{ Math.round(selectedAthlete.distance/10)/100 }} km</p>
                    <p><strong>Höhenmeter:</strong> {{ selectedAthlete.elevation_gain }} hm</p>
                  </div>
                </div>

                <h4 class="block text-xl font-sans-bold tracking-tight mt-4">
                  Absolvierte Strecken
                </h4>
                <div class="flow-root max-h-96 overflow-y-auto">
                  <ul role="list" class="-mb-8 ">
                    <li v-for="stage in selectedAthleteStages" :key="stage">
                      <div class="relative pb-8">
                        <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                        <div class="relative flex space-x-3">
                          <div class="items-center flex">
                                <span class="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                                  <svg class="h-5 w-5 text-white"><use xlink:href="#icon--flag"></use></svg>
                                </span>
                          </div>
                          <div class="w-full flex justify-between items-center">
                              <div>
                                <strong class="mr-4">{{ stage.name }}</strong>
                              </div>
                              <div>
                                <span>{{ (Math.round(stage.distance/10)/100) + " km" }}</span>
                                <span class=""> | </span>
                                <span>{{ stage.elevation_gain + " hm" }}</span>
                              </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import axios from "axios";
import Mixins from "@/Mixins";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from '@headlessui/vue'
import Headers from "@/components/contents/partials/Headers";
import Bodytext from "@/components/contents/partials/Bodytext";

export default {
  name: 'LaceupTrophies',
  mixins: [Mixins],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,

    Headers,
    Bodytext,
  },
  props: {
    content: Object
  },
  data() {
    return {
      data: null,
      isOpen: false,
      selectedAthlete: null,
      stages: null,
      selectedAthleteStages: null
    }
  },
  methods: {
    showDetails: function(athlete) {
      this.selectedAthlete = athlete
      this.selectedAthleteStages = athlete.stages
      this.isOpen = true
    },
    closeModal: function() {
      this.isOpen = false
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/bikestar_throphies.json`).then(response => {
      this.data = response.data
    })

    axios.get(`https://app.laceup.io/api/stages.json?tour.slug=bikestar-davos-2022`).then(response => {
      this.stages = response.data
    })
  }
}
</script>
