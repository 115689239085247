<template>

  <div>

    <div class="space-y-2 md:space-y-3" :class="{ 'm-auto w-10/12' : content.headerPosition == 'center'}">
      <Headers :content="content" />
      <Bodytext :content="content"/>
      <Link :style="content.linkStyle" :text="content.linkText" :link="content.headerLink.url" :target="content.headerLink.target" v-if="content.headerLink != ''"/>
    </div>

  </div>

</template>

<script>
import Headers from "@/components/contents/partials/Headers";
import Bodytext from "@/components/contents/partials/Bodytext";
import Link from "@/components/contents/partials/Link";

export default {
  name: 'Text',
  components: {
    Headers,
    Bodytext,
    Link,
  },
  props: {
    content: Object
  },
}
</script>