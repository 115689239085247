<template>

  <div class="sw_textpicslider -mx-4 sm:-mx-6 lg:mx-0">
    <div class="mx-auto">
      <swiper
          class="relative bg-sw-blue-dark" v-if="nested_contents" :class="nested_contents.length > 1 ? 'pb-16' : 'pb-6'"
          :slides-per-view="1"
          :space-between="0"
          :pagination="{ clickable: true }"
          :breakpoints="breakpoints"
      >

        <swiper-slide v-for="(child, index) in nested_contents" :key="index" class="h-auto flex flex-col text-white">
          <div class="px-8 sw_gradient mb-4 -mt-px">
            <a :href="child.content.gallery.rows[1].columns[1].properties.link??child.content.headerLink.url"><Image :gallery="child.content.gallery" :image="child.content.gallery.rows[1].columns[1]" imgClass="" imgRounded="xl" /></a>
          </div>
          <div class="px-8 flex-1">
            <Headers :content="child.content" color="white" />
            <Bodytext :content="child.content" v-if="child.content.bodytext"/>
          </div>
          <div class="px-8">
            <Link :style="child.content.linkStyle" :text="child.content.linkText" :target="child.content.headerLink.target" :link="child.content.headerLink.url" v-if="child.content.headerLink.url != ''"/>
          </div>
        </swiper-slide>

      </swiper>
    </div>
  </div>

</template>

<script>

import 'swiper/swiper-bundle.css';

import { Swiper, SwiperSlide } from 'swiper/vue';
import Link from "@/components/contents/partials/Link";
import Image from "@/components/contents/partials/Image";

import SwiperCore, {
  Pagination
} from 'swiper';
import Headers from "@/components/contents/partials/Headers";
import Bodytext from "@/components/contents/partials/Bodytext";

SwiperCore.use([Pagination]);

export default {
  name: 'TextPicSlider',
  components: {
    Swiper,
    SwiperSlide,
    Headers,
    Bodytext,
    Link,
    Image
  },
  props: {
    content: Object,
    nested_contents: Object,
    appearance: Object
  },
  data() {
    return {
      breakpoints: {
        640: {
          slidesPerView: this.content.children.length<2 ? this.content.children.length : 2,
          spaceBetween: 0,
        },
        1200: {
          slidesPerView: this.content.children.length<3 ? this.content.children.length : 3,
          spaceBetween: 0,
        }
      }
    }
  },
  mounted(){
    console.log(this.breakpoints);
  }
}
</script>
