<template>
  <div>
    <div class="TrailforksTrailList" data-w="100%" data-h="600px" data-rid="32566" data-displaytype="table" data-activitytype="1" data-season="1" data-unsanctioned="0" data-query=""></div>
    <div id="iframeResizer"></div>
  </div>
</template>

<script>
export default {
  name: "TrailForkList",
  props: {
    appearance: Object
  },
  mounted() {
    var iframeResizer = document.createElement("script");
    iframeResizer.setAttribute("src", "https://es.pinkbike.org/326/sprt/j/trailforks/iframeResizer.min.js");
    document.getElementById("iframeResizer").appendChild(iframeResizer);

    var script = document.createElement("script");
    script.setAttribute("src", "https://es.pinkbike.org/ttl-86400/sprt/j/trailforks/widget.js");
    document.getElementsByTagName("head")[0].appendChild(script);
  }
}
</script>