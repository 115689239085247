<template>
    <footer class="bg-d-white text-center">
        <div class="max-w-7xl mx-auto py-12 px-4 mb-10 overflow-hidden sm:px-6 lg:px-8 space-y-4">
            <div class="pb-4">
                <h3 class="mb-4 font-sans-bold">Destination Davos Klosters</h3>

                <p>
                    <strong>Tourismus- und Sportzentrum</strong><br />
                    Talstrasse 41<br />
                    CH-7270 Davos Platz<br />
                    +41 81 415 21 21
                </p>

                <p>
                    <strong>Klosters</strong><br />
                    Alte Bahnhofstrasse 6<br />
                    CH-7250 Klosters<br />
                    +41 81 410 20 20
                </p>

                <p>
                    <a href="mailto:info@davos.ch" class="text-sw-blue-davos">info@davos.ch</a><br />
                    <a href="https://www.davos.ch/" class="text-sw-blue-davos" target="_blank">www.davos.ch</a>
                </p>
            </div>

            <nav class="-mx-5 my-2 pb-6 flex flex-wrap justify-center space-x-4" aria-label="Footer" v-if="socialMedia">
                <div v-for="page in socialMedia" :key="page.name">
                    <a :href="page.link" :target="page.target" class="text-sm text-sw-black hover:text-gray-900" v-if="page.nav_hide_pwa != 1">
                        <span class="flex items-center">
                            <svg class="ml-1 w-5 h-5"><use :xlink:href="'#icon--' + page.title.replace(/\s+/g, '').toLowerCase()"></use></svg>
                        </span>
                    </a>
                </div>
            </nav>

            <nav class="-mx-5 my-2 flex flex-wrap justify-center" aria-label="Footer" v-if="navigation">
                <div v-for="(page, index) in navigation" :key="page.name">
                    <a :href="page.link" :target="page.target" class="text-sm text-sw-black hover:text-gray-900" v-if="page.nav_hide_pwa != 1">
                        <span class="flex items-center">
                            {{ page.title }} <span class="px-2" v-if="index != Object.keys(navigation).length - 1"> | </span>
                            <svg v-if="page.target == '_blank'" class="ml-1 w-4 h-4"><use xlink:href="#icon--external-link"></use></svg>
                        </span>
                    </a>
                </div>
            </nav>

            <a href="/parkplatzfinder" class="block h-10 w-full"></a>

            <div>
                <p>
                    {{ $t('footer.zur_homepage') }}
                    <a href="https://www.davos.ch/" target="_blank" class="font-sans-bold text-sw-blue-davos">davos.ch</a> &
                    <a href="https://www.klosters.ch/" target="_blank" class="font-sans-bold text-sw-blue-davos">klosters.ch</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'TheFooter',
    props: {
        navigation: Array,
        socialMedia: Array,
    },
    mounted() {},
}
</script>
