<template>
  <div>
    <div class="d-block py-2 md:py-2" v-if="space === 'small' || space === ''"></div>
    <div class="d-block py-3 md:py-4" v-if="space === 'medium'"></div>
    <div class="d-block py-4 md:py-6" v-if="space === 'large'"></div>
    <div class="d-block py-5 md:py-8" v-if="space === 'extra-large'"></div>
  </div>
</template>

<script>

export default {
  name: 'Space',
  props: {
    space: String,
  },
}
</script>