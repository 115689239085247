<template>
    <div>
        <div class="flex justify-end mb-3">
            <SwitchGroup>
                <div class="flex items-center">
                    <SwitchLabel class="mr-4">{{ mapView ? $t('general.listenansicht') : $t('general.kartenansicht') }}</SwitchLabel>
                    <Switch
                        v-model="mapView"
                        :class="mapView ? 'bg-blue-600' : 'bg-gray-200'"
                        class="relative inline-flex items-center h-6 transition-colors rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <span
                            :class="mapView ? 'translate-x-6' : 'translate-x-1'"
                            class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full"
                        />
                    </Switch>
                </div>
            </SwitchGroup>
        </div>

        <div v-if="!mapView" class="mx-auto grid gap-5 lg:grid-cols-3">
            <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative" v-for="skischool in skischools" :key="skischool">
                <div class="flex-1 p-6 flex flex-col justify-between">
                    <div class="flex-1">
                        <div class="block mt-2">
                            <h3 class="font-sans-bold text-md mb-0 mt-2 h4">
                                {{ skischool.name }}
                            </h3>
                            <p class="text-sm">
                                {{ skischool.address }}
                            </p>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="space-y-2 text-sm">
                            <a
                                :href="'tel:' + skischool.phone"
                                class="flex items-center text-sm transition ease-in-out duration-150"
                                v-if="skischool.phone"
                            >
                                <svg class="flex-shrink-0 h-4 w-5">
                                    <use xlink:href="#icon--phone"></use>
                                </svg>
                                <span class="ml-3">{{ skischool.phone }}</span>
                            </a>
                            <div>
                                <div class="flex items-center text-sm transition ease-in-out duration-150" v-if="skischool.hours[$i18n.locale]">
                                    <svg class="flex-shrink-0 h-5 w-5">
                                        <use xlink:href="#icon--clock"></use>
                                    </svg>
                                    <span class="ml-3">{{ $t('general.oeffnungszeiten') }}</span>
                                </div>
                                <div class="ml-8 pt-2">
                                    <p class="text-sm">
                                        <span class="block" v-for="hour in skischool.hours[$i18n.locale].split('\n')" :key="hour">
                                            {{ hour }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <Link :style="0" :text="$t('skischools.book_now')" :link="skischool.link" v-if="skischool.link" target="_blank" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="mapView">
            <GMaps :markers="markers" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Link from '@/components/contents/partials/Link'
import GMaps from '@/components/partials/GMaps'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import Mixins from '@/Mixins'

export default {
    name: 'Skischools',
    components: {
        Link,
        Switch,
        SwitchGroup,
        SwitchLabel,
        GMaps,
    },
    props: {
        filters: Object,
    },
    data() {
        return {
            skischools: [],
            sort_by_dist: false,
            markers: [],
            mapView: false,
        }
    },
    methods: {
        dynamicSort(property) {
            var sortOrder = 1
            if (property[0] === '-') {
                sortOrder = -1
                property = property.substr(1)
            }
            return function (a, b) {
                /* next line works with strings and numbers,
                 * and you may want to customize it to your needs
                 */
                var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
                return result * sortOrder
            }
        },
    },
    mounted() {
        var filter_string = ''
        if (this.filters['category'] && this.filters['category'].length != 0) {
            filter_string = '?cat=' + this.filters['category']
        }
        axios.get('https://ferienshop.davos.ch/api/export/suppliers/skischool' + filter_string).then((response) => {
            this.skischools = response.data.sort(this.dynamicSort('status'))
            this.skischools.forEach((skischool) => {
                skischool.dist = Mixins.methods.distanceInKm(skischool.coordinates.lat, skischool.coordinates.lng, this.$t("general.plan_route"))
                if (skischool.dist.dist !== null) {
                    this.sort_by_dist = true
                }
            })
            this.markers = Mixins.methods.setMarker(this.skischools, 'vermietungen')
            if (this.sort_by_dist) {
                this.skischools.sort((a, b) => (a.dist.dist > b.dist.dist ? 1 : -1))
            }
        })
    },
}
</script>
