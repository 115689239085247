<template>
  <div>
    <div id="oa-kinderwagentouren"></div>
  </div>
</template>

<script>
export default {
  name: "OaIntegrationMehrtagestouren",
  mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://api-oa.com/jscr/loader.js?id=137smk')
    document.getElementById('oa-kinderwagentouren').appendChild(externalScript)
  }
}
</script>