import moment from 'moment';

export default {
    data() {
        return {
            position: null,
            isUserMarkerSet: false,
        }
    },
    methods: {
        oaDifficulty: function(difficulty) {
            let classes = ""

            switch(difficulty) {
                case "0":
                    classes = "bg-oa-white"
                    break;
                case "1":
                    classes = "bg-oa-blue text-white"
                    break;
                case "2":
                    classes = "bg-oa-red text-white"
                    break;
                default:
                    classes = "bg-oa-black text-white"
                    break
            }

            return classes
        },
        trailforksDifficulty: function(difficulty) {
            let classes = ""

            switch(difficulty) {
                case "2":
                    classes = "bg-trailforks-white"
                    break;
                case "3":
                    classes = "bg-trailforks-green text-white"
                    break;
                case "4":
                    classes = "bg-trailforks-blue text-white"
                    break;
                default:
                    classes = "bg-trailforks-black text-white"
                    break
            }

            return classes
        },
        oaDifficultyColor: function(difficulty) {
            let color = ""

            switch(difficulty) {
                case "0":
                    color = "#eaeaea"
                    break;
                case "1":
                    color = "#06c"
                    break;
                case "2":
                    color = "#c00"
                    break;
                default:
                    color = "#272727"
                    break
            }

            return color
        },
        trailforksDifficultyColor: function(difficulty) {
            let color = ""

            switch(difficulty) {
                case "2":
                    color = "#eaeaea"
                    break;
                case "3":
                    color = "#46b414"
                    break;
                case "4":
                    color = "#148cf0"
                    break;
                default:
                    color = "#000"
                    break
            }

            return color
        },
        sanitizeString(title) {
            var slug = ''
            var titleLower = title.toLowerCase()
            slug = titleLower.replace('ä', 'ae')
            slug = slug.replace('ö', 'oe')
            slug = slug.replace('ü', 'ue')
            slug = slug.replace('ß', 'ss')
            slug = slug.replace('-', '')
            slug = slug.replace('(', '')
            slug = slug.replace(')', '')
            slug = slug.replace('[', '')
            slug = slug.replace(']', '')
            slug = slug.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
            slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
            slug = slug.replace(/đ/gi, 'd')
            slug = slug.replace(/\s*$/g, '')
            slug = slug.replace(/\s+/g, '-')
            return slug
        },
        shortenString(str, charCount) {
            //trim the string to the maximum length
            var trimmedString = str.substr(0, charCount);

            //re-trim if we are in the middle of a word
            trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))

            return trimmedString
        },
        orderBy(key) {
            return key
        },
        currencyCHF(value, from = false) {
            let val = (value / 1).toFixed(2)
            value = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")

            return (from ? this.$t('general.ab') + ' ' : '') + 'CHF ' + value
        },
        getUserMarker() {
            return {
                position: { lat: parseFloat(this.position.latitude), lng: parseFloat(this.position.longitude) },
                type: 'me',
                icon: {
                    scaledSize: { width: 32, height: 50 },
                    url: '/marker/user.svg',
                },
            }
        },
        setMarker: function (data, icon, starting_marker_id = 0, exactId) {
            let markers = []

            if (typeof this.position != 'undefined') {
                if (!this.isUserMarkerSet) {
                    markers.push(this.getUserMarker())
                    this.isUserMarkerSet = true
                }
            }

            let marker_id = starting_marker_id,
                latitude = 0,
                longitude = 0
            data.forEach((marker) => {
                latitude = parseFloat(marker.geoInfo ? marker.geoInfo.latitude : marker.coordinates.lat)
                longitude = parseFloat(marker.geoInfo ? marker.geoInfo.longitude : marker.coordinates.lng)

                markers.push({
                    id: exactId ? marker.id : marker_id++,
                    header: marker.short_name ?? marker.name,
                    description: marker.address,
                    link: marker.dirLink ?? marker.link,
                    position: { lat: latitude, lng: longitude },
                    type: icon,
                    icon: {
                        scaledSize: { width: 32, height: 50 },
                        url: '/marker/' + icon + '.svg',
                    },
                })
            })
            return markers
        },
        getUserPosition() {
            if (this.position == null) {
                return null
            }
            return {
                lat: this.position.latitude,
                lng: this.position.longitude,
            }
        },
        getHomeUrl() {
            if (this.$i18n.locale === "en") {
                return "/en"
            }

            return "/"
        },
        watchUserPosition() {
            this.position = localStorage.getItem('position') ? JSON.parse(localStorage.getItem('position')) : undefined
            if (!navigator.geolocation) {
                console.log('Geolocation is not supported by your browser')
            } else {
                navigator.geolocation.watchPosition(
                    (position) => {
                        console.log("pos granted")
                        this.position = {
                            latitude: parseFloat(position.coords.latitude),
                            longitude: parseFloat(position.coords.longitude),
                        }
                        var position_string = JSON.stringify(this.position)
                        if (position_string != localStorage.getItem('position')) {
                            localStorage.setItem('position', position_string)
                        }
                    },
                    (positionError) => {
                        console.log("pos error", positionError.message)
                    }
                )
            }
        },
        degreesToRadians(degrees) {
            return (degrees * Math.PI) / 180
        },
        distanceInKm(lat2, lon2, btnText) {
            if (typeof this.position != 'undefined') {
                var earthRadiusKm = 6371,
                    lat1 = parseFloat(this.position.latitude),
                    lon1 = parseFloat(this.position.longitude)
                lat2 = parseFloat(lat2)
                lon2 = parseFloat(lon2)

                var dLat = this.degreesToRadians(lat2 - lat1)
                var dLon = this.degreesToRadians(lon2 - lon1)

                lat1 = this.degreesToRadians(lat1)
                lat2 = this.degreesToRadians(lat2)

                var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

                var distKm = earthRadiusKm * c
                var distM = Math.round(distKm * 1000, 0)

                return {
                    dist: distM,
                    text: distKm < 1 ? distM + ' m' : Math.round(distKm, 2) + ' km',
                }
            } else {
                return {
                    dist: null,
                    text: btnText,
                }
            }
        },
        checkForExpiredGuestcards() {
            let guestCards = localStorage.getItem("guestcards")


            if (guestCards !== null) {
                guestCards = JSON.parse(guestCards)

                guestCards = guestCards.filter((item) => {
                    let valid_to = item.card_holder.valid_to

                    valid_to = moment(valid_to, "DD.MM.YYYY")
                    let today = moment()

                    if (today <= valid_to) {
                        return true
                    }

                    return false
                })

                localStorage.setItem("guestcards", JSON.stringify(guestCards))
            }
        },
        onScroll() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            if (currentScrollPosition < 0) {
                return
            }
            // Stop executing this function if the difference between
            // current scroll position and last scroll position is less than some offset
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
                return
            }
            this.showNavbar = currentScrollPosition < this.lastScrollPosition
            this.lastScrollPosition = currentScrollPosition
        },

        getBuddy() {
            const location = window.location.pathname

            if (
                location.startsWith('/ski-snowboard') ||
                location.startsWith('/langlauf') ||
                location.startsWith('/bike') ||
                location.startsWith('/wandern') ||
                location.startsWith('/wander') ||
                location.startsWith('/en/ski-snowboard') ||
                location.startsWith('/en/cross-country') ||
                location.startsWith('/en/bike') ||
                location.startsWith('/en/hiking') ||
                location.startsWith('/en/hike')
            ) {
                const buddyParts = location.split('/')

                let pwaCategory = ''
                let pos = this.$i18n && this.$i18n.locale && this.$i18n.locale === "en" ? 2 : 1
                const isRootThreshold = this.$i18n && this.$i18n.locale && this.$i18n.locale === "en" ? 3 : 2

                switch (buddyParts[pos]) {
                    case 'ski-snowboard':
                        pwaCategory = 'pwa-ski'
                        break
                    case 'langlauf':
                    case 'cross-country':
                        pwaCategory = 'pwa-crosscountry'
                        break
                    case 'bike':
                        pwaCategory = 'pwa-bike'
                        break
                    case 'wandern':
                    case 'wander':
                    case 'hiking':
                    case 'hike':
                        pwaCategory = 'pwa-hiking'
                }

                return {
                    buddy: buddyParts[pos],
                    isRoot: buddyParts.length > isRootThreshold ? false : true,
                    pwaCategory: pwaCategory,
                }
            }

            return false
        },
    },
}
