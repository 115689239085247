<template>
    <swiper class="relative w-full pt-2 px-2 -mx-2 -mt-2 pb-2" :slides-per-view="1">
        <swiper-slide v-for="(tipp, index) in tipps" :key="index" class="h-auto">
            <div class="mb-4 sw_textpicslider -mx-4 sm:-mx-6 lg:mx-0">
                <div class="h-auto md:flex text-white text-center md:text-left bg-sw-blue-dark">
                    <div class="sw_gradient md:hidden">
                        <div class="mb-4 -mt-px mx-auto px-4">
                            <img :src="tipp.images[0]" class="object-cover rounded-xl" />
                        </div>
                    </div>
                    <div class="hidden md:block p-4 w-6/12">
                        <img :src="tipp.images[0]" class="object-cover rounded-xl" />
                    </div>
                    <div class="flex-1 px-6 pb-6 md:py-6">
                        <p class="text-lg">
                            <b>
                                <span style="color: #fed500" v-if="headline">{{ headline }}</span><br />
                                {{ tipp.title }}
                            </b>
                        </p>
                        <div v-html="tipp.description"></div>
                        <br />
                        <a :href="tipp.link" v-if="tipp.link" target="_blank"> → {{ $t('labels.learn-more') }} </a>
                    </div>
                </div>
            </div>
        </swiper-slide>
    </swiper>
</template>

<script>
import axios from 'axios'
import Mixins from '@/Mixins'
import 'swiper/swiper-bundle.css'
import { Swiper, SwiperSlide } from 'swiper/vue'

export default {
    name: 'Tipps',
    mixins: [Mixins],
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        appearance: Object,
    },
    data() {
        return {
            tipps: null,
            headline: null
        }
    },
    mounted() {
        axios.get(`${this.appearance.tipp_source}_${this.$i18n.locale}.json`).then((response) => {
            this.tipps = response.data.tips[this.appearance.tipp_type]

            if (this.appearance.tipp_type === "hiking") {
                this.headline = "Wandertipp"
            }

            if (this.appearance.tipp_type === "bike") {
                this.headline = "Bergtipp"
            }
           
        })
    },
}
</script>
