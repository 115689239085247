<template>
  <div class="max-w-7xl mx-auto" v-if="actual_situation">

    <div class="flex items-end">
      <h2 class="mb-2 sm:mb-0 mr-4 sm:mr-6 flex-1"><b>{{ $t("actual_situation.aktuelle_lage")}}</b></h2>
      <p class="mb-0.5">{{actual_situation.last_update}}</p>
    </div>

    <div class="w-full mt-6 grid gap-2 lg:gap-4 lg:grid-cols-3" v-if="actual_situation">
      <a :href="$t('uri.weather')" class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="w-12 h-12 flex justify-center items-center mr-6">
          <svg class="h-7 w-7 text-gray-500"><use xlink:href="#icon--weather"></use></svg>
        </div>
        <div>
          <div class="flex space-x-6">
            <p class="mb-0">Min&nbsp;&nbsp;<b>{{actual_situation.weather.temp_min}}°C</b></p>
            <p class="mb-0">Max&nbsp;&nbsp;<b>{{actual_situation.weather.temp_max}}°C</b></p>
          </div>
          <p class="mb-0">{{actual_situation.name}}</p>
        </div>
      </a>
      <a :href="$t('uri.bike')"  class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="w-12 h-12 mr-6 text-sw-blue">
          <div class="sw_percentage-circle" :class="'text-' + page.color">
            <div class="sw_percentage-circle-text">
              <svg class="h-5 w-5 text-sw-gray-dark">
                <use xlink:href="#icon--slopes"></use>
              </svg>
            </div>
            <div class="pie" :class="'p' + percentage_facilities"></div>
          </div>
        </div>
        <div>
          <div class="flex space-x-6">
            <p class="mb-0"><b>{{actual_situation.facilities.open}}</b>&nbsp;&nbsp;von&nbsp;&nbsp;<b>{{actual_situation.facilities.total}}</b></p>
          </div>
          <p class="mb-0">{{ $t("actual_situation.offene_anlagen") }}</p>
        </div>
      </a>
      <a :href="$t('uri.trail')" class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="w-12 h-12 mr-6 text-sw-blue">
          <div class="sw_percentage-circle" :class="'text-' + page.color">
            <div class="sw_percentage-circle-text">
              <svg class="h-5 w-5 text-sw-gray-dark">
                <use xlink:href="#icon--trailforks-bike"></use>
              </svg>
            </div>
            <div class="pie" :class="'p' + percentage_trails"></div>
          </div>
        </div>
        <div>
          <div class="flex space-x-6">
            <p class="mb-0"><b>{{actual_situation.trails.open}}</b>&nbsp;&nbsp;von&nbsp;&nbsp;<b>{{actual_situation.trails.total}}</b></p>
          </div>
          <p class="mb-0">{{ $t("actual_situation.offene_trails") }}</p>
        </div>
      </a>
    </div>

  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'ActualSituationBike',
  props: {
    page: Object,
  },
  data() {
    return {
      actual_situation: null,
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/actual_situation_bike.json`).then(response => {
      this.actual_situation = response.data['davos']
    })
  },
  computed:{
    percentage_facilities(){
      return Math.round(parseInt(this.actual_situation.facilities.open) / parseInt(this.actual_situation.facilities.total) * 100)
    },
    percentage_trails(){
      return Math.round(parseInt(this.actual_situation.trails.open) / parseInt(this.actual_situation.trails.total) * 100)
    },
  }
}
</script>
