<template>
    <div class="">
        <div v-if="appearance.layout == 'slider'" class="mx-auto w-full h-full">
            <!--   TODO: limit!   -->

            <swiper
                class="relative w-full pt-2 px-2 -mx-2 -mt-2 pb-12"
                :slides-per-view="1.15"
                :space-between="15"
                :breakpoints="{
                    640: {
                        slidesPerView: 2.25,
                        spaceBetween: 25,
                    },
                    1200: {
                        slidesPerView: 3.25,
                        spaceBetween: 25,
                    },
                }"
            >
                <swiper-slide v-for="(event, index) in events" :key="index" class="h-auto">
                    <section class="h-full flex flex-col rounded-lg shadow-sw-1 bg-sw-white relative">
                        <div class="flex-1 px-4 pt-4 pb-4 flex flex-col justify-between">
                            <div class="flex-1">
                                <router-link
                                    class="block"
                                    :to="{
                                        name: 'eventDetail',
                                        params: { slug: 'mountain-' + event.id + '-' + sanitizeString(event.title), eventId: event.id },
                                    }"
                                >
                                    <p
                                        class="text-xs mt-1.5 mb-4 tracking-wide font-sans-bold uppercase inline-block uppercase px-1.5 py-1 rounded-md bg-sw-blue bg-opacity-10 text-sw-blue"
                                        v-if="event.tags[0]"
                                    >
                                        {{ event.tags[0] }}
                                    </p>

                                    <h3 class="block text-xl font-sans-bold sm:text-2xl">
                                        {{ event.title }}
                                    </h3>
                                    <p class="text-md font-sans my-2">
                                        {{ event.dateList.date }}
                                    </p>
                                    <p class="text-sm">{{ event.head }}</p>
                                </router-link>
                            </div>

                            <div class="mt-1">
                                <router-link
                                    :to="{
                                        name: 'eventDetail',
                                        params: { slug: 'mountain-' + event.id + '-' + sanitizeString(event.title), eventId: event.id },
                                    }"
                                    class="font-sans-bold text-cu"
                                    >{{ $t('labels.more') }} →</router-link
                                >
                            </div>
                        </div>
                        <div class="flex-shrink-0" v-if="event.image">
                            <router-link
                                :to="{
                                    name: 'eventDetail',
                                    params: { slug: 'mountain-' + event.id + '-' + sanitizeString(event.title), eventId: event.id },
                                }"
                            >
                                <img
                                    class="h-36 w-full object-cover rounded-b-xl"
                                    :src="'https://res.cloudinary.com/dgr25kh64/image/fetch/c_fill,f_auto,h_432,q_80,w_768/' + event.image"
                                    :alt="event.title"
                                />
                            </router-link>
                        </div>
                    </section>
                </swiper-slide>
                <swiper-slide class="h-auto">
                    <section class="h-full flex flex-col rounded-lg shadow-sw-1 bg-sw-white relative">
                        <a
                            href="https://www.davos.ch/erleben/events"
                            target="_blank"
                            class="flex-1 px-4 pt-4 pb-4 flex flex-col justify-center items-center"
                        >
                            <svg class="leading-none text-gray-300 w-100 h-100">
                                <use xlink:href="#icon--arrow-circle"></use>
                            </svg>
                            <p class="block font-sans-bold text-xl sm:text-2xl mt-4">{{ $t('events.show_all') }}</p>
                        </a>
                    </section>
                </swiper-slide>
            </swiper>
        </div>

        <div v-else class="mx-auto grid gap-5 lg:grid-cols-3">
            <section
                class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative"
                v-for="(event, index) in events"
                :item="tab"
                :key="index"
            >
                <div class="flex-shrink-0" v-if="event.image">
                    <router-link
                        :to="{ name: 'eventDetail', params: { slug: 'mountain-' + event.id + '-' + sanitizeString(event.title), eventId: event.id } }"
                    >
                        <img
                            class="h-36 w-full object-cover"
                            :src="'https://res.cloudinary.com/dgr25kh64/image/fetch/c_fill,f_auto,h_432,q_80,w_768/' + event.image"
                            :alt="event.title"
                        />
                    </router-link>
                </div>
                <div class="flex-1 px-4 pt-2 pb-4 flex flex-col justify-between">
                    <div class="flex-1">
                        <p class="uppercase text-sm text-sw-gray mt-1.5">Events</p>
                        <router-link
                            class="block"
                            :to="{ name: 'eventDetail', params: { slug: 'mountain-' + event.id + '-' + sanitizeString(event.title), eventId: event.id } }"
                        >
                            <h3 class="block text-xl font-sans-bold sm:text-3xl">
                                {{ event.title }}
                            </h3>
                            <p class="text-lg font-sans-bold my-2">
                                {{ event.dateList.date }}
                            </p>
                            <p class="text-sm">{{ event.head }}</p>
                        </router-link>
                    </div>

                    <div class="mt-1">
                        <router-link
                            :to="{ name: 'eventDetail', params: { slug: 'mountain-' + event.id + '-' + sanitizeString(event.title), eventId: event.id } }"
                            class="font-sans-bold"
                            >{{ $t('labels.more') }}</router-link
                        >
                    </div>
                </div>
            </section>
            <section class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative py-20">
                <a href="https://www.davos.ch/erleben/events" target="_blank" class="flex-1 px-4 pt-4 pb-4 flex flex-col justify-center items-center">
                    <svg class="leading-none text-gray-300 w-100 h-100">
                        <use xlink:href="#icon--arrow-circle"></use>
                    </svg>
                    <p class="block font-sans-bold text-xl sm:text-2xl mt-4">{{ $t('events.show_all') }}</p>
                </a>
            </section>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import 'swiper/swiper-bundle.css'
import { Swiper, SwiperSlide } from 'swiper/vue'

export default {
    name: 'MountainEvents',
    props: {
        filters: Object,
        appearance: Object,
    },
    data() {
        return {
            events: [],
        }
    },
    methods: {
        sanitizeString: function (title) {
            var slug = ''
            var titleLower = title.toLowerCase()
            slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
            slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
            slug = slug.replace(/đ/gi, 'd')
            slug = slug.replace(/\s*$/g, '')
            slug = slug.replace(/\s+/g, '-')
            return slug
        },
    },
    mounted() {
        var filter_string = '?'

        //filter_string += this.filters['limit']!='' ? 'limit='+this.filters['limit'] + '&' : '';
        filter_string += this.filters['limit'] ? 'limit=' + this.filters['limit'] + '&' : ''
        filter_string += this.filters['sort'] ? 'sort=' + this.filters['sort'] + '&' : ''
        filter_string = filter_string.replace(/\?$/g, '').replace(/&$/g, '')

        console.log(filter_string)

        axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/mountainevent_${this.$i18n.locale}.json`).then((response) => {
            this.events = response.data
        })
    },
    components: {
        Swiper,
        SwiperSlide,
    },
}
</script>
