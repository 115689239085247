<template>
    <div>
        <div class="space-y-3 md:space-y-0 w-full mt-6 md:grid md:gap-4 md:grid-cols-2">
            <div class="bg-sw-gray-light rounded-2xl text-left" v-for="facilitie in facilities" :key="facilitie">
                <div class="flex content-center items-center justify-between px-4 py-3 border-b-2 border-gray-200">
                    <h4 class="font-sans-bold mb-0">{{ facilitie.name }}</h4>
                    <span
                        class="inline-flex uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full"
                        :class="[facilitie.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']"
                        >{{ facilitie.status }}</span
                    >
                </div>
                <div class="grid grid-cols-2 mt-2 px-4 py-3">
                    <div v-if="facilitie.firstride || facilitie.lastride">
                        <p class="mb-0" v-if="facilitie.firstride">
                            <span class="font-sans-bold">{{ $t('kidsski.first_ride') }}</span> {{ facilitie.firstride }}
                        </p>
                        <p class="mb-0" v-if="facilitie.lastride">
                            <span class="font-sans-bold">{{ $t('kidsski.last_ride') }}</span> {{ facilitie.lastride }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="space-y-3 md:space-y-0 w-full mt-6 md:grid md:gap-4 md:grid-cols-2">
            <div class="h-full bg-sw-gray-light rounded-2xl text-left" v-for="valleyLift in valleyLifts" :key="valleyLift">
                <div class="flex content-center items-center justify-between px-4 py-3 border-b-2 border-gray-200">
                    <h4 class="font-sans-bold mb-0">{{ valleyLift.name }}</h4>
                    <span
                        class="inline-flex uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full ml-5"
                        :class="[valleyLift.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']"
                        >{{ valleyLift.status }}</span
                    >
                </div>
                <div class="px-4 py-3">
                    <div class="grid sm:grid-cols-2 mt-2">
                        <div class="xs:mb-2" v-if="valleyLift.firstride || valleyLift.lastride">
                            <p class="mb-0" v-if="valleyLift.firstride">
                                <span class="font-sans-bold">{{ $t('kidsski.first_ride') }}</span> {{ valleyLift.firstride }}
                            </p>
                            <p class="mb-0" v-if="valleyLift.lastride">
                                <span class="font-sans-bold">{{ $t('kidsski.last_ride') }}</span> {{ valleyLift.lastride }}
                            </p>
                        </div>
                        <div>
                            <p class="mb-0">
                                <span class="font-sans-bold">{{ $t('kidsski.length') }}</span> {{ valleyLift.length }}
                            </p>
                            <p class="mb-0">
                                <span class="font-sans-bold">{{ $t('kidsski.capacity') }}</span> {{ valleyLift.capacity }}
                            </p>
                            <p class="mb-0">
                                <span class="font-sans-bold">{{ $t('kidsski.travel_time') }}</span> {{ valleyLift.traveltime }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-8" v-if="lastupdate">
            <p class="uppercase">{{ $t('general.lastUpdate') }}: {{ lastupdate }}</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'KidsSkiParadise',
    props: {
        appearance: Object,
    },
    data() {
        return {
            valleyLifts: [],
            facilities: [],
            lastupdate: null,
        }
    },
    mounted() {
        //console.log(this.appearance.kidsType);
        axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/overviewfacilities_${this.$i18n.locale}.json`).then((response) => {
            this.lastupdate = response.data.lastupdate

            if (this.appearance.kidsType == 'valleyLifts' || this.appearance.kidsType == '') {
                this.valleyLifts = response.data.valleyLift.klosters.filter(function (lift) {
                    if (lift !== undefined && (lift.id == 218 || lift.id == 243)) {
                        return true
                    }
                })
            }
            if (this.appearance.kidsType == 'madrisa' || this.appearance.kidsType == '') {
                this.facilities = response.data.facilities.madrisa.filter(function (facility) {
                    if (facility !== undefined && facility.icon == 'cfi-kids-paradise') {
                        return true
                    }
                })
            }
        })
    },
}
</script>
