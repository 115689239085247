<template>
  <div class="relative overflow-hidden">

    <div class="space-y-6">
        <ul role="list" class="space-y-2">
          <li v-for="(tab, index) in data.slopesOverview" :item="tab" :key="index" class="bg-sw-gray-light rounded-3xl">
            <a class="transition block rounded-tl-3xl rounded-tr-3xl cursor-pointer" @click="select(tab)" :class="{ 'bg-gray-200': tab == selected}">
              <div class="w-full flex items-center space-x-4 md:space-x-8 px-4 lg:px-8 py-2 md:py-4 grid gap-4 grid-cols-4">
                <div class="flex-col md:flex md:flex-row col-span-2">
                  <div class="w-full flex items-center">
                    <b>{{ tab.title }}</b>
                  </div>
                  <div class="w-full">
                    <p class="mb-0 text-base">
                      ({{ tab.open }} / {{ tab.total }} {{ $t("slopes.in_betrieb")}})
                    </p>
                  </div>
                </div>
                <div>
                  <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider w-16" :class="[tab.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']">{{ tab.status }}</span>
                </div>
                <div class="flex items-center justify-end">
                  <p class="mb-0 hidden font-sans-bold md:block text-base text-sw-black mr-2">{{ $t('labels.show-more') }}</p>
                  <svg class="h-4 w-4" v-if="tab != selected">
                    <use xlink:href="#icon--chevron-down"></use>
                  </svg>
                  <svg class="h-4 w-4" v-if="tab == selected">
                    <use xlink:href="#icon--chevron-up"></use>
                  </svg>
                </div>
              </div>
            </a>
            <div v-show="tab == selected" class="pb-4 bg-sw-gray-light rounded-3xl space-y-6">
              <div class="bg-sw-white overflow-hidden sm:rounded-md">
                <ul role="list" class="divide-y divide-gray-200">
                  <li v-for="(slope, index) in data.slopesDetail[index]" :key="index">
                    <a class="transition block hover:bg-gray-50" :class="{ 'bg-gray-50': slope == selected }" @click="select(slope)">
                      <div class="w-full flex items-center space-x-4 md:space-x-8 px-4 py-4 sm:px-6">
                        <div class="w-16">
                          <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider w-16" :class="[slope.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']">
                              {{ slope.status }}
                            </span>
                        </div>
                        <div class="flex-1">
                          <p class="mb-0 font-sans-bold" :class="[slope.status == 'open' ? 'text-green-500' : 'text-red-500']">
                            <span class="mr-3 font-normal">{{ slope.number }}</span>
                            {{ slope.name }}
                          </p>
                        </div>
                        <div class="sm:flex-1">
                          <div v-if="slope.color == 'difficult'">
                            <span class="inline-block h-4 w-4 mr-2 rounded-full bg-black"></span> <span class="hidden sm:inline-block">{{ $t("slopes.schwer") }}</span>
                          </div>
                          <div v-if="slope.color == 'medium'">
                            <span class="inline-block h-4 w-4 mr-2 rounded-full bg-red-500"></span> <span class="hidden sm:inline-block">{{ $t("slopes.mittel") }}</span>
                          </div>
                          <div v-if="slope.color == 'easy'">
                            <span class="inline-block h-4 w-4 mr-2 rounded-full bg-blue-500"></span> <span class="hidden sm:inline-block">{{ $t("slopes.leicht") }}</span>
                          </div>
                          <div v-if="slope.color == 'downhill'">
                            <span class="inline-block h-4 w-4 mr-2 rounded-full bg-yellow-500"></span> <span class="hidden sm:inline-block">{{ $t("slopes.abfahrtsroute") }}</span>
                          </div>
                        </div>
                        <!--
                        <div class="flex items-center">
                          <p class="mb-0 hidden md:block font-sans-bold text-base text-sw-black mr-2">{{ $t('labels.show-more') }}</p>
                          <svg class="h-4 w-4"><use xlink:href="#icon--chevron-down"></use></svg>
                        </div>-->
                      </div>
                    </a>
                    <!--
                    <div v-show="slope == selected" class="md:flex px-4 py-4 sm:px-6 bg-gray-50 space-y-4 md:space-y-0">
                      <div class="md:flex-1 mt-2 space-y-4">
                        <div v-if="slope.infoStatus != ''">
                          <p class="text-sm font-light text-gray-500 uppercase tracking-wider mb-1">Information</p>
                          <p class="text-base text-sw-black">{{ slope.infoStatus }}</p>
                        </div>
                        <div v-if="slope.valleyText != ''">
                          <p class="text-sm font-light text-gray-500 uppercase tracking-wider mb-1">Valley Text</p>
                          <p class="text-base text-sw-black">{{ slope.valleyText }}</p>
                        </div>
                      </div>

                      <div class="md:flex-1">
                        <div class="overflow-hidden sm:rounded-lg">
                          <table class="w-full divide-y divide-gray-200">
                            <tbody class="bg-sw-white divide-y divide-gray-200">
                            <tr>
                              <th class="px-4 py-2 text-left text-sm font-light text-gray-500 uppercase tracking-wider">
                                Schwierigkeit
                              </th>
                              <td class="px-4 py-2 whitespace-nowrap">
                                {{ slope.difficult }}
                              </td>
                            </tr>
                            <tr>
                              <th class="px-4 py-2 text-left text-sm font-light text-gray-500 uppercase tracking-wider">
                                Länge
                              </th>
                              <td class="px-4 py-2 whitespace-nowrap">
                                {{ slope.length }}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>-->
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
    </div>

    <div class="mt-8" v-if="data.lastupdate">
      <p class="text-sw-gray-dark text-sm tracking-wide uppercase">{{ $t("general.lastUpdate") }}: {{ data.lastupdate }}</p>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Slopes',
  data() {
    return {
      data: [],
      selected: null
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/slopes_${this.$i18n.locale}.json`).then(response => {
      this.data = response.data
    })
  },
  methods: {
    select(index) {
      this.selected = this.selected != index ? index : null
    },
  }
}
</script>
