<template>
    <div>
    <div class="oax-top-cont" style="z-index:0;"></div>
    </div>
</template>

<script>
    export default {
        name: "OaSnowSituationHiking",
        mounted() {
          var conf = {
            frontendtype:  "tour",
            modes : [
              "map",
              "list",
              "gallery",
              "listMap"
            ],
            zoom:      12,
            center:    [9.83092,46.83454],
            fitDataBounds: true,
            whereType: "none",
            initModeMobile:"map"
          };
          conf.initMode = "map";
          conf.cfg = {
            "initFilterNoGui" : {
            },
            "what": {
              "selected_init":"Wandern-main"
            },
            "maplayer": {
              "mapoverlay": {
                init : { exolabs_snow : true }
              }
            }

          };
          window.oa.api.flexviewpage( conf );
          window.onresize = function() {
            var w = window.innerWidth;
            var elm = document.getElementsByClassName("oax-top-cont");
            elm.width= w + 'px';
          };
          var w = window.innerWidth;
          var elm = document.getElementsByClassName("oax-top-cont");
          elm.width= w + 'px';

        }
    }
</script>
