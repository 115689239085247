<template>
  <div class="mx-auto grid gap-5 lg:grid-cols-3">
    <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative" v-for="bookableOffer in bookableOffers" :key="bookableOffer">
      <div class="flex-shrink-0" v-if="bookableOffer.image">
        <img class="h-48 w-full object-cover" :src="bookableOffer.image" :alt="bookableOffer.title">
      </div>
      <div class="flex-1 p-6 flex flex-col justify-between">
        <div class="flex-1">
          <div class="block mt-2">
            <h3 class="block text-2xl leading-8 font-sans-extrabold tracking-tight sm:text-3xl">
              {{ bookableOffer.title }}
            </h3>
            <p class="mt-3 text-base" v-html="$filters.str_limit(bookableOffer.description, 160)"></p>
          </div>
        </div>
        <div class="sm:text-right">
          <b class="block mb-2 text-xl" v-if="bookableOffer.price">{{ currencyCHF(bookableOffer.price, true) }}</b>
          <Link :style="0" :text="$t('labels.learn-more')" :link="bookableOffer.link" linkClass="w-full" v-if="bookableOffer.link" target="_blank"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Link from "@/components/contents/partials/Link";
import Mixins from "@/Mixins";

export default {
  name: 'BookableOffers',
  props: {
    filters: Object,
  },
  components: {
    Link
  },
  mixins: [Mixins],
  data() {
    return {
      bookableOffers: []
    }
  },
  mounted() {
    let filter_string = '?cat=';
    let ids = [];
    if (this.filters['object_ids']){
      ids = this.filters['object_ids'].split(',');
    }
    filter_string += (this.filters['categories'] && this.filters['categories'].length != 0 ? this.filters['categories'] : 'pwa');

    axios.get('https://ferienshop.davos.ch/api/export/experiences'+filter_string).then(response => {
      let data = []
      data = data.concat(response.data[this.$i18n.locale].filter(bookableOffer => bookableOffer.season === 'winter'))
      data = data.concat(response.data[this.$i18n.locale].filter(bookableOffer => bookableOffer.season === 'year'))
      data = data.concat(response.data[this.$i18n.locale].filter(bookableOffer => bookableOffer.season !== 'winter' && bookableOffer.season !== "year"))
      if(this.filters['object_ids']){
        data = data.filter(function(offer){return ids.includes(offer.experience_id+'')})
      }
      this.bookableOffers = this.bookableOffers.concat(data);
    })
    axios.get('https://ferienshop.davos.ch/api/export/activities'+filter_string).then(response => {
      let data = []
      data = data.concat(response.data[this.$i18n.locale].filter(bookableOffer => bookableOffer.season === 'winter'))
      data = data.concat(response.data[this.$i18n.locale].filter(bookableOffer => bookableOffer.season === 'year'))
      data = data.concat(response.data[this.$i18n.locale].filter(bookableOffer => bookableOffer.season !== 'winter' && bookableOffer.season !== "year"))
      if(this.filters['object_ids']){
        data = data.filter(function(offer){return ids.includes(offer.experience_id+'')})
      }
      this.bookableOffers = this.bookableOffers.concat(data);
    })
  }
}
</script>
