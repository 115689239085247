<template>
  <div>
    <div class="flex justify-end mb-3">
      <SwitchGroup>
        <div class="flex items-center">
          <SwitchLabel class="mr-4">{{ mapView ? $t("general.listenansicht") : $t("general.kartenansicht") }}</SwitchLabel>
          <Switch
            v-model="mapView"
            :class='mapView ? "bg-blue-600" : "bg-gray-200"'
            class="relative inline-flex items-center h-6 transition-colors rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span
              :class='mapView ? "translate-x-6" : "translate-x-1"'
              class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full"
            />
          </Switch>
        </div>
      </SwitchGroup>
    </div>

    <div v-if="!mapView" class="mx-auto grid gap-5 lg:grid-cols-3">

      <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative" v-for="covidCenter in covidCenters" :key="covidCenter">

        <div class="flex-1 p-6 flex flex-col justify-between">
          <div class="flex-1">
            <div class="block mt-2">
             <!-- <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-500 text-green-100" v-if="aed.filter.city">
              {{ aed.filter.city }}
            </span> -->
              <h3 class="font-sans-bold text-md mb-0 mt-2 h4">
                {{ covidCenter.name }}
              </h3>
              <p class="text-sm">
                {{ covidCenter.address }}
              </p>
            </div>
          </div>
          <div class="mt-4">
            <div class="space-y-2 text-sm">
              <a :href="'tel:' + covidCenter.tel"
                 class="flex items-center text-sm transition ease-in-out duration-150"
                 v-if="covidCenter.tel">
                <svg class="flex-shrink-0 h-4 w-5">
                  <use xlink:href="#icon--phone"></use>
                </svg>
                <span class="ml-3">{{ covidCenter.tel }}</span>
              </a>
              <a :href="'http://' + covidCenter.url"
                 class="flex items-center text-sm transition ease-in-out duration-150"
                 target="_blank"
                 v-if="covidCenter.url">
                <svg class="flex-shrink-0 h-4 w-5">
                  <use xlink:href="#icon--website"></use>
                </svg>
                <span class="ml-3">{{covidCenter.url}}</span>
              </a>
            </div>
              <Link :style="200" :text="covidCenter.dist.text" target="_blank" :link="covidCenter.dirLink" :class="'w-full mt-4'" :linkClass="'w-full'" v-if="covidCenter.dirLink" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="mapView">
      <GMaps :markers="markers"  />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Mixins from "@/Mixins";
import Link from "@/components/contents/partials/Link";
import GMaps from "@/components/partials/GMaps";
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export default {
  name: 'CovidTestcenter',
  mixins: [Mixins],
  components: {
    Link,
    Switch,
    SwitchGroup,
    SwitchLabel,
    GMaps
  },
  data() {
    return {
      covidCenters: [],
      sort_by_dist: false,
      markers: [],
      mapView: false
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/covidcenter_${this.$i18n.locale}.json`).then(response => {
      this.covidCenters = response.data;

      this.covidCenters.forEach(covidCenter => {
        covidCenter.dist = Mixins.methods.distanceInKm(covidCenter.geoInfo.latitude, covidCenter.geoInfo.longitude, this.$t("general.plan_route"))
        if(covidCenter.dist.dist !== null){
          this.sort_by_dist = true;
        }
      });
      this.markers = Mixins.methods.setMarker(this.covidCenters, 'aed');
      if(this.sort_by_dist){
        this.covidCenters.sort((a, b) => a.dist.dist > b.dist.dist ? 1 : -1);
      }
    })
  },
}
</script>
