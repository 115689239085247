<template>
  <div v-if="showLocationBanner" class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-60">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="p-3 rounded-lg bg-sw-blue shadow-lg sm:p-3">
        <div class="md:flex items-center justify-between flex-wrap w-full">
          <p class="md:flex-1 text-white my-0">
            <span v-html="$t(`location.message_${browser}`)"></span>
          </p>
          <div class="flex pt-4">
            <button @click="close()" type="button" class="flex-1 inline-flex items-center justify-center px-3 py-2 border border-white border-transparent font-extrabold rounded-full transition duration-200 ease-in-out bg-sw-blue text-white">
              {{ $t('location.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import Mixins from "@/Mixins";

export default {
  name: 'TheLocationBanner',
  data () {
    return {
      showLocationBanner: false,
      browser: "unknown"
    }
  },
  methods: {
    close: function () {
      localStorage.setItem("location_banner", true)
      this.showLocationBanner = false
    },
  },
  mounted() {
    if (localStorage.getItem("location_banner") === null) {
      this.showLocationBanner = Mixins.methods.getUserPosition() != null ? false:true;
    }

    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 )
    {
      this.browser = "opera"
    }
    else if(navigator.userAgent.indexOf("Edg") != -1 )
    {
      this.browser = "edge"
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 )
    {
      this.browser = "chrome"
    }
    else if(navigator.userAgent.indexOf("Safari") != -1)
    {
      this.browser = "safari"
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 )
    {
      this.browser = "firefox"
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
    {
      this.browser = "ie"
    }
    else
    {
      this.browser = "unknown"
    }
  },
  setup() {
    const { t } = useI18n();
    return { t };
  }
}
</script>
