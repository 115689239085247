<template>

  <div>
    <div v-if="appearance.layout == 'slider'" class="mx-auto w-full h-full">
      <div class="sw_textpicslider -mx-4 sm:-mx-6 lg:mx-0">
        <div class="mx-auto">
          <swiper
              class="relative pb-16 bg-sw-blue-dark"
              :slides-per-view="1"
              :space-between="0"
              :pagination="{ clickable: true }"
              :breakpoints="breakpoints"
          >
            <swiper-slide v-for="(experience, index) in experiences" :key="index" class="h-auto flex flex-col text-white">
              <div class="px-8 sw_gradient mb-4 -mt-px">
                <img class="h-48 w-full object-cover rounded-xl" :src="experience.image" :alt="experience.title">
              </div>
              <div class="px-8 flex-1 text-center">
                <h3><strong>{{ experience.title }}</strong></h3>
                <h4 class="text-sw-orange"><strong>{{ experience.teaser }}</strong></h4>
                <p v-html="experience.description.substring(0,100)+'..'"></p>
              </div>
              <div class="px-8">
                <Link :style="0" :text="currencyCHF(experience.price, true) + ' buchen' " target="_blank" :link="experience.link" linkClass="w-full" v-if="experience.link != ''"/>
              </div>
            </swiper-slide>

          </swiper>
        </div>
      </div>
    </div>

    <div v-else class="mx-auto grid gap-5 lg:grid-cols-3">
      <section v-for="experience in experiences" :key="experience" class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative">
        <div class="flex-shrink-0">
          <img class="h-48 w-full object-cover" :src="experience.image" :alt="experience.title">
        </div>
        <div class="flex-1 p-6 flex flex-col justify-between">
          <div class="flex-1">
            <div class="block mt-2">
              <h3 class="block text-2xl leading-8 font-sans-extrabold tracking-tight sm:text-2xl">
                {{ experience.title }}
              </h3>
              <p class="mt-3 text-base" v-html="experience.description.substring(0,100)+'..'"></p>
            </div>
          </div>
          <div class="w-full sm:text-right">
            <b class="block mb-2 text-xl" v-if="experience.price">{{ currencyCHF(experience.price, true) }}</b>

            <Link :style="0" :text="$t('labels.learn-more')" :link="experience.link" v-if="experience.link" target="_blank"/>

          </div>
        </div>
      </section>

    </div>
  </div>

</template>

<script>
import axios from "axios";
import 'swiper/swiper-bundle.css';
import {Swiper, SwiperSlide} from 'swiper/vue';

import Link from "@/components/contents/partials/Link";
import SwiperCore, {Pagination} from "swiper";
import Mixins from "@/Mixins";

SwiperCore.use([Pagination]);

export default {
  name: 'Experiences',
  mixins: [Mixins],
  props: {
    filters: Object,
    appearance: Object,
  },
  components: {
    Swiper,
    SwiperSlide,
    Link
  },
  data() {
    return {
      experiences: [],
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 0,
        }
      }
    }
  },
  mounted() {
    var filter_string = '?cat=';
    filter_string += (this.filters['categories'] && this.filters['categories'].length != 0 ? this.filters['categories'] : 'pwa');

    axios.get('https://ferienshop.davos.ch/api/export/experiences'+filter_string).then(response => {
      this.experiences = response.data[this.$i18n.locale].slice(0,8);
      
      if (this.filters['categories'] === "pwa-wandern") {
        this.experiences = this.experiences.filter(exp => exp.experience_id === 136 || exp.experience_id === 144)
      }
    })
  }
}
</script>
