<template>
  <div>
    <div class="oax-top-cont" style="z-index:0;"></div>
  </div>
</template>

<script>
export default {
  name: 'SkitourFreeride',
  mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://www.outdooractive.com/alpportal/oa_head.js?proj=api-davos-klosters&amp;key=IXESBVMK-EMWGKQBE-4OSSFBRI&amp;lang=' + this.$i18n.locale)
    document.head.appendChild(externalScript)

    let oa_script = document.createElement('script')
    oa_script.setAttribute('src', '/js/oa_script.js')
    document.body.appendChild(oa_script)
  },
}
</script>
