<template>

  <div class="space-y-2 md:space-y-6">
    <Headers :content="content" />
    <Link :style="content.linkStyle" :text="content.linkText" :link="content.headerLink.url" :target="content.headerLink.target" v-if="content.headerLink != ''"/>

    <div class="flex flex-col overflow-hidden">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden">

            <table class="table-fixed w-full" :class="[content.tableHeaderPosition == 1 ? 'table--header-top' : '', content.tableHeaderPosition == 2 ? 'table--header-left' : '']">

              <thead v-if="content.tableHeaderPosition == 1">
                <tr>
                  <th v-for="field in content.bodytext[0]" :key="field" scope="col" class="px-6 py-3 text-left font-sans-bold" v-html="field"></th>
                </tr>
              </thead>

              <tbody :class="{'divide-y divide-gray-200': content.tableClass == 'bordered' }">
                <tr v-for="(row, i) in content.bodytext" :key="row"
                    :class="{'bg-sw-gray-light': content.tableClass != 'bordered' && i % 2 }">
                  <template v-for="(field, index) in row">
                    <template v-if="i == 0 && content.tableHeaderPosition == 1"></template>
                    <template v-else-if="i == row.length && content.tableTfoot == 1"></template>
                    <template v-else-if="index == 0 && content.tableHeaderPosition == 2">
                      <th class="px-6 py-3 text-left font-sans-bold" :key="field" v-html="field"></th>
                    </template>
                    <template v-else>
                      <td class="px-4 md:px-6 sm:py-4" :class="[index==0?'pt-3':'', index==(row.length-1)?'pb-3':'']" :key="field" :data-mobile="field != '' ? content.bodytext[0][index].replace(/(<([^>]+)>)/gi, '') : ''" v-html="field"></td>
                    </template>
                  </template>
                </tr>
              </tbody>

              <template v-for="(row, i) in content.bodytext">
                <tfoot v-if="i == row.length && content.tableTfoot == 1" :key="row">
                  <tr>
                    <td class="px-4 md:px-6 sm:py-4" v-for="field in row" :key="field" v-html="field"></td>
                  </tr>
                </tfoot>
              </template>

            </table>

          </div>
          <p v-if="content.tableCaption" class="mt-5">{{ content.tableCaption }}</p>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import Headers from "@/components/contents/partials/Headers";
import Link from "@/components/contents/partials/Link";

export default {
  name: 'Table',
  components: {
    Headers,
    Link,
  },
  props: {
    content: Object
  },
}
</script>