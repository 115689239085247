<template>
    <div>
    <div class="oax-top-cont" style="z-index:0;"></div>
    </div>
</template>

<script>
    export default {
        name: "OaSnowSituation",
        mounted() {
          var conf = {
            frontendtype: "tour",
            modes: ["list", "gallery", "map", "listMap"],
            zoom: 12,
            center: [9.83092, 46.83454],
            fitDataBounds: true,
            whereType: "none"
          };
          conf.initMode = "listMap";
          conf.cfg = {
            "initFilterNoGui": {},
            "what": {"selected_init": "Velo-main"},
            "maplayer": {"mapoverlay": {init: {exolabs_snow: true}}}
          };
          window.oa.api.flexviewpage(conf);
          window.onresize = function () {
            var w = window.innerWidth;
            var elm = document.getElementsByClassName("oax-top-cont");
            elm.width = w + 'px';
          };
          var w = window.innerWidth;
          var elm = document.getElementsByClassName("oax-top-cont");
          elm.width = w + 'px';
                    
        }
    }
</script>