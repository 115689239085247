<template>
  <div class="weather">
    <TabGroup v-if="data.length > 0">
      <TabList class="border border-gray-200 rounded-full relative pr-10">
        <div id="mobile-slide-arrow" class="w-10 h-full absolute right-0 top-2/4 transform -translate-y-2/4 z-20 flex lg:hidden items-center bg-white rounded-l-none rounded-full justify-center">
          <svg class="h-4 w-5">
            <use xlink:href="#icon--chevron-right"></use>
          </svg>
        </div>
        <div class="flex overflow-auto relative">
          <Tab v-slot="{ selected }" v-for="tab in data" :key="tab" as="template">
            <button :class="(selected ? 'bg-black text-white ' : '') + 'flex-shrink-0 px-4 md:px-6 py-2 tracking-wider border border-transparent font-sans-bold rounded-full transition duration-200 ease-in-out focus:outline-none'">
              {{ tab.tabTitle }}
            </button>
          </Tab>
        </div>
      </TabList>
      <TabPanels>
        <TabPanel v-for="(tab, index) in data" :key="index">
          <div class="border rounded-lg mt-3">
            <div v-for="(day, index) in tab.forecast" :key="index" class="">
              <Disclosure :defaultOpen="index==0">
                <DisclosureButton @click="showDay(index)" class="py-2 px-3 bg-gray-50 w-full border-top border-gray-200 flex">
                  <span class="font-sans-bold flex-1">{{ day.day.fullDesc }}</span>
                  <svg class="flex-shrink-0 h-4 w-5">
                    <use :xlink:href="selectedDay === index ? '#icon--chevron-up' : '#icon--chevron-down'"></use>
                  </svg>
                </DisclosureButton>
                <DisclosurePanel static v-show="selectedDay === index">
                  <div class="xl:flex px-3 py-2">
                    <div class="flex mb-5">
                      <WeatherIcons :icon="day.icon" add_class="text-xxs" />
                      <div class="ml-4">
                        <p class="text-xl font-semibold">{{ getAverageTemperature(day) }} °C</p>
                        <p>
                          Min <span :class="getColorBasedOnTemperature(day.temp_low) + ' font-sans-bold mr-4'">{{ day.temp_low }} °C</span>
                          Max <span :class="getColorBasedOnTemperature(day.temp_high) + ' font-sans-bold'">{{ day.temp_high }} °C</span>
                        </p>
                      </div>
                    </div>
                    <div class="xl:flex-1 xl:ml-8">
                      <div>
                        <p>{{ day.description }}</p>
                      </div>
                      <div class="border-t my-4 border-gray-200"></div>
                      <div class="md:flex items-center">
                        <div class="md:flex md:flex-1 mb-3 md:mb-0 items-center">
                          <div class="font-sans-bold">{{ $t('weather.precipitation_probability') }}</div>
                          <div class="flex items-center">
                            <span class="cfi-weather-stack md:ml-3" style="font-size: 5px;"><i class="cfi-stack-icon icon-master cfi-weather-brokencloud"></i><i class="cfi-stack-icon icon-slave cfi-weather-rainy"></i></span>
                            <span class="text-sw-blue-davos font-sans-bold mx-4">{{ day.precipitation }} {{ day.precipitationProbability}}</span>
                          </div>
                        </div>
                        <div class="md:flex md:ml-5 md:flex-1 items-center">
                          <div class="font-sans-bold md:mr-3">{{ $t('weather.wind') }}</div>
                          <div class="flex items-center">
                            <svg class="w-8 h-6"><use xlink:href="#icon-weather--wind"></use></svg>
                            <span class="text-sw-blue-davos mx-4 font-sans-bold">{{ day.wind.speed }}</span>
                            <svg :class="'w-8 h-6 wind-direction--'+day.wind.direction.toLowerCase()"><use xlink:href="#icon--wind-direction"></use></svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul class="weather-day-list mt-8 rounded-xl overflow-hidden md:flex mb-10">
                    <li v-for="(dayChild, index) in day.intraday" v-bind:key="index" class="border-t border-gray-200 flex flex-auto md:flex-col items-center py-2 md:px-5 md:py-4 justify-center">
                      <span class="font-sans-bold">{{ dayChild.hour }}</span>
                      <WeatherIcons :icon="dayChild.icon" add_class="text-xxxxs mx-4 md:my-3" />
                      <span>{{ dayChild.temperature }}</span>
                    </li>
                  </ul>
                </DisclosurePanel>
              </Disclosure>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import axios from "axios";
import WeatherIcons from "@/components/partials/WeatherIcons";
import { TabGroup, TabList, Tab, TabPanels, TabPanel, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

export default {
  name: 'Weather',
  data() {
    return {
      data: [],
      selectedDay: 0
    }
  },
  components: {
    WeatherIcons,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/weather_${this.$i18n.locale}.json`).then(response => {
      this.data = response.data
    })
  },
  methods: {
    showDay(index) {
      if (this.selectedDay === index) {
        this.selectedDay = undefined;
      } else {
        this.selectedDay = index
      }
    },
    getAverageTemperature(day) {
      return (parseInt(day.temp_low) + parseInt(day.temp_high)) / 2
    },
    getColorBasedOnTemperature(temp) {
      if (temp <= -3) {
        return "text-sw-blue-davos"
      }

      return "text-sw-red"
    },
    getColorBasedOnPrecipitation(percentage) {
      percentage = parseInt(percentage.replace("%", ""))

      if (percentage >= 80) {
        return "text-sw-blue-davos"
      }

      return "text-sw-red"
    },
    getColorBasedOnWindSpeed(speed) {
      speed = parseInt(speed.replace(" km/h", ""))

      if (speed >= 10) {
        return "text-sw-blue-davos"
      }

      return "text-sw-red"
    }
  }
}
</script>
