<template>
  <div class="flex justify-between py-3 px-3 border-t">
    <div>
      <b>{{ title }}</b><br v-if="subTitle"/>
      {{ subTitle }}
    </div>
    <div class="flex">
      <router-link v-if="infoLink" class="text-sw-blue px-5 flex-shrink-0 flex items-center cursor-pointer" :to="{ name: 'restaurantDetail', params: { slug: infoLink }}">
        <svg class="h-7 w-7 m-auto">
          <use :xlink:href="'#icon--info'"></use>
        </svg>
      </router-link>
      <a :href="link" class="text-green-500 pl-2 flex-shrink-0 flex items-center cursor-pointer w-16 leading-4 text-center" target="_blank" v-if="dist">
        <span>
          <svg class="h-7 w-7 m-auto mb-2">
            <use :xlink:href="'#icon--directions'"></use>
          </svg>
          {{ dist.text }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AccordionItem",
    props: ["title", "subTitle", "dist", "link", "position", "open", "infoLink"],
  }
</script>
