<template>
  <div class="">
    <div class="around-me-filters flex-shrink-0 overflow-x-scroll hide-scroll-bar">
      <div class="flex sm:flex-nowrap xl:flex-wrap mb-4 whitespace-nowrap">
        <div class="inline-block pr-2" @click="setSelectedPoiIndex(index)" v-for="(poi, index) in pois" :key="index">
          <div>
            <a href="javascript:;" :class="{ 'bg-black text-white': selectedPoiIndex === index, 'bg-white text-black': selectedPoiIndex !== index }" class="inline-flex items-center justify-center px-4 py-2 border border-black font-sans-bold rounded-full transition duration-200 ease-in-out w-full mt-2">
              {{ poi.label }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <div class="relative">
        <GMaps :fullscreen="true" @markerClick="onMarkerClick" :disableControls="true" :markers="markers" :center="center" :zoom="zoom" />
        <button class="my_position absolute bottom-52 md:bottom-10 right-3" @click="onCenterMapClick" :class="currentPosition!=null?'':'hidden'">
          <svg class="h-5 w-5">
            <use xlink:href="#icon--location"></use>
          </svg>
        </button>
      </div>

      <div class="absolute bottom-10 left-8 right-0">
        <swiper
            v-if="selectedPoiIndex || selectedPoiIndex === 0"
            @swiper="sliderInit"
            class="relative h-full max-w-screen-sm poi-slider"
            :slides-per-view="'auto'"
            :space-between="32"
            @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(poi, index) in pois[selectedPoiIndex].data" :key="index" class="flex items-end" >
            <div class="p-4 rounded-lg shadow-sw-1 bg-sw-white w-full">
              <div class="flex items-start justify-start">
                <svg v-if="false === true" class="h-10 w-10 mr-2" aria-hidden="true">
                  <use xlink:href="#icon--parking"></use>
                </svg>
                <div>
                  <span v-if="poi.filter.city" class="mb-2 inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-500 text-green-100">
                    {{ poi.filter.city }}
                  </span>
                  <h3 class="font-sans-extrabold m-0">{{ poi.name }}</h3>
                  <p v-if="poi.tel">{{ poi.tel }}</p>
                  <span v-if="poi.address">{{ poi.address }}</span>
                </div>
              </div>
              <Link :style="200" :text="poi.dist.text" target="_blank" :link="poi.dirLink" :class="'w-full mt-4'" :linkClass="'w-full'"/>
            </div>
          </swiper-slide>

        </swiper>
      </div>
    </div>
    <TheLocationBanner />
  </div>
</template>

<script>
import axios from 'axios';
import Mixins from "@/Mixins";
import GMaps from "@/components/partials/GMaps";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import Link from "@/components/contents/partials/Link";
import TheLocationBanner from '@/components/TheLocationBanner.vue'

export default {
  name: 'AroundMe',
  mixins: [Mixins],
  components: {
    GMaps,
    Swiper,
    SwiperSlide,
    Link,
    TheLocationBanner
  },
  props: {
    appearance: Object
  },
  data() {
    return {
      sort_by_dist: false,
      pointsOfInterest: [],
      swiperInstance: null,
      currentPosition: Mixins.methods.getUserPosition(),
      center: {lat: 46.802746, lng: 9.835970},
      zoom: 12,
      hasDetail: false,
      selectedPoiIndex: null,
      markers: [],
      pois: [
     /*   {
          type: "covidcenter",
          label: "Covid-Teststellen"
        },

      */
        {
          type: "infopoint",
          label: "Infostellen"
        },
        {
          type: "parking",
          label: "Parkplätze"
        },
        {
          type: "chargingstation",
          label: "E-Tanksäulen"
        },
        {
          type: "aed",
          label: "Defibrillatoren"
        },
        {
          type: "mountainaddress",
          label: "Berg-Restaurants"
        },
        {
          type: "gastroaddress",
          label: "Restaurants"
        },
        {
          type: "toiletten",
          label: "Toiletten"
        },
        {
          type: "bus",
          label: "Bus"
        },
        {
          type: "train",
          label: "Zug"
        },/*
        {
          type: "vermietungen",
          label: "Vermietungen"
        },*/
      ],
    }
  },
  methods: {
    onMarkerClick: function(id) {
      this.swiperInstance.slideTo(id)
    },
    setSelectedPoiIndex: function(index) {
      this.selectedPoiIndex = index
      this.center = {lat: 46.802746, lng: 9.835970}
      this.zoom = 12
      console.log("reset")
    },
    sliderInit: function(swiperInstance) {
      this.swiperInstance = swiperInstance
    },
    onSlideChange: function() {
      if (this.pois[this.selectedPoiIndex].data) {
        this.center =  {
          lat: parseFloat(this.pois[this.selectedPoiIndex].data[this.swiperInstance.realIndex].geoInfo.latitude),
          lng: parseFloat(this.pois[this.selectedPoiIndex].data[this.swiperInstance.realIndex].geoInfo.longitude)
        };
        this.zoom = 17
      }
    },
    /* tryFetch: async function(poi, selectPoi = true) {
      let starting_marker_id = 0;
      if(selectPoi){
        this.selectedPoi = this.pois.find(p => p.type === poi);
      }

      if (!this.pois.find(p => p.type === poi).data) {
        let apiUrl = process.env.VUE_APP_API_URL+`typo3temp/feed_dk/${poi}_${this.$i18n.locale}.json`

        if(this.appearance.type && this.appearance.type == 'wef' && (poi=="parking" || poi=="chargingstation")){
          apiUrl = process.env.VUE_APP_API_URL+`typo3temp/feed_dk/${poi}-wef_${this.$i18n.locale}.json`
        }

        if (poi === "vermietungen") {
          apiUrl = 'https://ferienshop.davos.ch/api/export/suppliers/rental/winter'
        }

        axios.get(apiUrl).then(response => {
          let {data} = response;
          data = poi === "mountainaddress" || poi === "gastroaddress"  ? data.list : data;
          let latitude = 0;
          let longitude = 0;

          data.forEach(poi => {
            latitude = parseFloat(poi.geoInfo ? poi.geoInfo.latitude : poi.coordinates.latitude);
            longitude = parseFloat(poi.geoInfo ? poi.geoInfo.longitude : poi.coordinates.longitude);
            poi.dist = Mixins.methods.distanceInKm(latitude, longitude, this.$t("general.plan_route"))

          });

          data.sort((a, b) => a.dist.dist > b.dist.dist ? 1 : -1);
          this.pois.find(p => p.type === poi).data = data
          //console.log(this.pois);
          //this.pois.sort((a, b) => a.dist.dist > b.dist.dist ? 1 : -1);
          starting_marker_id = this.markers.length
          this.markers = this.markers.concat(Mixins.methods.setMarker(this.pois.find(p => p.type === poi).data,poi,starting_marker_id));
        });
      }
    } */
  },
  watch: {
    async selectedPoiIndex(newIndex) {
      let starting_marker_id = 0;


      if (!this.pois[newIndex].isLoaded) {
        // load data
        const poi = this.pois[newIndex].type

        let apiUrl = process.env.VUE_APP_API_URL+`typo3temp/feed_dk/${poi}_${this.$i18n.locale}.json`

        if(this.appearance.type && this.appearance.type == 'wef' && (poi=="parking" || poi=="chargingstation")){
          apiUrl = process.env.VUE_APP_API_URL+`typo3temp/feed_dk/${poi}-wef_${this.$i18n.locale}.json`
        }

        if (poi === "vermietungen") {
          apiUrl = 'https://ferienshop.davos.ch/api/export/suppliers/rental/winter'
        }


        const response = await axios.get(apiUrl)

        this.pois[newIndex].isLoaded = true

        let {data} = response;
        data = poi === "mountainaddress" || poi === "gastroaddress"  ? data.list : data;
        let latitude = 0;
        let longitude = 0;

        data.forEach(poi => {
          latitude = parseFloat(poi.geoInfo ? poi.geoInfo.latitude : poi.coordinates.latitude);
          longitude = parseFloat(poi.geoInfo ? poi.geoInfo.longitude : poi.coordinates.longitude);
          poi.dist = Mixins.methods.distanceInKm(latitude, longitude, this.$t("general.plan_route"))
        });

        data.sort((a, b) => a.dist.dist > b.dist.dist ? 1 : -1);
        this.pois.find(p => p.type === poi).data = data
        this.pois.find(p => p.type === poi).markers = Mixins.methods.setMarker(this.pois.find(p => p.type === poi).data,poi,starting_marker_id)
        //starting_marker_id = this.markers.length
      }

      this.markers = this.pois[newIndex].markers
    }
  },
  mounted() {
    this.setSelectedPoiIndex(0)
  }
}
</script>
