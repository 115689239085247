<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <Disclosure as="nav" :class="`w-full shadow top-0 z-50 navbar_top sticky ${buddy ? 'bg-opacity-50 bg-black' : 'bg-sw-white'}`" v-slot="{ open }">
        <div ref="nav" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16">
            <div class="flex justify-between h-16 items-center">
                <!-- <a v-if="!isHome()" :href="fromRoute" title="Home" class="mr-4">
            <svg class="h-4 w-4 sm:hidden"><use xlink:href="#icon--menu-arrow"></use></svg>
          </a> -->

                <div
                    v-if="buddy && main_navigation != null"
                    id="menu-image-overlay"
                    class="absolute left-0 top-0 bottom-0 w-full overflow-hidden sw_blurred-sm"
                    :class="'bg-' + main_navigation.color"
                >
                    <img class="mix-blend-multiply m-auto" :src="main_navigation.media[0].publicUrl" />
                    <div class="absolute inset-0 mix-blend-color" aria-hidden="true" :class="'bg-' + main_navigation.color"></div>
                </div>

                <div v-if="!buddy" class="flex items-center">
                    <a v-if="!isHome()" :href="getPrevUrl()" class="mr-4 color-black">
                        <svg class="h-4 w-4"><use xlink:href="#icon--menu-arrow"></use></svg>
                    </a>
                    <a :href="getHomeUrl()" title="Davos Klosters"
                        ><img
                            class="h-8 w-auto mr-4"
                            src="https://www.davos.ch//typo3conf/ext/davos_website/Resources/Public/Assets/Images/Logos/davos-klosters.png"
                            alt="Davos Klosters Logo"
                    /></a>
                    <a :href="getHomeUrl()" title="Davos Klosters"
                        ><img
                            class="h-9 w-auto"
                            src="https://www.davos.ch//typo3conf/ext/davos_website/Resources/Public/Assets/Images/Logos/sports-unlimited.png"
                            alt="Sports Unlimited Logo"
                    /></a>
                </div>

                <a v-if="buddy && !buddy.isRoot" :href="getPrevUrl()">
                    <svg class="h-6 w-6 text-white">
                        <use xlink:href="#icon--chevron-left"></use>
                    </svg>
                </a>

                <a v-if="buddy && buddy.isRoot" :href="getHomeUrl()"><img class="h-12 mr-4" :src="`/images/winter/${$i18n.locale}/avatar and logo.svg`" /></a>

                <a :href="`/${buddy.buddy}`" v-if="buddy && !buddy.isRoot && buddy.buddy === 'ski-snowboard'" class="flex text-white items-center">
                    <svg class="w-12 h-8 mr-2 md:mr-6">
                        <use :xlink:href="'#icon--snowsports'"></use>
                    </svg>
                    <h4 class="mb-0">
                        <strong>Ski & Snowboard</strong>
                        Buddy
                    </h4>
                </a>
                <a :href="`/${buddy.buddy}`" v-if="buddy && !buddy.isRoot && buddy.buddy === 'bike'" class="flex text-white items-center">
                    <svg class="w-12 h-8 mr-6">
                        <use :xlink:href="'#icon--bike'"></use>
                    </svg>
                    <h4 class="mb-0">
                        <strong>Bike</strong>
                        Buddy
                    </h4>
                </a>
                <a :href="`/${buddy.buddy}`" v-if="buddy && !buddy.isRoot && buddy.buddy === 'langlauf'" class="flex text-white items-center">
                    <svg class="w-12 h-8 mr-6">
                        <use :xlink:href="'#icon--cross-country-skiing'"></use>
                    </svg>
                    <h4 class="mb-0">
                        <strong>Langlauf</strong>
                        Buddy
                    </h4>
                </a>
                <a :href="`/${buddy.buddy}`" v-if="buddy && !buddy.isRoot && buddy.buddy === 'wander'" class="flex text-white items-center">
                    <svg class="w-12 h-8 mr-6">
                        <use :xlink:href="'#icon--hiking'"></use>
                    </svg>
                    <h4 class="mb-0">
                        <strong>Wander</strong>
                        Buddy
                    </h4>
                </a>

                <div class="-mr-2 flex items-center">
                    <DisclosureButton
                        @click="addClass"
                        :class="`inline-flex items-right justify-center p-2 rounded-md focus:outline-none ${buddy ? 'text-white' : ''}`"
                    >
                        <span class="sr-only">Open main menu</span>
                        <div v-if="!open" class="w-6">
                            <svg class="inline-block h-4 w-4"><use xlink:href="#icon--menu"></use></svg>
                        </div>
                        <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
                    </DisclosureButton>
                </div>
            </div>
        </div>

        <DisclosurePanel :style="{ height: navHeight + 'px' }" :class="open ? 'overflow-y-scroll bg-white' : ''" class="">
            <div class="pb-20 space-y-2">
                <div class="max-w-7xl mx-auto px-6 lg:px-16 pb-3" v-if="main_navigation != null">
                    <a
                        :href="main_navigation.link"
                        :target="main_navigation.target"
                        class="flex items-center block py-5"
                        v-if="main_navigation.nav_hide != 1 && main_navigation.header_text != ''"
                    >
                        <div class="w-12 h-8 mr-4 flex items-center justify-center">
                            <svg class="h-6 w-10" :class="'text-' + main_navigation.color">
                                <use :xlink:href="'#icon--' + main_navigation.icon"></use>
                            </svg>
                        </div>
                        <span :class="`text-${main_navigation.color} py-2`">
                            <span class="font-sans-bold">{{ main_navigation.title }}</span> Buddy
                        </span>
                    </a>
                    <hr class="text-sw-gray-light mb-2" />
                    <ul role="list" class="sm:grid sm:grid-cols-2 lg:grid-cols-4">
                        <li v-for="page in main_navigation['children']" :key="page">
                            <div
                                class="flex items-center block"
                                v-if="page.nav_hide_pwa != 1 && page.nav_hide != 1"
                                @click="page.children && page.children.length > 0 ? openCloseMenu(page) : directLink(page.link)"
                            >
                                <div class="w-5 h-5 mr-4 flex items-center justify-center sm:hidden">
                                    <svg class="h-3 w-5 pl-2" v-if="page.children && page.children.length > 0">
                                        <use :xlink:href="menuOpen === page.uid ? '#icon--minus' : '#icon--plus'"></use>
                                    </svg>
                                </div>
                                <span class="pt-3 pb-2 font-sans-bold" v-html="page.title"></span>
                            </div>
                            <ul role="list" v-if="menuOpen == page.uid || menuOpen == 'all'">
                                <li v-for="subpage in page['children']" :key="subpage">
                                    <a
                                        :href="subpage.link"
                                        :target="subpage.target"
                                        :class="
                                            (this.activeItem === subpage.uid ? `bg-gray-200 ` : '') +
                                            'rounded-lg flex items-center block pl-12 sm:pl-4'
                                        "
                                        v-if="subpage.nav_hide != 1"
                                    >
                                        <span class="py-2" v-html="subpage.title"></span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <hr class="text-sw-gray-light mt-2" />
                </div>

                <div class="fixed right-0 bottom-20 z-10">
                    <Link :style="100" text="SOS" :linkClass="'rounded-r-none'" :link="$t('links.sos')" />
                </div>

                <div class="max-w-7xl mx-auto px-6 lg:px-16">
                    <a :href="getHomeUrl()" class="pt-6 pb-4 flex items-center">
                        <img class="w-12 h-12 mr-4" :src="`/images/winter/${$i18n.locale}/buddy_navigation.svg`" />
                        <span><b>Davos Klosters</b> Buddy</span>
                    </a>
                </div>

                <div class="max-w-7xl mx-auto px-6 lg:px-16">
                    <hr class="text-sw-gray-light" />
                </div>

                <div class="max-w-7xl mx-auto px-6 lg:px-16">
                    <ul role="list" class="grid sm:grid-cols-2 md:grid-cols-4">
                        <li v-for="page in navigation['children']" :key="page">
                            <a
                                :href="page.link"
                                :target="page.target"
                                class="flex items-center block pb-3"
                                v-if="page.nav_hide_pwa != 1 && page.header_text != ''"
                                :class="[main_navigation != null && main_navigation.uid == page.uid ? 'sw-grayscale' : '']"
                            >
                                <div class="w-12 h-8 mr-4 flex items-center justify-center">
                                    <svg class="h-6 w-10" :class="'text-' + page.color">
                                        <use :xlink:href="'#icon--' + page.icon"></use>
                                    </svg>
                                </div>
                                <span class="py-2 font-sans-bold" v-html="page.title"></span>&nbsp;Buddy
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="max-w-7xl mx-auto sm:px-6 lg:px-16 pb-3">
                    <hr class="border-gray-300" />
                </div>
                <div class="max-w-7xl mx-auto px-6 lg:px-16" v-if="main_navigation == null">
                    <ul role="list" class="grid sm:grid-cols-2">
                        <li v-for="page in navigation['children']" :key="page">
                            <a
                                :href="page.link"
                                :target="page.target"
                                class="flex items-center block hover:bg-gray-50 rounded-md"
                                v-if="page.nav_hide_pwa != 1 && page.nav_children_show != 1 && page.header_text == ''"
                            >
                                <span class="py-2" v-html="page.title"></span>
                            </a>
                            <div class="" v-else-if="page.nav_hide_pwa != 1 && page.nav_children_show == 1">
                                <div class="mt-2">
                                    <div class="pb-4">
                                        <hr class="text-sw-gray-light" />
                                    </div>
                                    <p class="text-gray-300 py-2 uppercase mb-0">
                                        <b>{{ page.title }}</b>
                                    </p>
                                    <ul role="list" v-if="page['children']">
                                        <li class="flow-root" v-for="subpage in page['children']" :key="subpage">
                                            <a :href="subpage.link" :target="subpage.target" class="flex items-center block">
                                                <span class="py-2 flex items-center">
                                                    {{ subpage.title }}
                                                    <svg v-if="subpage.target == '_blank'" class="ml-1 w-4 h-4 text-gray-400">
                                                        <use xlink:href="#icon&#45;&#45;external-link"></use>
                                                    </svg>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="max-w-7xl mx-auto sm:px-6 lg:px-16 pt-3" v-if="main_navigation == null">
                    <hr class="text-sw-gray-light" />
                </div>
                <div class="max-w-7xl mx-auto px-6 lg:px-16 pt-3">
                    <p class="uppercase text-gray-300 font-sans-bold tracking-wide">Services</p>
                    <ul role="list" class="grid sm:grid-cols-2">
                        <li v-for="page in servicenavigation" :key="page">
                            <a
                                :href="page.link"
                                :target="page.target"
                                class="flex items-center block hover:bg-gray-50 rounded-md"
                                v-if="page.nav_hide_pwa != 1 && page.nav_children_show != 1"
                            >
                                <span class="py-2" v-html="page.title"></span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <div class="px-6 mt-6 text-center">
                        <div class="relative">
                            <template v-for="language in languages">
                                <button
                                    @click="showDropdown = !showDropdown"
                                    type="button"
                                    :class="[showDropdown ? 'rounded-t-md' : 'rounded-md']"
                                    class="w-40 bg-white relative border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-0"
                                    aria-haspopup="listbox"
                                    aria-expanded="true"
                                    aria-labelledby="listbox-label"
                                    :key="language.languageId"
                                    v-if="language.active == 1"
                                >
                                    <span class="flex items-center truncate">
                                        <svg class="h-5 w-5 mr-2">
                                            <use xlink:href="#icon--globe"></use>
                                        </svg>
                                        {{ language.title }}
                                    </span>
                                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <svg class="h-5 w-5" v-if="showDropdown">
                                            <use xlink:href="#icon--chevron-up"></use>
                                        </svg>
                                        <svg class="h-5 w-5" v-else>
                                            <use xlink:href="#icon--chevron-down"></use>
                                        </svg>
                                    </span>
                                </button>
                            </template>
                            <ul
                                v-if="showDropdown"
                                style="margin-top: -1px"
                                class="w-40 mx-auto bg-white max-h-60 rounded-b-md py-1 border border-gray-300 overflow-auto focus:outline-none divide-y divide-gray-200"
                                tabindex="-1"
                                role="listbox"
                                aria-labelledby="listbox-label"
                                aria-activedescendant="listbox-option-3"
                            >
                                <template v-for="language in languages">
                                    <li
                                        class="cursor-pointer select-none relative pl-3 pr-10 py-2 text-sm text-gray-500 hover:text-black"
                                        id="listbox-option-0"
                                        role="option"
                                        :key="language.languageId"
                                        v-if="language.active == 0"
                                    >
                                        <a
                                            :href="language.link"
                                            class="flex items-center truncate"
                                            v-on:click="changeLanguage(language.twoLetterIsoCode)"
                                        >
                                            <svg class="h-5 w-5 mr-2">
                                                <use xlink:href="#icon--globe"></use>
                                            </svg>
                                            {{ language.title }}
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>

                    <div class="px-6 mt-10 text-center">
                        <p class="mb-0">
                            {{ $t('labels.to-website') }}
                            <a href="htps://www.davos.ch" target="_blank" class="text-sw-blue-davos font-sans-bold">davos.ch</a>
                        </p>
                    </div>
                </div>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>

<script>
import Link from '@/components/contents/partials/Link'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import Mixins from '@/Mixins'

export default {
    mixins: [Mixins],
    components: {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        XIcon,
        Link,
    },
    props: {
        isEventDetail: Boolean,
        isTrailDetail: Boolean,
        isTourDetail: Boolean,
        navdata: Array,
        languages: Array,
        servicenavigation: Array
    },
    data() {
        return {
            currentLanguage: '',
            fromRoute: '/',
            main: [],
            navigation: [],
            main_navigation: null,
            isAddClass: false,
            navHeight: '100vh',
            menuOpen: window.innerWidth >= 640 ? 'all' : 0,
            activeItem: 0,
            showDropdown: false,
            buddy: null,
        }
    },
    methods: {
        changeLanguage: function (lang) {
            this.currentLanguage = lang
        },
        directLink: function (link) {
            window.location.replace(link)
        },
        addClass: function () {
            this.isAddClass = !this.isAddClass
            this.isAddClass ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden')
        },
        setNavHeight() {
            var navHeight = this.$refs.nav.clientHeight
            this.navHeight = window.innerHeight - navHeight
        },
        openCloseMenu(page) {
            this.menuOpen = window.innerWidth >= 640 ? 'all' : this.menuOpen == page.uid ? 0 : page.uid
        },
        getPrevUrl() {
            // const path = window.location.pathname

            if (this.isEventDetail) {
                return '/events-veranstaltungen'
            }
            else if (this.isTrailDetail) {
                return this.$i18n.locale === 'en' ? '/en/bike/reports-maps/trail-network/' : '/bike/berichte-karten/trail-netz/';
            }
            else if (this.isTourDetail) {
                return '/bike/berichte-karten/touren'
            }

            if (document.referrer) {
                const url = new URL(document.referrer)
                return url.pathname
            } else {
                return "/"
            }

            /*

            const parts = path.split('/')

            if (parts.length === 2) {
                return '/'
            }



            return `/${parts[1]}` */
        },
        isHome() {
            if (window.location.pathname === '/' && this.$route.name !== 'eventDetail' && this.$route.name !== 'trailDetail' && this.$route.name !== 'tourDetail') {
                return true
            }

            return false
        },
        highlightActiveMenuItem() {
            const path = window.location.pathname

            this.main_navigation['children'].forEach((parentItem) => {
                if (!parentItem.children) return

                parentItem.children.forEach((childItem) => {
                    if (childItem.link == path) {
                        this.menuOpen = window.innerWidth >= 640 ? 'all' : parentItem.uid
                        this.activeItem = childItem.uid
                    }
                })
            })
        },
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    mounted() {
        this.buddy = this.getBuddy()

        var first = window.location.pathname.split('/')[1],
            second = window.location.pathname.split('/')[2],
            mountain,
            buddys = ['ski-snowboard', 'langlauf', 'wander', 'bike'],
            lang = ['en', 'it']
        this.setNavHeight()
        window.addEventListener('scroll', this.onScroll)

        let url_parts = window.location.pathname.split('/')
        this.fromRoute = '/' + (url_parts.length > 2 ? (buddys.includes(url_parts[1]) ? url_parts[1] : '') : '')

        addEventListener('resize', () => {
            this.menuOpen = window.innerWidth >= 640 ? 'all' : 0
        })

        if (buddys.includes(first)) {
            mountain = '/' + first
        } else if (buddys.includes(second)) {
            mountain = '/' + first + '/' + second
        } else if (lang.includes(first)) {
            mountain = '/' + first
        } else {
            mountain = '/'
        }

        this.main = {
            baseUrl: mountain,
        }

        const pos = this.navdata.length - 1
        this.navigation = this.navdata[0]

        this.languages.forEach(language => {
            if (language['active'] == 1) {
                this.$i18n.locale = language['twoLetterIsoCode']
            }
        })

        if (pos != 0) {
            this.main_navigation = this.navdata[pos]
            this.highlightActiveMenuItem()
        }
    },
}
</script>
