<template>

  <div>
    <div class="flex justify-end mb-3">
      <SwitchGroup>
        <div class="flex items-center">
          <SwitchLabel class="mr-4">{{ mapView ? "Listenansicht" : "Karte anzeigen" }}</SwitchLabel>
          <Switch
            v-model="mapView"
            :class='mapView ? "bg-blue-600" : "bg-gray-200"'
            class="relative inline-flex items-center h-6 transition-colors rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span
              :class='mapView ? "translate-x-6" : "translate-x-1"'
              class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full"
            />
          </Switch>
        </div>
      </SwitchGroup>
    </div>

    <div v-if="!mapView" class="mx-auto grid gap-5 lg:grid-cols-3">

      <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative" v-for="(restaurant) in restaurants"
           :key="restaurant">
        <div class="flex-shrink-0">
          <router-link class="block" :to="{ name: 'restaurantDetail', params: { slug: 'crosscountry_' + restaurant.id + '_' + sanitizeString(restaurant.name), restaurantId: restaurant.id }}">
          <img class="h-48 w-full object-cover" :src="'https://res.cloudinary.com/dgr25kh64/image/fetch/c_fill,f_auto,h_432,q_80,w_768/'+'https://services.davos.ch/' + restaurant.image"
               :alt="restaurant.name">
          </router-link>
        </div>
        <div class="flex-1 p-6 pt-3 flex flex-col justify-between">
          <div class="flex-1">
          <router-link class="block" :to="{ name: 'restaurantDetail', params: { slug: 'crosscountry_' + restaurant.id + '_' + sanitizeString(restaurant.name), restaurantId: restaurant.id }}">
           <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider"
                 :class="[restaurant.opening == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']">
              {{ restaurant.opening }}
            </span>
              <!--<span aria-hidden="true" class="absolute inset-0"></span>-->
              <h3 class="font-sans-bold text-md mb-0 mt-2 h4">{{ restaurant.name }}</h3>
              <p class="text-sm">
                {{ restaurant.address }}
              </p>
            </router-link>
          </div>
          <div class="mt-4">
            <div class="space-y-2 text-sm">
              <a :href="'tel:' + restaurant.tel"
                 class="flex items-center text-sm transition ease-in-out duration-150"
                 v-if="restaurant.tel">
                <svg class="flex-shrink-0 h-4 w-5">
                  <use xlink:href="#icon--phone"></use>
                </svg>
                <span class="ml-3">{{ restaurant.tel }}</span>
              </a>
              <a :href="'mailto:' + restaurant.email"
                 class="flex items-center text-sm transition ease-in-out duration-150"
                 v-if="restaurant.email">
                <svg class="flex-shrink-0 h-3 w-5">
                  <use xlink:href="#icon--email"></use>
                </svg>
                <span class="ml-3">{{ restaurant.email }}</span>
              </a>
              <a :href="'http://' + restaurant.url" target="_blank"
                 class="flex items-center text-sm transition ease-in-out duration-150"
                 v-if="restaurant.url">
                <svg class="flex-shrink-0 h-4 w-5">
                  <use xlink:href="#icon--external"></use>
                </svg>
                <span class="ml-3">{{ restaurant.url }}</span>
              </a>
              <Link :style="200" :text="restaurant.dist.text" target="_blank" :link="restaurant.dirLink" :class="'w-full mt-4'" :linkClass="'w-full mt-4'"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="mapView">
      <GMaps :markers="markers"  />
    </div>
  </div>

</template>

<script>
import axios from "axios";
import GMaps from "@/components/partials/GMaps";
import Link from "@/components/contents/partials/Link";
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import Mixins from "@/Mixins";

export default {
  name: 'CrosscountryGastro',
  components: {
    Link,
    Switch,
    SwitchGroup,
    SwitchLabel,
    GMaps
  },
  data() {
    return {
      restaurants: [],
      sort_by_dist: false,
      markers: [],
      mapView: false,
    }
  },
  methods: {
    sanitizeString: function (title) {
      var slug = "";
      var titleLower = title.toLowerCase();
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      slug = slug.replace(/đ/gi, 'd');
      slug = slug.replace(/\s*$/g, '');
      slug = slug.replace(/\s+/g, '-');
      return slug;
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/gastrocrosscountry_${this.$i18n.locale}.json`).then(response => {
      this.restaurants = response.data.list

      this.restaurants.forEach(restaurant => {
        restaurant.dist = Mixins.methods.distanceInKm(restaurant.geoInfo.latitude, restaurant.geoInfo.longitude, this.$t("general.plan_route"))
        if(restaurant.dist.dist !== null){
          this.sort_by_dist = true;
        }
      });
      this.markers = Mixins.methods.setMarker(this.restaurants, 'gastrocrosscountry');
      if(this.sort_by_dist){
        this.restaurants.sort((a, b) => a.dist.dist > b.dist.dist ? 1 : -1);
      }
    })
  },
}
</script>
