<template>
  <div class="difficulty-rating flex space-x-1">
    <div class="difficulty-rating-dot rounded-full w-3 h-3 border border-black" :class="{'bg-black': ((value >= rating) && value != null)}" v-for="(rating, index) in ratings" :key="index"></div>
  </div>
</template>

<script>
export default {
  name: 'Difficulty',
  props: {
    value: {
      type: Number,
      default() {
        return null
      }
    },
  },
  data: function() {
    return {
      ratings: [1, 2, 3, 4, 5, 6]
    };
  }
}
</script>
