<template>

  <div>
    <div class="flex justify-end mb-3">
      <SwitchGroup>
        <div class="flex items-center">
          <SwitchLabel class="mr-4">{{ mapView ? $t("general.listenansicht") : $t("general.kartenansicht") }}</SwitchLabel>
          <Switch
              v-model="mapView"
              :class='mapView ? "bg-blue-600" : "bg-gray-200"'
              class="relative inline-flex items-center h-6 transition-colors rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span
                :class='mapView ? "translate-x-6" : "translate-x-1"'
                class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full"
            />
          </Switch>
        </div>
      </SwitchGroup>
    </div>

    <div v-if="!mapView" class="mx-auto">
      <AccordionItem v-for="(parking, index) in parkings" :key="index" :title="parking.short_name" :sub-title="parking.address" :dist="parking.dist" :link="parking.dirLink" />
    </div>

    <div v-if="mapView">
      <GMaps :markers="markers"  />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import AccordionItem from "@/components/AccordionItem";
import GMaps from "@/components/partials/GMaps";
import Mixins from "@/Mixins";

export default {
  name: 'Parking',
  mixins: [Mixins],
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
    AccordionItem,
    GMaps
  },
  data() {
    return {
      mapView: null,
      sort_by_dist: false,
      parkings: [],
      markers: [],
    }
  },
  mounted() {
    let apiUrl = process.env.VUE_APP_API_URL+`typo3temp/feed_dk/parking_${this.$i18n.locale}.json`
    if(this.appearance.type && this.appearance.type == 'wef'){
      apiUrl = process.env.VUE_APP_API_URL+`typo3temp/feed_dk/parking-wef_${this.$i18n.locale}.json`
    }

    axios.get(apiUrl).then(response => {
      this.parkings = response.data;
      this.parkings.forEach(parking => {
        parking.dist = Mixins.methods.distanceInKm(parking.geoInfo.latitude, parking.geoInfo.longitude, this.$t("general.plan_route"))
        if(parking.dist.dist !== null){
          this.sort_by_dist = true;
        }
      });
      this.markers = Mixins.methods.setMarker(this.parkings, 'parking');
      if(this.sort_by_dist) {
        this.parkings.sort((a, b) => a.dist.dist > b.dist.dist ? 1 : -1);
      }
    })
  },
}
</script>
