<template>
  <div class="relative">
    <div class="space-y-6">
      <div>
        <div class="grid grid-cols-2 lg:grid-cols-4 gap-4">
          <div class="form-group">
            <label for="filter_type" class="font-bold block mb-1">{{ $t("winterHiking.art") }}</label>
            <select id="filter_type" class="form-control" v-model="filters.type">
              <option value="">{{ $t("winterHiking.alle") }}</option>
              <option value="cfi-walk-trail">{{ $t("winterHiking.winterwanderweg") }}</option>
              <option value="cfi-snowrackets">{{ $t("winterHiking.schneeschuhlaufen") }} </option>
            </select>
          </div>
          <div class="form-group">
            <label for="filter_where" class="font-bold block mb-1">{{ $t("winterHiking.wo") }}</label>
            <select id="filter_where" class="form-control" v-model="filters.where">
              <option value="">{{ $t("winterHiking.ueberall") }}</option>
              <option value="1">{{ $t("winterHiking.tal") }}</option>
              <option value="0">{{ $t("winterHiking.berg") }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="filter_status" class="font-bold block mb-1">{{ $t("winterHiking.status") }}</label>
            <select id="filter_status" class="form-control" v-model="filters.status">
              <option value="">{{ $t("winterHiking.alle") }}</option>
              <option value="open">{{ $t("winterHiking.offen") }}</option>
              <option value="closed">{{ $t("winterHiking.geschlossen") }}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="font-bold block mb-1">{{ $t("winterHiking.laenge") }}</label>
            <div class="mt-4 mb-4 px-6">
              <Slider v-model="filters.range" :step="1" :max="20" :format="rangeFormat" tooltipPosition="bottom" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <tabs>
          <tab :title="`Davos (${getFilteredHikings().hikingMixedDavos ? getFilteredHikings().hikingMixedDavos.length : 0})`">
            <p v-if="getFilteredHikings().hikingMixedDavos && getFilteredHikings().hikingMixedDavos.length === 0">
              {{ $t("winterHiking.noResults") }}
            </p>
            <div class="bg-sw-white overflow-hidden grid md:grid-cols-2">
              <div v-for="(f, index) in getFilteredHikings().hikingMixedDavos" :key="index" class="pt-4 px-4 lg:px-8 border-t flex items-center justify-between py-5">
                <p>
                  <svg class="h-8 w-8 m-auto mb-2" :class="[f.status == 'open' ? 'text-green-500' : 'text-red-500']" v-if="f.icon == 'cfi-snowrackets'">
                    <use xlink:href="#icon--snowrackets"></use>
                  </svg>
                  <svg class="h-8 w-8 m-auto mb-2" :class="[f.status == 'open' ? 'text-green-500' : 'text-red-500']" v-if="f.icon == 'cfi-walk-trail'">
                    <use xlink:href="#icon--winterhiking"></use>
                  </svg>
                  <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider ml-2 w-16" :class="[f.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']">{{ f.status }}</span>
                </p>
                <p class="mb-0 ml-3 flex-1">
                  <b :class="[f.status == 'open' ? 'text-green-500' : 'text-red-500']">{{ f.name }}</b><br />
                  <b>{{ $t("winterHiking.laenge") }}:</b> {{ parseInt(f.length) }} m
                </p>
              </div>
            </div>
          </tab>
          <tab :title="`Klosters (${getFilteredHikings().hikingMixedKlosters ? getFilteredHikings().hikingMixedKlosters.length : 0})`">
            <p v-if="getFilteredHikings().hikingMixedKlosters && getFilteredHikings().hikingMixedKlosters.length === 0">
              {{ $t("winterHiking.noResults") }}
            </p>
            <div class="bg-sw-white overflow-hidden grid md:grid-cols-2">
              <div v-for="(f, index) in getFilteredHikings().hikingMixedKlosters" :key="index" class="pt-4 px-4 lg:px-8 border-t flex items-center justify-between py-5">
                <p>
                  <svg class="h-8 w-8 m-auto mb-2" :class="[f.status == 'open' ? 'text-green-500' : 'text-red-500']" v-if="f.icon == 'cfi-snowrackets'">
                    <use xlink:href="#icon--snowrackets"></use>
                  </svg>
                  <svg class="h-8 w-8 m-auto mb-2" :class="[f.status == 'open' ? 'text-green-500' : 'text-red-500']" v-if="f.icon == 'cfi-walk-trail'">
                    <use xlink:href="#icon--winterhiking"></use>
                  </svg>
                  <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider ml-2 w-16" :class="[f.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']">{{ f.status }}</span>
                </p>
                <p class="mb-0 ml-3 flex-1">
                  <b :class="[f.status == 'open' ? 'text-green-500' : 'text-red-500']">{{ f.name }}</b><br />
                  <b>Länge:</b> {{ parseInt(f.length) }} m
                </p>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </div>

    <div class="mt-8" v-if="hikings.lastupdate">
      <p class="uppercase">{{ $t("general.lastUpdate") }}: {{hikings.lastupdate}}</p>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import Tabs from "@/components/Tabs";
import Tab from "@/components/Tab";
import Slider from '@vueform/slider'

export default {
  name: 'WinterHiking',
  components: {
      Tabs,
      Tab,
      Slider
  },
  data() {
    return {
      hikings: [],
      filters: {
        type: "",
        where: "",
        status: "",
        range: [0, 20]
      },
      rangeFormat: function(value) {
        return `${value} km`
      }
    }
  },
  methods: {
    getFilteredHikings: function() {
      const filteredHikings = {...this.hikings}

      if (filteredHikings.hikingMixedDavos && filteredHikings.hikingMixedKlosters) {
        if (this.filters.type) {
          filteredHikings.hikingMixedDavos = filteredHikings.hikingMixedDavos.filter(hike => hike.icon === this.filters.type)
          filteredHikings.hikingMixedKlosters = filteredHikings.hikingMixedKlosters.filter(hike => hike.icon === this.filters.type)
        }

        if (this.filters.where) {
          if (this.filters.where === "0") {
            filteredHikings.hikingMixedDavos = filteredHikings.hikingMixedDavos.filter(hike => hike.valley === 0)
            filteredHikings.hikingMixedKlosters = filteredHikings.hikingMixedKlosters.filter(hike => hike.valley === 0)
          } else {
            filteredHikings.hikingMixedDavos = filteredHikings.hikingMixedDavos.filter(hike => hike.valley === "")
            filteredHikings.hikingMixedKlosters = filteredHikings.hikingMixedKlosters.filter(hike => hike.valley === "")
          }
        }

        if (this.filters.status) {
          filteredHikings.hikingMixedDavos = filteredHikings.hikingMixedDavos.filter(hike => hike.status === this.filters.status)
          filteredHikings.hikingMixedKlosters = filteredHikings.hikingMixedKlosters.filter(hike => hike.status === this.filters.status)
        }

        if (this.filters.range) {
          const min = this.filters.range[0] * 1000
          const max = this.filters.range[1] * 1000

          filteredHikings.hikingMixedDavos = filteredHikings.hikingMixedDavos.filter(hike => hike.length >= min && hike.length <= max)
          filteredHikings.hikingMixedKlosters = filteredHikings.hikingMixedKlosters.filter(hike => hike.length >= min && hike.length <= max)
        }
      }

      return filteredHikings
    },
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/hiking_${this.$i18n.locale}.json`).then(response => {
      this.hikings = response.data
    })
  },
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
