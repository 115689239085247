<template>
  <div v-if="showCookieBanner" class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-60">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="p-3 rounded-lg bg-sw-blue shadow-lg sm:p-3">
        <div class="md:flex items-center justify-between flex-wrap w-full">
          <p class="md:flex-1 text-white my-0">
            <span v-html="$t('cookie.message')"></span> <a :href='privacy_link' class='font-sans-bold'>{{ $t('cookie.message_link') }}</a>.
          </p>
          <div class="flex pt-4">
            <button @click="reject()" type="button" class="flex-1 inline-flex items-center justify-center px-3 py-2 border border-white border-transparent font-extrabold rounded-full transition duration-200 ease-in-out bg-sw-blue text-white">
              {{ $t('cookie.decline') }}
            </button>
            <button @click="accept()" type="button" class="flex-1 ml-4 inline-flex items-center justify-center px-3 py-2 border border-transparent font-extrabold rounded-full transition duration-200 ease-in-out bg-white text-sw-blue">
             {{ $t('cookie.accept') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: 'TheCookieBanner',
  data () {
    return {
      showCookieBanner: false,
      privacy_link: null
    }
  },
  methods: {
    accept: function() {
      localStorage.setItem("gtm_accepted", true)
      window.dispatchEvent( new StorageEvent('storage', {
        key: "gtm_accepted",
        newValue: true
      }) )

      if (!this.$gtm.enabled()) {
        this.$gtm.enable(true)
      }

      this.showCookieBanner = false
    },
    reject: function() {
      localStorage.setItem("gtm_accepted", false)
      window.dispatchEvent( new StorageEvent('storage', {
        key: "gtm_accepted",
        newValue: false
      }) )

      if (this.$gtm.enabled()) {
        this.$gtm.enabled(false)
      }

      this.showCookieBanner = false
    }
  },
  mounted() {
    if (localStorage.getItem("gtm_accepted") === null) {
      this.showCookieBanner = true
    }
    this.privacy_link = '/footer-menue/datenschutzverordnung'
    if(this.$i18n.locale === 'en'){
      this.privacy_link = '/en/privacy-policy'
    }
  },
  setup() {
    const { t } = useI18n();
    return { t };
  }
}
</script>
