<template>
  <a
      :href="content.headerLink.url"
      :class="[appearance.frameClass != 'default' ? 'py-8 lg:py-10' : '', appearance.frameClass == 'none' && appearance.backgroundColor == '' ? 'px-4 sm:px-6 md:px-0' : '']"
      :target="content.headerLink.target && content.headerLink.target.includes('_blank') ? '_blank' : '_self'"
  >
    <div class="relative px-4 rounded-xl shadow-sw-1">
      <div class="absolute inset-0 overflow-hidden rounded-xl opacity-50 ">
        <Media :content="content.gallery" imgClass="w-full h-full object-center object-cover" imgRounded=""/>
      </div>
      <div aria-hidden="true"
           :class="[appearance.backgroundColor != '' ? 'bg-sw-' + appearance.backgroundColor : '']"
           class="absolute inset-0 z-10 pointer-events-none rounded-xl mix-blend-color"></div>
      <div class="relative mx-auto max-w-7xl z-10" :class="{'py-12':content.gallery.rows[1], 'py-4':content.gallery.rows.length==0}">
        <div class="space-y-1 md:space-y-6 lg:space-y-10">
          <div>
            <Media :content="content.icon" imgClass="object-center object-cover" imgRounded=""/>
          </div>
          <div class="space-y-2">
            <Bodytext :content="content" v-if="content.bodytext"/>
            <Link :style="content.linkStyle" :text="content.linkText" :link="content.headerLink.url" :target="content.headerLink.target" v-if="content.headerLink != '' && content.linkText != ''"/>
          </div>
        </div>
      </div>
    </div>

  </a>

</template>

<script>

import Bodytext from "@/components/contents/partials/Bodytext";
import Link from "@/components/contents/partials/Link";
import Media from "@/components/contents/partials/Media";

export default {
  name: 'Textpicbox',
  components: {
    Bodytext,
    Link,
    Media
  },
  props: {
    content: Object,
    appearance: Object
  },
}
</script>