<template>
    <div class="mx-auto grid gap-5 lg:grid-cols-3">
        <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative" v-for="rental in rentals" :key="rental">
            <div class="flex-shrink-0">
                <img class="h-48 w-full object-cover" :src="rental.image" :alt="rental.name" />
            </div>
            <div class="flex-1 p-6 flex flex-col justify-between">
                <div class="flex-1">
                    <div class="block mt-2">
                        <!--<span aria-hidden="true" class="absolute inset-0"></span>-->
                        <h3 class="font-sans-bold text-md mb-0 mt-2 h4">
                            {{ rental.name }}
                        </h3>
                        <p class="text-sm">
                            {{ rental.address }}
                        </p>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="space-y-2 text-sm">
                        <a :href="'tel:' + rental.phone" class="flex items-center text-sm transition ease-in-out duration-150" v-if="rental.phone">
                            <svg class="flex-shrink-0 h-4 w-5">
                                <use xlink:href="#icon--phone"></use>
                            </svg>
                            <span class="ml-3">{{ rental.phone }}</span>
                        </a>
                        <div>
                            <div class="flex items-center text-sm transition ease-in-out duration-150" v-if="rental.hours[$i18n.locale]">
                                <svg class="flex-shrink-0 h-5 w-5">
                                    <use xlink:href="#icon--clock"></use>
                                </svg>
                                <span class="ml-3">{{ $t('rentals.opening_hours') }}</span>
                            </div>
                            <div class="ml-8 pt-2">
                                <p class="text-sm">
                                    <span class="block" v-for="hour in rental.hours[$i18n.locale].split('\n')" :key="hour">
                                        {{ hour }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <Link :style="0" :text="$t('rentals.rent_now')" :link="rental.link" v-if="rental.link" target="_blank" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Link from '@/components/contents/partials/Link'

export default {
    name: 'Rentals',
    props: {
        filters: Object,
    },
    components: {
        Link,
    },
    data() {
        return {
            rentals: [],
        }
    },
    mounted() {
        let filter_string = ''
        let season = 'winter'
        if (this.filters['category'] && this.filters['category'].length != 0) {
          filter_string = '?cat=' + this.filters['category']
          if (this.filters['category'] == 'mtb') {
            season = 'summer'
          }
        }
        axios.get('https://ferienshop.davos.ch/api/export/suppliers/rental/'+ season + filter_string).then((response) => {
            this.rentals = response.data
        })
    },
}
</script>
