<template>
    <div class="relative overflow-hidden">
        <div class="bg-sw-white overflow-hidden">
            <tabs>
                <tab :title="index" v-for="(facility, index) in facilities.valleyLift" :key="index">
                    <div class="pb-4 px-4 lg:px-8 rounded-3xl space-y-6">
                        <div v-for="(f, index) in facility" :key="index" class="grid gap-4 grid-cols-8 mt-2">
                            <div class="col-span-2 md:col-span-1">
                                <span
                                    class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider w-16"
                                    :class="[f.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']"
                                >
                                    {{ f.status }}
                                </span>
                            </div>
                            <div class="col-span-6 md:col-span-7 space-y-4">
                                <div>
                                    <h3>
                                        <b>{{ f.name }}</b>
                                    </h3>
                                    <p v-if="f.comments != ''">{{ $t('facilities.notes') }}: {{ f.comments }}</p>
                                </div>
                                <div>
                                    <div class="md:table md:table-fixed md:w-full divide-y divide-sw-black md:divide-y-0">
                                        <div class="flex justify-between py-2 md:table-cell md:align-top md:py-0">
                                            <div class="md:border-b-2 md:pb-2 md:mb-2 md:border-sw-black">
                                                <b>{{ $t('facilities.first_ride') }}</b>
                                            </div>
                                            <div>
                                                <b>{{ f.firstride }} - {{ f.lastride }}</b>
                                            </div>
                                        </div>
                                        <div class="flex justify-between py-2 md:table-cell md:align-top md:py-0">
                                            <div class="md:border-b-2 md:pb-2 md:mb-2 md:border-sw-black">
                                                <b>{{ $t('facilities.difference') }}</b>
                                            </div>
                                            <div>{{ f.difference }}</div>
                                        </div>
                                        <div class="flex justify-between py-2 md:table-cell md:align-top md:py-0">
                                            <div class="md:border-b-2 md:pb-2 md:mb-2 md:border-sw-black">
                                                <b>{{ $t('facilities.length') }}</b>
                                            </div>
                                            <div>{{ f.length }}</div>
                                        </div>
                                        <div class="flex justify-between py-2 md:table-cell md:align-top md:py-0">
                                            <div class="md:border-b-2 md:pb-2 md:mb-2 md:border-sw-black">
                                                <b>{{ $t('facilities.capacity') }}</b>
                                            </div>
                                            <div>{{ f.capacity }}</div>
                                        </div>
                                        <div class="flex justify-between py-2 md:table-cell md:align-top md:py-0">
                                            <div class="md:border-b-2 md:pb-2 md:mb-2 md:border-sw-black">
                                                <b>{{ $t('facilities.fahrzeit') }}</b>
                                            </div>
                                            <div>{{ f.traveltime }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabs>
        </div>

        <div class="mt-8" v-if="facilities.lastupdate">
            <p class="uppercase">{{ $t('general.lastUpdate') }}: {{ facilities.lastupdate }}</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Tabs from '@/components/Tabs'
import Tab from '@/components/Tab'

export default {
    name: 'FacilitiesValley',
    components: {
        Tabs,
        Tab,
    },
    data() {
        return {
            facilities: [],
            selected: null,
        }
    },
    mounted() {
        axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/overviewfacilities_${this.$i18n.locale}.json`).then((response) => {
            this.facilities = response.data
        })
    },
    methods: {
        select(index) {
            this.selected = this.selected != index ? index : null
        },
    },
}
</script>
