<template>
  <div>
    <div v-if="eau_la_la" class="grid md:grid-cols-2 gap-6 mb-7">
      <div class="max-w-7xl mx-auto rounded-lg shadow-sw-1 overflow-hidden">
        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex items-center">
          <img src="https://www.davos.ch/typo3conf/ext/davos_website/Resources/Public/Assets/Svg/Reports/pool.svg" alt="" class="mx-2" />
          <h3 class="font-sans-bold m-0 flex-1">
            {{ $t("icepool.erlebnissbad")}}
          </h3>
          <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-500 text-green-100">{{ eau_la_la.pool > 0 ? 'open' : 'closed' }}</span>
        </div>
        <ul role="list" class="divide-y divide-gray-200">
          <li>
            <div class="px-4 py-4 sm:px-6 font-sans flex items-center">
              <span class="block w-10 h-10 bg-gray-200 rounded-full font-sans-bold text-center leading-10">{{eau_la_la.pool}}</span>
              <span class="flex-1 pl-4">{{ $t("icepool.erlebnissbad_text")}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="max-w-7xl mx-auto rounded-lg shadow-sw-1 overflow-hidden">
        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex items-center">
          <img src="https://www.davos.ch/typo3conf/ext/davos_website/Resources/Public/Assets/Svg/Reports/spa.svg" alt="" class="mx-2" />
          <h3 class="font-sans-bold m-0 flex-1">
            {{ $t("icepool.wellness")}}
          </h3>
          <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-500 text-green-100">{{ eau_la_la.pool > 0 ? 'open' : 'closed' }}</span>
        </div>
        <ul role="list" class="divide-y divide-gray-200">
          <li>
            <div class="px-4 py-4 sm:px-6 font-sans flex items-center">
              <span class="block w-10 h-10 bg-gray-200 rounded-full font-sans-bold text-center leading-10">{{eau_la_la.sauna}}</span>
              <span class="flex-1 pl-4">{{ $t("icepool.wellness_text")}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="visitors_ice" class="grid md:grid-cols-2 gap-6">
      <div class="max-w-7xl mx-auto rounded-lg shadow-sw-1 overflow-hidden w-full">
        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex items-center">
          <img src="/images/ice-skating.svg" alt="" class="mx-2" />
          <h3 class="font-sans-bold m-0 flex-1">
            {{ $t("icepool.visitors_ice")}}
          </h3>
          <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider" :class="visitors_ice.info.ActualValue > 0 ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100'">{{ visitors_ice.info.ActualValue > 0 ? 'open' : 'closed' }}</span>
        </div>
        <ul role="list" class="divide-y divide-gray-200">
          <li>
            <div class="px-4 py-4 sm:px-6 font-sans flex items-center">
              <span class="flex-1 pl-4" v-html="visitors_ice.text[$i18n.locale]"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'IcePool',
  props: {
    page: Object,
  },
  data() {
    return {
      eau_la_la: null,
      visitors_ice: null,
    }
  },
  mounted() {
    // hallenbad
    axios.get(process.env.VUE_APP_API_URL+'typo3temp/feed_dk/eau_la_la.json').then(response => {
      this.eau_la_la = response.data
    })

    // ice
    axios.get(process.env.VUE_APP_API_URL+'typo3temp/feed_dk/visitors_ice_de.json').then(response => {
      this.visitors_ice = response.data
    })
  },
}
</script>
