<template>
  <div class="relative overflow-hidden">

    <div class="space-y-6">
      <div>
        <div class="bg-sw-white overflow-hidden">
          <ul role="list" class="space-y-2">
            <li v-for="(tab, index) in sledges.tab" :item="tab" :key="index" class="bg-sw-gray-light rounded-3xl">
              <a class="transition block rounded-tl-3xl rounded-tr-3xl cursor-pointer" @click="select(tab)" :class="{ 'bg-gray-200': tab == selected}">
                <div class="w-full flex items-center space-x-4 md:space-x-8 px-4 lg:px-8 py-2 md:py-4 grid gap-4 grid-cols-4">
                  <div class="flex-col md:flex md:flex-row col-span-2">
                    <div class="w-full flex items-center">
                      <b>{{ tab.title }}</b>
                    </div>
                    <div class="w-full">
                      <p class="mb-0 text-base">
                        ({{ tab.open }} / {{ tab.total }} {{ $t("sledging.in_betrieb") }})
                      </p>
                    </div>
                  </div>
                  <div>
                    <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider" :class="[tab.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']">{{ tab.status }}</span>
                  </div>
                  <div class="flex items-center justify-end">
                    <p class="mb-0 hidden font-sans-bold md:block text-base text-sw-black mr-2">{{ $t('labels.show-more') }}</p>
                    <svg class="h-4 w-4" v-if="tab != selected">
                      <use xlink:href="#icon--chevron-down"></use>
                    </svg>
                    <svg class="h-4 w-4" v-if="tab == selected">
                      <use xlink:href="#icon--chevron-up"></use>
                    </svg>
                  </div>
                </div>
              </a>
              <div v-show="tab == selected" class="pb-4 bg-sw-gray-light rounded-3xl space-y-6">
                <div v-for="(f, index) in sledges.sledge[index]" :key="index" class="pt-4 px-4 lg:px-8 border-t flex items-center justify-between">
                  <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider ml-2" :class="[f.status == 'open' ? 'bg-green-500 text-green-100' : 'bg-red-500 text-red-100']">{{ f.status }}</span>
                  <p class="mb-0 ml-3 flex-1" :class="[f.status == 'open' ? 'text-green-500' : 'text-red-500']"><b>{{ f.name }}</b></p>
                  <p class="mb-0 flex-1">
                    <b>{{ $t("facilities.length") }}:</b> {{ parseInt(f.length) }} m
                  </p>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>

    <div class="mt-8" v-if="sledges.lastupdate">
      <p class="uppercase">{{ $t("general.lastUpdate") }}: {{
          sledges.lastupdate
        }}</p>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Sledding',
  data() {
    return {
      sledges: [],
      selected: null
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/sledge_${this.$i18n.locale}.json`).then(response => {
      this.sledges = response.data
    })
  },
  methods: {
    select(index) {
      this.selected = this.selected != index ? index : null
    },
  }
}
</script>
