<template>
  <div>
    <div class="flex justify-end mb-3">
      <SwitchGroup>
        <div class="flex items-center">
          <SwitchLabel class="mr-4">{{ mapView ? "Listenansicht" : "Karte anzeigen" }}</SwitchLabel>
          <Switch
            v-model="mapView"
            :class='mapView ? "bg-blue-600" : "bg-gray-200"'
            class="relative inline-flex items-center h-6 transition-colors rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span
              :class='mapView ? "translate-x-6" : "translate-x-1"'
              class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full"
            />
          </Switch>
        </div>
      </SwitchGroup>
    </div>

    <div v-if="!mapView" class="mx-auto grid gap-5 lg:grid-cols-3">

      <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative" v-for="trainStation in trainStations" :key="trainStation">

        <div class="flex-1 p-6 flex flex-col justify-between">
          <div class="flex-1">
            <div class="block mt-2">
             <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-500 text-green-100" v-if="trainStation.filter.city">
              {{ trainStation.filter.city }}
            </span>
              <h3 class="font-sans-bold text-md mb-0 mt-2 h4">
                {{ trainStation.name }}
              </h3>
              <p class="text-sm">
                {{ trainStation.address }}
              </p>
            </div>
          </div>
          <div class="mt-4">
            <Link :style="200" :text="trainStation.dist.text" target="_blank" :link="trainStation.dirLink" :class="'w-full mt-4'" :linkClass="'w-full'" v-if="trainStation.dirLink" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="mapView">
      <GMaps :markers="markers"  />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Mixins from "@/Mixins";
import Link from "@/components/contents/partials/Link";
import GMaps from "@/components/partials/GMaps";
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export default {
  name: 'trainStation',
  mixins: [Mixins],
  components: {
    Link,
    Switch,
    SwitchGroup,
    SwitchLabel,
    GMaps
  },
  data() {
    return {
      trainStations: [],
      sort_by_dist: false,
      markers: [],
      mapView: false
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/train_${this.$i18n.locale}.json`).then(response => {
      this.trainStations = response.data;

      this.trainStations.forEach(trainStation => {
        trainStation.dist = Mixins.methods.distanceInKm(trainStation.geoInfo.latitude, trainStation.geoInfo.longitude, this.$t("general.plan_route"))
        if(trainStation.dist.dist !== null){
          this.sort_by_dist = true;
        }
      });
      this.markers = Mixins.methods.setMarker(this.trainStations, 'bus');
      if(this.sort_by_dist){
        this.trainStations.sort((a, b) => a.dist.dist > b.dist.dist ? 1 : -1);
      }
    })
  },
}
</script>
