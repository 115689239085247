<template>
    <div>
        <div v-if="appearance.layout == 'layout-1'">
            <!--  TEMPLATE: Boxes  -->

            <div class="relative overflow-hidden space-y-2 md:space-y-6 sw_pages-teaser">
                <div class="grid grid-cols-1 md:grid-cols-2 md:gap-x-4 lg:gap-x-8 mt-6 sw_pages-teaser-wrapper" v-if="content.menu">
                    <a class="h-56 lg:h-auto relative flex sw_pages-teaser-box" :href="page.link" v-for="page in content.menu" :key="page">
                        <div class="absolute z-10 -top-6 inset-x-0" v-if="page.icon != ''">
                            <div class="w-12 h-12 rounded-md flex m-auto items-center justify-center shadow-sw-1" :class="'bg-' + page.color">
                                <svg class="w-10 h-10 text-white">
                                    <use :xlink:href="'#icon--' + page.icon"></use>
                                </svg>
                            </div>
                        </div>
                        <!--
<img v-if="page.media" alt="" class="absolute inset-0 w-full h-full object-center object-cover rounded-md">
-->
                        <div
                            class="relative w-full flex flex-col items-center justify-center text-center p-4 sm:p-8 lg:py-20 rounded-md bg-cover bg-center bg-blend-multiply"
                            :style="{ 'background-image': 'url(' + page.media[0]['publicUrl'] + ')' }"
                            :class="'bg-' + page.color"
                            v-if="page.media[0]"
                        >
                            <div class="text-white" v-html="page.header_text"></div>
                            <!--            <h2 class="text-white block text-xl leading-8 font-bold tracking-tight sm:text-2xl">{{ page.subtitle }}</h2>
            <p v-if="page.abstract" class="mt-2.5 font-medium text-white">{{ page.abstract }}</p>-->
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div v-else-if="appearance.layout == 'default'">
            <!--  TEMPLATE: Header Quick Links  -->

            <section class="-mx-4 lg:mx-0 sw_header-quick-links relative">
                <Link :style="100" :linkClass="'absolute z-10 top-4 right-4'" text="SOS" :link="page.sosNumber" v-if="page.sosNumber" />

                <div class="relative" :class="'bg-' + page.color">
                    <div class="absolute inset-0 sw_overlay">
                        <img
                            class="w-full h-full object-cover mix-blend-multiply"
                            alt=""
                            :src="content.gallery[0].publicUrl"
                            v-if="content.gallery[0]"
                        />
                        <div class="absolute inset-0 mix-blend-color" aria-hidden="true" :class="'bg-' + page.color"></div>
                    </div>
                    <div class="relative flex flex-col mx-auto max-w-7xl sm:h-96 py-6">
                        <div class="flex-1 flex justify-center items-center">
                            <div class="px-10 lg:px-16 py-4 text-center" v-if="page.id != 1 && page.id !== 279">
                                <svg class="w-24 h-20 text-white m-auto">
                                    <use :xlink:href="'#icon--' + page.icon"></use>
                                </svg>
                                <div class="text-white" v-html="page.text"></div>
                            </div>
                            <div class="px-4 w-11/12 sm:w-9/12 md:w-1/2 mb-6 mt-2" v-else-if="page.id === 279">
                                <img class="w-full h-auto" src="../../assets/icons/header.svg">
                            </div>
                            <div class="px-4 w-11/12 sm:w-9/12 md:w-1/2 mb-6 mt-2" v-else>
                                <img class="w-full h-auto" :src="`/images/winter/${$i18n.locale}/avatar and logo.svg`" />
                            </div>
                        </div>

                        <div class="flex-shrink-0 overflow-x-scroll hide-scroll-bar">
                            <div class="flex flex-nowrap mx-4 whitespace-nowrap">
                                <div class="inline-block pr-2" v-for="p in content.menu" :key="p">
                                    <Link :linkClass="'text-' + page.color" :style="21" :text="p.title" :link="p.link" v-if="p.link!='/'&&p.link!='/en/'" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Link from '@/components/contents/partials/Link'
import Mixins from '@/Mixins'

export default {
    name: 'MenuPages',
    mixins: [Mixins],
    components: {
        Link,
    },
    props: {
        content: Object,
        appearance: Object,
        page: Object,
    },
}
</script>
