<template>
    <div>
    <div class="oax-top-cont" style="z-index:0;"></div>
    </div>
</template>

<script>
    export default {
        name: "OaIntegrationHiking",
        mounted() {
          var conf = {
            frontendtype:  "tour",
            withUrlHash: true,
            modes : [
              "list",
              "gallery",
              "map",
              "listMap"
            ],
            zoom:      12,
            center:    [9.83092,46.83454],
            fitDataBounds: true,
            whereType: "none",
            mapInit: {
              network: 'winter_alpine'
            }
          };
          conf.initMode = "gallery";
          conf.cfg = {
            "initFilterNoGui" : {},
            "what": {
              "selected_init":"Wandern"
            },
            "sort": {
              selected_initFromDom : false,
              selected_init_value : "title",
              key2descendant_init : {
                title : true
              }
            },
            "maplayer": {
              "mapoverlay": {
                "winter_alpine": {}
              }
            }

          };
          window.oa.api.flexviewpage( conf );
          window.onresize = function() {
            var w = window.innerWidth;
            var elm = document.getElementsByClassName("oax-top-cont");
            elm.width= w + 'px';
          };
          var w = window.innerWidth;
          var elm = document.getElementsByClassName("oax-top-cont");
          elm.width= w + 'px';
                    
        }
    }
</script>