<template>
  <div>
    <div class="flex justify-end mb-3">
      <SwitchGroup>
        <div class="flex items-center">
          <SwitchLabel class="mr-4">{{ mapView ? $t("general.listenansicht") : $t("general.kartenansicht") }}</SwitchLabel>
          <Switch
            v-model="mapView"
            :class='mapView ? "bg-blue-600" : "bg-gray-200"'
            class="relative inline-flex items-center h-6 transition-colors rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span
              :class='mapView ? "translate-x-6" : "translate-x-1"'
              class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full"
            />
          </Switch>
        </div>
      </SwitchGroup>
    </div>
    <div v-if="!mapView" class="mx-auto grid gap-5 lg:grid-cols-3">
      <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative" v-for="(infopoint, index) in infopoints" :key="index" >
        <div class="flex-1 px-4 pt-2 pb-4 flex flex-col justify-between">

          <div class="flex-1">
            <a class="block" :href="infopoint.dirLink" target="_blank">
              <span class="inline-flex items-center justify-center uppercase px-2 py-1 text-xs font-sans-bold leading-none rounded-full tracking-wider bg-green-500 text-green-100" v-if="infopoint.filter.city">
              {{ infopoint.filter.city }}
            </span>
              <h3 class="font-sans-bold text-md mb-0 mt-2 h4">
                {{ infopoint.name }}
              </h3>
            </a>
          </div>
          <div class="my-2">
            <div class="space-y-2 text-sm">
              <a :href="'tel:' + infopoint.tel"
                 class="flex items-center text-sm transition ease-in-out duration-150"
                 v-if="infopoint.tel">
                <svg class="flex-shrink-0 h-4 w-5">
                  <use xlink:href="#icon--phone"></use>
                </svg>
                <span class="ml-3">{{ infopoint.tel }}</span>
              </a>
              <a :href="'mailto:' + infopoint.email"
                 class="flex items-center text-sm transition ease-in-out duration-150"
                 v-if="infopoint.email">
                <svg class="flex-shrink-0 h-3 w-5">
                  <use xlink:href="#icon--email"></use>
                </svg>
                <span class="ml-3">{{ infopoint.email }}</span>
              </a>
          </div>
        </div>
          <div>
            <p class="text-md font-sans-bold my-2" v-if="infopoint.description">
              {{ $t("general.oeffnungszeiten" )}}
            </p>
            <p class="text-sm whitespace-pre-line" v-if="infopoint.description">{{ infopoint.description }}</p>
          </div>
          <div class="mt-1">
            <Link :style="200" :text="infopoint.dist.text" target="_blank" :link="infopoint.dirLink" :class="'w-full mt-4'" :linkClass="'w-full'"/>
          </div>
        </div>
      </div>
    </div>

    <div v-if="mapView">
      <GMaps :markers="markers"  />
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import GMaps from "@/components/partials/GMaps";
  import Link from "@/components/contents/partials/Link";
  import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
  import Mixins from "@/Mixins";

  export default {
    name: 'Infopoints',
    components: {
      Link,
      Switch,
      SwitchGroup,
      SwitchLabel,
      GMaps
    },
    props: {
      appearance: Object
    },
    data() {
      return {
        sort_by_dist: false,
        markers: [],
        infopoints: [],
        mapView: false,
      }
    },
    mounted() {
      axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/infopoint_${this.$i18n.locale}.json`).then(response => {
        this.infopoints = response.data
        this.infopoints.forEach(infopoint => {
          infopoint.dist = Mixins.methods.distanceInKm(infopoint.geoInfo.latitude, infopoint.geoInfo.longitude, this.$t("general.plan_route"))
          if(infopoint.dist.dist !== null){
            this.sort_by_dist = true;
          }
        });
        this.markers = Mixins.methods.setMarker(this.infopoints, 'infopoint');
        if(this.sort_by_dist){
          this.infopoints.sort((a, b) => a.dist.dist > b.dist.dist ? 1 : -1);
        }
      });

    }
  }
</script>

<style>

</style>
