<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="bg-sw-white w-full shadow-top fixed left-0 right-0 bottom-0 z-50 drop-shadow-sw-1 navbar_bottom" :class="iosHotfixNeeded ? 'pb-4' : null">
        <div class="flex justify-center items-center">
            <div v-for="page in navigation" :key="page" class="flex flex-1 flex-col">
                <a
                    :href="page.link"
                    class="py-2 pt-2 pb-4 text-gray-500 hover:text-gray-700 md:flex-row items-center justify-center font-light hover:bg-gray-50"
                    v-if="!page.children"
                >
                    <span class="flex flex-col items-center justify-start text-center">
                        <svg class="h-6 w-6 mb-1"><use v-bind="{ 'xlink:href': '#icon--' + page.icon }"></use></svg>
                        <span class="text-xxs md:text-sm">{{ page.title }}</span>
                    </span>
                </a>

                <div
                    class="py-2 px-2 text-gray-500 hover:text-gray-700 md:flex-row items-center justify-center font-light hover:bg-gray-50"
                    @click="openSubmenu(page.id)"
                    v-if="page.children"
                >
                    <span class="flex flex-col items-center justify-start text-center">
                        <svg class="h-6 w-6 mb-1"><use v-bind="{ 'xlink:href': '#icon--' + page.icon }"></use></svg>
                        <span class="text-xxs md:text-sm">{{ page.title }}</span>
                    </span>
                </div>
            </div>
        </div>
        <div v-for="page in navigation" :key="page" class="absolute bottom-full w-full z-50">
            <div
                v-if="page.children && selectedSubpage === page.id"
                class="relative bg-white w-full -bottom-px rounded-t-xl border-b shadow-top navbar_bottom"
            >
                <div class="shadow-inner py-3 px-2 pb-1">
                    <a
                        :href="child.link"
                        v-for="child in page.children"
                        :key="child"
                        class="border-b p-2 border rounded-lg text-xs mr-2 mb-2 inline-block bg-white"
                    >
                        {{ child.title }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        navigation: Array,
    },
    data() {
        return {
            selectedSubpage: null,
        }
    },
    methods: {
        openSubmenu(id) {
            if (this.selectedSubpage === id) {
                this.selectedSubpage = null
            } else {
                this.selectedSubpage = id
            }
        },
    },
    computed: {
        iosHotfixNeeded() {
            // fix bug with the home bar indicator
            const userAgent = "Mozilla/5.0 (iPhone; CPU iPhone OS 13_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.4 Mobile/15E148 Safari/604.1"
            const iosVersion = userAgent.substring(userAgent.indexOf("Version/"), userAgent.indexOf("Version/") + 10).split("/")[1]

            if (iosVersion >= 14) {
                return true
            }

            return false
        }
    }
}
</script>
