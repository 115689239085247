<template>

  <div class="px-4 sm:px-6 lg:px-16 py-5" v-html="content.bodytext"></div>

</template>

<script>
export default {
  name: 'Html',
  props: {
    content: Object,
  },
}
</script>